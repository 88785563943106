@import 'https://fonts.googleapis.com/css?family=Noto+Sans';
* {
  box-sizing: border-box;
}
#open-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
#open-chat img{
  width: 30px;
  height: 30px;
}
#notificacao-info {
  background-color: red;
  border-radius: 50%;
  width: 30px;
  height: 28px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-left: 30px;
}
.floating-chat {
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 65px;
  height: 65px;
  transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 50%;
  opacity: 0;
  background-color: white;
  /* background: -moz-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 100;
}
.floating-chat.enter:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  opacity: 1;
}
.floating-chat.enter {
  transform: translateY(0);
  opacity: 0.6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}
.floating-chat.expand {
  width: 350px;
  max-height: 500px;
  height: 500px;
  border-radius: 8px;
  cursor: auto;
  opacity: 1;
  z-index: 100;
}
/* .floating-chat :focus {
  outline: 0;
  box-shadow: 0 0 3pt 2pt rgba(14, 200, 121, 0.3);
} */
.floating-chat button {
  background: transparent;
  border: 0;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
}
.floating-chat .chat-discord {
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all 250ms ease-out;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.floating-chat .chat-discord.enter {
  opacity: 1;
  border-radius: 0;
  margin: 0px;
  margin-top: 30px;
  width: auto;
  height: auto;
}
.floating-chat .header-discord {
  flex-shrink: 0;
  height: 30px;
  /* padding-bottom: 10px; */
  display: flex;
  background: #8841FB;
  border-radius: 5px 5px 0 0;
  /* background-image: url(../img/backgrounds/chat-header-background.jpg); */
}
.floating-chat .header-discord .title-discord {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 5px;
  /* font-family: "Noto Sans", sans-serif; */
  font-size: 12px;
}
#close-chat {
  margin-right: 10px;
  width: 10px;
  z-index: 100;
}
.floating-chat .header-discord button {
  flex-shrink: 0;
}
.floating-chat .chat-discord .messages {
  padding: 10px;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;
}
.floating-chat .chat-discord .messages::-webkit-scrollbar {
  width: 5px;
}
.floating-chat .chat-discord .messages::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background-color: #8841FB;
}
.floating-chat .chat-discord .messages::-webkit-scrollbar-thumb {
  /* border-radius: 5px; */
  background-color: rgb(252, 249, 252);
}
.floating-chat .chat-discord .messages li {
  position: relative;
  clear: both;
  padding: 7px 7px 7px 7px;
  margin: 0 0 20px 0;
  border-radius: 10px;
  word-wrap: break-word;
  width: 75%;
  /* max-width: 81%; */
  /* font: 12px/16px "Noto Sans", sans-serif; */
  /* background-color: rgba(25, 147, 147, 0.2); */
}
.floating-chat .chat-discord .messages li.other {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  /* margin-right: 45px; */
  /* color: #0AD5C1; */
}
.floating-chat .chat-discord .messages li.other p{
  text-align: right;
  /* color: rgb(255, 255, 255); */
}
.floating-chat .chat-discord .messages li.other .chat-date{
  text-align: right;
  font-size: 15px;
  /* color: rgb(255, 255, 255); */
}
/* .floating-chat .chat-discord .messages li.other:before {
   right: -45px;
  background-image: url(https://github.com/Thatkookooguy.png); 
} */
.floating-chat .chat-discord .messages li.other:after {
  border-right: 10px solid transparent;
  right: -10px;
}
.floating-chat .chat-discord .messages li.self {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  /* float: left; */
  margin-left: 45px;
  /* color: #0EC879; */
}
.floating-chat .chat-discord .messages li.self p{
  text-align: left;
  /* color: rgb(255, 255, 255); */
}
.floating-chat .chat-discord .messages li.self i{
  text-align: left;
  /* color: rgb(255, 255, 255); */
}
.floating-chat .chat-discord .messages li.self:before {
  left: -45px;
  /* background-image: url(https://projectdow.com/data/assets/img/logo/preloader.png); */
}
.floating-chat .chat-discord .messages li.self:after {
  border-left: 10px solid transparent;
  left: -10px;
}
.floating-chat .chat-discord .messages .self {
  /* background-color: rgb(0, 0, 0); */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.floating-chat .chat-discord .messages .self p {
  color: black;
}
.floating-chat .chat-discord .messages .self:before {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  content: "";
  background-size: cover;
}
.floating-chat .chat-discord .messages .self:after {
  position: absolute;
  top: 5px;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.155);
}

.floating-chat .chat-discord .messages .other {
  background-color: #8841FB;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); */
}
.floating-chat .chat-discord .messages .other p {
  color: rgb(255, 255, 255);
}
.floating-chat .chat-discord .messages .other:before {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  content: "";
  background-size: cover;
}
.floating-chat .chat-discord .messages .other:after {
  position: absolute;
  top: 8px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid #8841FB;
}
.floating-chat .chat-discord .footer {
  flex-shrink: 0;
  display: flex;
  /* padding-top: 10px; */
  max-height: 40px;
  height: 40px;
  background: transparent;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.floating-chat .chat-discord .footer .text-box {
  border-radius: 3px;
  /* background: rgba(25, 147, 147, 0.2); */
  /* min-height: 100%; */
  width: 75%;
  /* margin-right: 5px; */
  /* padding: 2px 5px; */
  color: #343131;
  overflow-y: auto;
  border: 0;
}
.floating-chat .chat-discord .footer img {
  width: 25px;
  opacity: 0.5;
  padding: 2px;
  cursor: pointer;

}
.floating-chat .chat-discord .footer .text-box::-webkit-scrollbar {
  width: 5px;
}
.floating-chat .chat-discord .footer .text-box::-webkit-scrollbar-track {
  border-radius: 5px;
  /* background-color: rgba(25, 147, 147, 0.1); */
}
.floating-chat .chat-discord .footer .text-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* background-color: rgba(25, 147, 147, 0.2); */
}
.title-message {
  font-size: 12px;
  font-family:Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

/*  LOCAL MESSAGES */
.carregando-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.carregando-chat li {
  font-size: 20px;
  list-style-type: none;
  font-family: "Noto Sans", sans-serif;
}
.carregando-chat img {
  width: 130px;
  height: 100px;
}
.container-cadastro-chat .btn { display: inline-block; display: inline; zoom: 1; padding: 4px 10px 4px; margin-bottom: 0; font-size: 13px; line-height: 18px; color: #333333; text-align: center;text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75); vertical-align: middle; background-color: #f5f5f5; background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6); background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6); background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6)); background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6); background-image: -o-linear-gradient(top, #ffffff, #e6e6e6); background-image: linear-gradient(top, #ffffff, #e6e6e6); background-repeat: repeat-x; filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0); border-color: #e6e6e6 #e6e6e6 #e6e6e6; border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); border: 1px solid #e6e6e6; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); cursor: pointer; margin-left: .3em; }
.container-cadastro-chat .btn:hover, .btn:active, .btn.active, .btn.disabled, .btn[disabled] { background-color: #e6e6e6; }
.container-cadastro-chat .btn-large { padding: 9px 14px; font-size: 15px; line-height: normal; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.container-cadastro-chat .btn:hover { color: #ffffff; text-decoration: none; background-color: #e6e6e6; background-position: 0 -15px; -webkit-transition: background-position 0.1s linear; -moz-transition: background-position 0.1s linear; -ms-transition: background-position 0.1s linear; -o-transition: background-position 0.1s linear; transition: background-position 0.1s linear; }
.container-cadastro-chat .btn-primary, .btn-primary:hover { text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); color: #ffffff; }
.container-cadastro-chat .btn-primary.active { color: rgba(255, 255, 255, 0.75); }
.container-cadastro-chat .btn-primary { background-color: #8841FB; background-image: -moz-linear-gradient(top, #8841FB, #8841FB); background-image: -ms-linear-gradient(top, #8841FB, #8841FB); background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8841FB), to(#8841FB)); background-image: -webkit-linear-gradient(top, #8841FB, #8841FB); background-image: -o-linear-gradient(top, #6eb6de, #4a77d4); background-image: linear-gradient(top, #6eb6de, #4a77d4); background-repeat: repeat-x; filter: progid:dximagetransform.microsoft.gradient(startColorstr=#6eb6de, endColorstr=#4a77d4, GradientType=0);  border: 1px solid #8841FB; text-shadow: 1px 1px 1px rgba(0,0,0,0.4); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.5); }
.container-cadastro-chat .btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] { filter: none; background-color: #772bf3; }
.container-cadastro-chat .btn-block { width: 100%; display:block; }

.login-cadastro-chat { 
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -150px 0 0 -150px;
	width:300px;
	height:300px;
}
.login-cadastro-chat h1 { color: #8841FB; text-shadow: 0 0 10px rgba(0,0,0,0.3); letter-spacing:1px; text-align:center;   font-family: "Noto Sans", sans-serif;}

.container-cadastro-chat input { 
	width: 100%; 
	margin-bottom: 10px; 
	/* background: rgba(0,0,0,0.3); */
	border: none;
	outline: none;
	padding: 10px;
	font-size: 15px;
	color: #434343;
  font-weight: bold;
	/* text-shadow: 1px 1px 1px rgba(0,0,0,0.3); */
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 4px;
	/* box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2); */
	-webkit-transition: box-shadow .5s ease;
	-moz-transition: box-shadow .5s ease;
	-o-transition: box-shadow .5s ease;
	-ms-transition: box-shadow .5s ease;
	transition: box-shadow .5s ease;
}
/* .container-cadastro-chat input:focus { box-shadow: inset 0 -5px 45px rgba(100,100,100,0.4), 0 1px 1px rgba(255,255,255,0.2); } */
#menssages-container p {
  margin-bottom: 0 !important;
}

.floating-wapp {
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 65px;
  height: 65px;
  transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 50%;
  opacity: 0;
  background-color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 99;
}

.floating-wapp.enter:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  opacity: 1;
}
.floating-wapp.enter {
  transform: translateY(0);
  opacity: 0.6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}
