@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900");

#container-discord {
  height: 100vh;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat" !important;
}
#inviteContainer {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
#inviteContainer .acceptContainer {
  padding: 45px 30px;
  box-sizing: border-box;
  width: 495px;
  margin-left: -400px;
  overflow: hidden;
  /* height: 0; */
  opacity: 0;
}
#inviteContainer .acceptContainer.loadIn {
  opacity: 1;
  margin-left: 0;
  transition: 0.5s ease;
}
#inviteContainer .acceptContainer:before {
  content: "";
  background-size: cover !important;
  box-shadow: inset 0 0 0 3000px rgba(40, 43, 48, 0.75);
  filter: blur(10px);
  position: absolute;
  width: 150%;
  height: 150%;
  top: -50px;
  left: -50px;
}

#container-discord form {
  position: relative;
  text-align: center;
  height: 100%;
}
#container-discord form h1 {
  margin: 0 0 15px 0;
  font-family: "Work Sans" !important;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0;
  left: -30px;
  position: relative;
  transition: 0.5s ease;
}
#container-discord form h1.loadIn {
  left: 0;
  opacity: 1;
}
.formContainer {
  text-align: left;
}
.formContainer .formDiv {
  margin-bottom: 30px;
  left: -25px;
  opacity: 0;
  transition: 0.5s ease;
  position: relative;
}
.formContainer .formDiv.loadIn {
  opacity: 1;
  left: 0;
}
.formContainer .formDiv:last-child {
  padding-top: 10px;
  margin-bottom: 0;
}
.formContainer p {
  margin: 0;
  font-weight: 700;
  color: #aaa;
  font-size: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.formContainer input[type=text], .formContainer input[type=email] {
  background: transparent !important;
  border: none;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
  padding: 15px 0;
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  font-weight: bold;
}
.formContainer input[type=text]:focus, .formContainer input[type=email]:focus {
  box-shadow: inset 0 -2px 0 #fff;
}
.logoContainer {
  padding: 45px 35px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* transform: scale(0, 0); */
}
.logoContainer img {
  width: 150px;
  margin-bottom: -5px;
  display: block;
  position: relative;
}
.logoContainer img:first-child {
  width: 150px;
}
.logoContainer .text-discord {
  padding: 25px 0 10px 0;
  margin-top: -70px;
  opacity: 0;
}
.logoContainer .text-discord.loadIn {
  margin-top: 0;
  opacity: 1;
  transition: 0.8s ease;
}
.logoContainer .logo-discord {
  position: relative;
  top: -20px;
  opacity: 0;
}
.logoContainer .logo-discord.loadIn {
  top: 0;
  opacity: 1;
  transition: 0.8s ease;
}
.logoContainer:before {
  content: "";
  background-size: cover !important;
  position: absolute;
  top: -50px;
  left: -50px;
  width: 150%;
  height: 150%;
  filter: blur(10px);
  box-shadow: inset 0 0 0 3000px rgba(255, 255, 255);
}
.forgotPas {
  color: #aaa;
  opacity: 0.8;
  text-decoration: none;
  font-weight: 700;
  font-size: 10px;
  margin-top: 15px;
  display: block;
  transition: 0.2s ease;
}
.forgotPas:hover {
  opacity: 1;
  color: #fff;
}
.acceptBtn {
  width: 100%;
  box-sizing: border-box;
  background: #7289DA;
  border: none;
  color: #fff;
  padding: 20px 0;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.2s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.acceptBtn:hover {
  background: #6B7FC5;
}
.register {
  color: #aaa;
  font-size: 12px;
  padding-top: 15px;
  display: block;
}
.register a {
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
  box-shadow: inset 0 -2px 0 transparent;
  padding-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.register a:hover {
  box-shadow: inset 0 -2px 0 #fff;
}
.div-message {
  margin-top: 20px;
  text-align: center;
}
.div-message p{
  font-size: 15px;
}
.second-form {
  text-align: center;
}
.second-form h5 {
  font-family: "Montserrat";
  margin: 0;
  color: white;
}
#contador {
  color: green;
}
.active-discord {
  padding: 50px 0 50px;
}