@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}
.container-login h1 {
	font-weight: bold;
	margin: 0;
  color: purple;
}
.container-login h2 {
	text-align: center;
}
.container-login p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
	color: white;
}
.container-login span {
	font-size: 12px;
}
.container-login a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}
.container-login button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}
.container-login button:active {
	transform: scale(0.95);
}
.container-login button:focus {
	outline: none;
}
.container-login button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.container-login form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}
.container-login-register .container-login input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
	height: 54px;
	border-radius: 10px;
	color: black;
	font-style: normal;
	font-weight: normal;
}
.container-login-register {
  margin-top: 200px;
	min-height: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-login {
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 950px;
	max-width: 100%;
	min-height: 600px;
}
.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}
.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}
.container-login.right-panel-active .sign-in-container {
	transform: translateX(100%);
}
.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}
.container-login.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}
@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}
.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}
.container-login.right-panel-active .overlay-container{
	transform: translateX(-100%);
}
.overlay {
	/* background: #8841FB;
	background: -webkit-linear-gradient(to left, #8841FB, #8841FB);
	background: linear-gradient(to left, #8841FB, #000000); */
  background-image: url(../img/login/loginbackground.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}
.container-login.right-panel-active .overlay {
  	transform: translateX(50%);
}
.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}
.overlay-left {
	transform: translateX(-20%);
}
.container-login.right-panel-active .overlay-left {
	transform: translateX(0);
}
.overlay-right {
	right: 0;
	transform: translateX(0);
}
.container-login.right-panel-active .overlay-right {
	transform: translateX(20%);
}
.social-container {
	margin: 20px 0;
}
.social-container div {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
	cursor: pointer;
}
.esqueceu-senha {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
	cursor: pointer;
}

/* */
.lostpassword-container {
  margin-top: 110px;
  height: 90ch;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* background-color: #1f1d2b; */
}
.lostpassword-container .lostpassword {
  width: 450px;
	min-height: 300px;
  margin: auto;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 5px;
  color: 1F1F1F;
  text-align: center;
}
.lostpassword h2 {
  padding: 20px;
	color: purple;
}
.lostpassword p {
  color: #B8B8B8;
  font-size: 13px;
}
.lostpassword input {
  margin: 5px 0 !important;
  padding: 10px !important;
  width: 80% !important;
  background-color: #EAEDF2 !important;
  border: none !important;
  border-radius: 3px !important;
  color: #A0A0A0 !important;
}
.lostpassword-container .lostpassword input:hover {
  outline: none;
}
.lostpassword-container .lostpassword input:focus {
  outline: none;
}
.lostpassword-container .lostpassword input:active {
  outline: none;
}
.lostpassword-container .lostpassword button {
	border-radius: 20px !important;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-bottom: 30px;
	width: 55%;
}
.lostpassword button:hover {
  outline: none;
}
.lostpassword button:focus {
  outline: none;
}
.lostpassword button:active {
  outline: none;
  position: relative;
  top: 1px;
  border: none;
  height: 37px;
}
.lostpassword hr {
  width: 75%;
  border-radius: 10px;
  border: 1px solid #F4F4F4;
  margin: auto;
  margin-bottom: 10px;
}
.lostpassword a {
  line-height: 4;
  color: #5E54C7;
  text-decoration: none;
}
.btn-lost-password {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 75px;
}

.container-icon {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.circle-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
}
.circle-wrapper .icon {
  position: absolute;
  color: #fff;
  font-size: 55px;
  top: 52px;
  left: 51px;
  transform: translate(-50%, -50%);
}
.circle-wrapper .circle {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  animation: spin 10s linear infinite;
}
.circle-wrapper:active .circle {
  animation: spin 2s linear infinite;
}
.circle-wrapper .success {
  background-color: #4BB543;
  border: 2.5px dashed #4BB543;
}
.circle-wrapper .error {
  background-color: #CA0B00;
  border: 2.5px dashed #CA0B00;
}
.circle-wrapper .warning {
  background-color: #F0D500;
  border: 2.5px dashed #F0D500;
}
@keyframes spin { 
  100% { 
    transform: rotateZ(360deg);
  }
}
