@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

* {
  outline: none;
  box-sizing: border-box;
}
:root {
  --color-secundario: #252836;
  --body-font: "Inter", sans-serif;
  --theme-bg: #1f1d2b;
  --body-color: #808191;
  --button-bg: #353340;
  --border-color: rgb(128 129 145 / 24%);
  --video-bg: #252936;
  --delay: 0s;
}
.tg-header__area {
  z-index: 100;
}
.container-ui {
  padding: 150px 0 0 0;
  background-color: var(--theme-bg);
  display: flex;
  overflow: hidden;
  height: 100vh;

  font-size: 15px;
  font-weight: 500;
  position: fixed;
  top: 0;
  left: 0;
}
/* .sidebar {
  width: 220px;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-link {
  cursor: pointer;
}
.sidebar-link:hover, .sidebar-link.is-active {
  color: #fff;
  font-weight: 600;
} */
.container_products {
  z-index: 10;
}
#menu-sidebar-mods:hover svg, #menu-sidebar-mods.is-active svg {
  background: #7700ff;
}
#menu-sidebar-mods-acessos:hover svg, #menu-sidebar-mods-acessos.is-active svg {
  background: #2b00ff;
}
.sidebar-link:hover:nth-child(2n+1) svg, .sidebar-link.is-active:nth-child(2n+1) svg {
  background: #ff7551;
}
.sidebar-link:hover:nth-child(2n) svg, .sidebar-link.is-active:nth-child(2n) svg {
  background: #32a7e2;
}
.sidebar-link:hover:nth-child(2n+3) svg, .sidebar-link.is-active:nth-child(2n+3) svg {
  background: #6c5ecf;
}
.sidebar.collapse {
  width: 90px;
  border-right: 1px solid var(--border-color);
}
.sidebar.collapse .logo-expand,
.sidebar.collapse .side-title {
  display: none;
}
.sidebar.collapse .logo {
  display: flex;
}
.sidebar.collapse .side-wrapper {
  width: 30px;
}
.sidebar.collapse .side-menu svg {
  margin-right: 30px;
}
@-webkit-keyframes bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.side-menu {
  display: flex;
  flex-direction: column;
}
.side-menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--body-color);
  cursor: pointer;
}
.side-menu div + div {
  margin-top: 26px;
}
.side-menu svg {
  width: 30px;
  padding: 8px;
  border-radius: 10px;
  background-color: var(--button-bg);
  flex-shrink: 0;
  margin-right: 16px;
}
.side-menu svg:hover {
  color: #fff;
}
.side-title {
  font-size: 12px;
  letter-spacing: 0.07em;
  margin-bottom: 24px;
}
.side-wrapper {
  border-bottom: 1px solid var(--border-color);
  padding: 36px 0;
  width: 160px;
}
.side-wrapper + .side-wrapper {
  border-bottom: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding: 0 80px 30px;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
  width: 90vw;
}
.anim {
  -webkit-animation: bottom 0.8s var(--delay) both;
          animation: bottom 0.8s var(--delay) both;
}
.main-header {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #1f1d2b 0%, #1f1d2b 78%, rgba(31, 29, 43, 0) 100%);
  z-index: 11;
}
.small-header {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin: 30px 0 20px;
}
.main-blogs {
  display: flex;
  align-items: center;
}
.main-blog__author {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.main-blog__author.tips {
  flex-direction: column-reverse;
  align-items: flex-start;
}
.main-blog__title {
  font-size: 25px;
  max-width: 12ch;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}
.main-blog {
  background-size: 80%;
  background-position-x: 150px;
  background-color: #31abbd;
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 30px;
  border-radius: 20px;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  transition: background 0.3s;
  background-repeat: no-repeat;
}
.main-blog + .main-blog {
  margin-left: 20px;
  width: 35%;
  background-color: unset;
  background-position-x: 0;
  background-size: 139%;
  filter: saturate(1.4);
}
.main-blog + .main-blog .author-img {
  border-color: rgba(255, 255, 255, 0.75);
  margin-top: 14px;
}
.main-blog + .main-blog .author-img__wrapper svg {
  border-color: #ffe6b2;
  color: #e7bb7d;
}
.main-blog + .main-blog .author-detail {
  margin-left: 0;
}
.main-blog__time {
  background: rgba(21, 13, 13, 0.44);
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.author-img {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 4px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.author-img__wrapper {
  position: relative;
  flex-shrink: 0;
}
.author-img__wrapper svg {
  width: 16px;
  padding: 2px;
  background-color: #fff;
  color: #0daabc;
  border-radius: 50%;
  border: 2px solid #0daabc;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.author-name {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
}
.author-info {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}
.author-detail {
  margin-left: 16px;
}
.seperate {
  width: 3px;
  height: 3px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 6px;
}
.seperate.video-seperate {
  background-color: var(--body-color);
}
.video {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.4s;
}
.video-js {
  height: 70%;
}
.video-youtube {
  width: 100%;
  height: 100%;
}
.container-info-mods {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.video-stream {
  width: 60%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
  display: inline-block;
  justify-content: space-between;
}
.video-stream:hover .video-js .vjs-big-play-button {
  opacity: 1;
}
.video-p {
  margin-right: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
}
.video-p .author-img {
  border: 0;
}
.video-p-wrapper {
  display: flex;
}
.video-p-wrapper .author-img {
  border: 0;
}
.video-p-wrapper svg {
  width: 20px;
  padding: 4px;
}
.video-p-title {
  font-size: 24px;
  color: #fff;
  line-height: 1.4em;
  margin: 16px 0 20px;
}
.video-p-subtitle {
  font-size: 14px;
  line-height: 1.5em;
  max-width: 60ch;
}
.video-p-subtitle + .video-p-subtitle {
  margin-top: 16px;
}
.video-p-name {
  color: #fff;
  font-size: 30px;
}
.video-p-sub {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.video-p-name.offline:after {
  background-color: #ff7551;
}
.video-content {
  width: 100%;
  height: 100%;
}
.button-wrapper {
  display: flex;
  margin-left: auto;
  /* margin-top: 10px; */
}
.like {
  display: flex;
  align-items: center;
  background-color: var(--button-bg);
  color: #fff;
  border: 0;
  font-family: var(--body-font);
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
}
.like.red {
  background-color: #ea5f5f;
}
.like svg {
  width: 18px;
  flex-shrink: 0;
  margin-right: 10px;
  padding: 0;
}
.like + .like {
  margin-left: 16px;
}
.video-stats {
  margin-left: 30px;
}
.status-mod-inject {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video-detail {
  display: flex;
  margin-top: 5px;
  width: 100%;
  height: 30%;
}
.chat {
  background-color: #252836;
  border-radius: 20px;
  padding: 0 20px;
  overflow: auto;
  height: 55%;
  min-height: 420px;
  max-height: 465px;
}
.chat::-webkit-scrollbar { 
  display: none;
}
.card-funcoes {
  max-height: 500px;
}
.chat-header {
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: sticky;
  top: 0;
  background-color: #252836;
  left: 0;
  z-index: 1;
  /* border-bottom: 1px solid var(--border-color); */
}
.chat-header svg {
  width: 15px;
  margin-right: 6px;
  flex-shrink: 0;
}
.chat-header span {
  margin-left: auto;
  color: var(--body-color);
  font-size: 12px;
  display: flex;
  align-items: center;
}
.chat-stream {
  display: block;
  flex-direction: column;
  justify-content: space-around;
  width: 35%;
  height: 100%;
}
.chat-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 12px;
  background-color: #252836;
}
.chat-footer input {
  width: 100%;
  border: 0;
  background-color: #2d303e;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  margin-left: -10px;
  padding: 12px 40px;
  font-weight: 500;
  font-family: var(--body-font);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 12C2 6.48 6.47 2 12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10C6.47 22 2 17.52 2 12zm5.52 1.2c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2.66 0 1.19.54 1.19 1.2 0 .66-.53 1.2-1.19 1.2zM10.8 12c0 .66.54 1.2 1.2 1.2.66 0 1.19-.54 1.19-1.2a1.194 1.194 0 10-2.39 0zm4.48 0a1.195 1.195 0 102.39 0 1.194 1.194 0 10-2.39 0z' fill='%236c6e78'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 8px;
}
.chat-footer input::-moz-placeholder {
  color: #6c6e78;
}
.chat-footer input:-ms-input-placeholder {
  color: #6c6e78;
}
.chat-footer input::placeholder {
  color: #6c6e78;
}
.chat-footer:before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21.435 2.582a1.933 1.933 0 00-1.93-.503L3.408 6.759a1.92 1.92 0 00-1.384 1.522c-.142.75.355 1.704 1.003 2.102l5.033 3.094a1.304 1.304 0 001.61-.194l5.763-5.799a.734.734 0 011.06 0c.29.292.29.765 0 1.067l-5.773 5.8c-.428.43-.508 1.1-.193 1.62l3.075 5.083c.36.604.98.946 1.66.946.08 0 .17 0 .251-.01.78-.1 1.4-.634 1.63-1.39l4.773-16.075c.21-.685.02-1.43-.48-1.943z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: #6c5ecf;
  padding: 4px;
  border-radius: 50%;
  right: 16px;
}
.chat-vid {
  padding: 20px 30px 10px 30px;
}
.chat-vid__title {
  padding: 10PX 20px;
  color: #fff;
  font-size: 18px;
}
.chat-vid__container {
  margin-top: 35px;
  /* background-image: url(../img/ui/baneranuncio.jpg); */
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 42%;
  background-position: left top;
  cursor: pointer;
  min-height: 300px;
}
.chat-vid__wrapper {
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.chat-vid__name {
  color: #fff;
  font-size: 14px;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.chat-vid__img {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: right;
     object-position: right;
  margin-right: 16px;
  transition: 0.3s;
}
.chat-vid__img:hover {
  transform: scale(1.02);
}
.chat-vid__content {
  max-width: 20ch;
}
.chat-vid__by, .chat-vid__info {
  color: var(--body-color);
  font-size: 13px;
}
.chat-vid__by {
  margin: 6px 0;
}
.chat-vid__button {
  background-color: #202023;
  border: 0;
  color: #8a7d7d;
  font-size: 13px;
  margin-top: 50px;
  display: flex;
  padding: 0 10px;
  height: 70px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0 0 8px 8px;
  bottom: 0;
  position: absolute;
  width: 100%;
}
.chat-vid__button:hover {
  background-color: #5847d0;
  color: white;
}
.icone-vip-anuncio{
  height: 100%;
  padding: 20px;
}
.icone-vip-anuncio img{
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.texto-vip-anuncio {
  padding: 10px;
}
.message {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.message:last-child {
  margin-bottom: 18px;
}
.message-container .author-img__wrapper svg {
  width: 15px;
}
.msg__name {
  font-size: 13px;
}
.msg__content {
  line-height: 1.4em;
  max-width: 26ch;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.video-js .vjs-control-bar {
  display: flex;
  align-items: center;
}
.vjs-poster {
  background-size: 100%;
}
.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 14px;
  padding-left: 36px;
  left: 14px;
  width: calc(100% - 28px);
  right: 0;
  border-radius: 10px;
  height: 4em;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
}
.video-js:hover .vjs-big-play-button {
  background-color: rgba(43, 51, 63, 0.5);
}
.video-js .vjs-big-play-button {
  transition: 0.3s;
  opacity: 0;
  border: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button:hover {
  background-color: rgba(43, 51, 63, 0.7);
  border-color: transparent;
}
.vjs-play-control:after {
  content: "LIVE";
  position: absolute;
  left: -66px;
  top: 7px;
  background-color: #8941e3;
  height: 24px;
  font-family: var(--body-font);
  font-size: 10px;
  padding: 0 12px 0 26px;
  display: flex;
  font-weight: 700;
  letter-spacing: 0.03em;
  align-items: center;
  border-radius: 6px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23fff' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3e%3ccircle cx='12' cy='12' r='10'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: 12px;
}
.vjs-menu-button-inline .vjs-menu {
  top: 4px;
}
.video-js .vjs-control:before,
.video-js .vjs-time-control {
  line-height: 40px;
}
.video-js .vjs-tech {
  -o-object-fit: cover;
     object-fit: cover;
}
button.vjs-play-control.vjs-control.vjs-button {
  margin-left: 40px;
}
.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control:before {
  content: "";
  position: absolute;
  display: block;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.54 0h3.38c1.41 0 2.54 1.15 2.54 2.561V5.97c0 1.42-1.13 2.56-2.54 2.56H2.54C1.14 8.53 0 7.39 0 5.97V2.561C0 1.15 1.14 0 2.54 0zm0 11.47h3.38c1.41 0 2.54 1.14 2.54 2.56v3.41c0 1.41-1.13 2.56-2.54 2.56H2.54C1.14 20 0 18.85 0 17.44v-3.41c0-1.42 1.14-2.56 2.54-2.56zM17.46 0h-3.38c-1.41 0-2.54 1.15-2.54 2.561V5.97c0 1.42 1.13 2.56 2.54 2.56h3.38c1.4 0 2.54-1.14 2.54-2.56V2.561C20 1.15 18.86 0 17.46 0zm-3.38 11.47h3.38c1.4 0 2.54 1.14 2.54 2.56v3.41c0 1.41-1.14 2.56-2.54 2.56h-3.38c-1.41 0-2.54-1.15-2.54-2.56v-3.41c0-1.42 1.13-2.56 2.54-2.56z' fill='%23fff'/%3e%3c/svg%3e");
  background-size: 11px;
  background-position: center;
  background-position-y: 14px;
  background-repeat: no-repeat;
  opacity: 0.6;
}
.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.1em;
  line-height: 3.5em;
  opacity: 0.6;
  font-weight: 700;
  font-family: var(--body-font);
}
.video-js .vjs-playback-rate {
  width: 2.2em;
}
.video-js.vjs-fluid {
  border-radius: 20px;
  overflow: hidden;
  min-height: 414px;
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(21, 20, 26, 0.63);
  border-radius: 10px;
}
.d-steam-cards {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.d-steam-card-wrapper {
  max-width: 250px;
  min-width: 150px;
  margin: 0;
  flex: 0 1 16.66%;
  padding: 1rem;
}
.d-steam-card {
  padding: 0 0 150% 0;
  /* background: url("") no-repeat transparent 50% 50%; */
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  /* box-shadow: 0 8px 10px -2px rgba(0, 0, 0, 0.5); */
  transition: all ease 0.28s 0.01s;
  transform: perspective(222px) translate3d(0px, 5px, 0px) rotateX(0deg);
  perspective-origin: top;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}
.d-steam-card-v2 {
  max-width: 250px;
  min-width: 150px;
  height: 310px;
}
.d-steam-card::before {
  width: 100%;
  height: 172%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(35deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 51.5%, rgba(255, 255, 255, 0.15) 54%, rgba(255, 255, 255, 0.15) 100%);
  transform: translateY(-36%);
  opacity: 0.5;
  transition: all ease 0.28s 0.01s;
}
.d-steam-card:hover, .d-steam-card:focus {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: perspective(222px) translate3d(0px, 0px, 8px) rotateX(3deg);
  transform-origin: center;
  /* box-shadow: 0 14px 16px -2px rgba(0, 0, 0, 0.5); */
}
.d-steam-card:hover::before, .d-steam-card:focus::before {
  opacity: 1;
  /* transform: translateY(-20%); */
}
.d-steam-card:active {
  filter: brightness(80%) contrast(110%);
}
.main-container::-webkit-scrollbar { 
	display: none;
}
.cd-single-point {
  position: absolute;
  top: 10px;
  left: 242px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}
.cd-single-point.is-open .cd-more-info {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s;
}
.cd-single-point .cd-more-info {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  line-height: 1.5;
  background: linear-gradient(to bottom, rgba(227,234,239,1) 0%,rgba(199,213,224,1) 100%);
  padding: 12px 1em 1em;
  visibility: hidden;
  opacity: 0;
  /* -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s, top 0.3s 0s, bottom 0.3s 0s, left 0.3s 0s, right 0.3s 0s; */
}
.cd-single-point .cd-more-info::before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  display: none;
  border: 8px solid transparent;
}
.cd-single-point .cd-more-info h2 {
  font-size: 2.2rem;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  margin-bottom: .6em;
}
.cd-single-point .cd-more-info p {
  color: #3d385a;
}
.free {
  color: green;
}
.vip {
  color: rgb(255, 136, 0);
}
.funcional, .functional {
  background-color: green;
  color: white;
}
.desativado, .disabled, .maintenance, .manutencao {
  background-color: rgb(255, 136, 0);
  color: white;
}
.offline {
  background-color: rgb(255, 0, 0);
  color: white;
}
.status_text {
  text-transform: uppercase;
}
.status_text img {
  width: 15px;
  margin-bottom: 5px;
}
.global-status-page-injetor {
  margin-left: 5px;
}
.funcional-text {
  color: green;
}
.update-text {
  color: rgb(255, 136, 0);
}
.offline-text {
  color: red;
}
.galeria-injetor {
  display: flex;
  justify-content: space-around;
  background-color: #252836;
  padding: 10px;
  margin-top: 15px;
  height: 55%;
}
.pre-img-injetor {
  padding: 5px;
}
.pre-img-injetor img {
  width: 100%;
  height: 100%;
}
.card-funcoes .nav {
  justify-content: space-between;
  padding: 15px 20px 5px 20px;
}
.card-funcoes-icones {
  width: 18px;
  opacity: 20%;
}
.active img {
  opacity: 100%;
}
.chat .nav-tabs {
  border-bottom: 2px solid #DDD;
}
.chat .nav-tabs>li {
  cursor: pointer;
}
.chat .nav-tabs>li.active,
.chat .nav-tabs>li.active:focus,
.chat .nav-tabs>li.active:hover {
  border-width: 0;
}
.chat .nav-tabs>li>a {
  border: none;
  color: #666;
}
.chat .nav-tabs>li.active,
.chat .nav-tabs>li:hover {
  border: none;
  color: #ffffff !important;
  background: transparent;
  opacity: 100%;
}
.chat .nav-tabs>li::after {
  content: "";
  background: #ffffff;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.chat .nav-tabs>li.active::after,
.chat .nav-tabs>li:hover::after {
  transform: scale(1);
}
.chat .tab-nav>li::after {
  background: #21527d none repeat scroll 0% 0%;
  color: #fff;
}
.chat .tab-pane {
  padding: 15px 0;
}
.chat .tab-content {
  padding: 20px;
  position: absolute;
  width: 100%;
}
.chat .card-funcoes {
  margin-bottom: 30px;
}
#funcoes-mods-id {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
@keyframes loading {
  0% {
    cy: 10;
  }
  25% {
    cy: 3;
  }
  50% {
    cy: 10;
  }
}
.toggle-switch input{
  opacity: 0
}
.toggle-switch label{
  width: 60px;
  height: 30px;
  background: #ccc;
  border-radius:50px;
  display: inline-block;
  position: relative;
}
.toggle-switch label:after {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left .3s ease-out;
}
.toggle-switch input:checked + label:after {
  left: 31px
}
.toggle-switch input:checked + label {
  background:#8941e3;
}
.mod-opcao {
  display: flex;
  justify-content: space-between;
}
#button-inject {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(to right, #7F06DE, #0c0c0c); */
  background: #7F06DE;
  border-radius: 8px;
  border: none;
  color: #f4f7ff;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
  height: 50px;
  padding: 0 10px;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  /* min-width: 250px; */
  transition: width 0.2s cubic-bezier(0.39, 1.86, 0.64, 1) 0.3s;
}
#button-inject.ready .submitMessage svg {
  opacity: 1;
  top: 1px;
  transition: top 0.4s ease 600ms, opacity 0.3s linear 600ms;
}
#button-inject.ready .submitMessage .button-text span {
  top: 0;
  opacity: 1;
  transition: all 0.2s ease calc(var(--dr) + 600ms);
}
#button-inject.loading::before {
  transition: width 0.3s ease;
  /* width: 100%; */
}
#button-inject.loading .loadingMessage {
  opacity: 1;
}
#button-inject.loading .loadingCircle {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loading;
}
#button-inject.complete .submitMessage svg {
  top: -30px;
  transition: none;
}
#button-inject.complete .submitMessage .button-text span {
  top: -8px;
  transition: none;
}
#button-inject.complete .loadingMessage {
  top: 80px;
}
#button-inject.complete .successMessage .button-text span {
  left: 0;
  opacity: 1;
  transition: all 0.2s ease calc(var(--d) + 1000ms);
}
#button-inject.complete .successMessage svg {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease-in-out 1.4s;
}
#button-inject.error .errorMessage .button-text span {
  left: 0;
  opacity: 1;
  transition: all 0.2s ease calc(var(--d) + 1000ms);
}
#button-inject.error .errorMessage svg {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease-in-out 1.4s;
}
#button-inject:disabled {
  background-color: rgb(71, 71, 71);
}

.button-text span {
  opacity: 0;
  position: relative;
}
.inject-notificacao {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--tg-body-font-family);
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
}
.inject-notificacao img {
  width: 18px;
  margin-bottom: 3px;
  margin-right: 5px;
}
.injetando:disabled {
  background-color: #7F06DE !important;
}
.inject-notificacao svg {
  display: inline-block;
  fill: none;
  margin-right: 5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.submitMessage .button-text span {
  top: 8px;
  transition: all 0.2s ease var(--d);
}
.submitMessage svg {
  color: #ffffff;
  margin-left: -1px;
  opacity: 0;
  position: relative;
  top: 30px;
  transition: top 0.4s ease, opacity 0.3s linear;
  width: 20px;
}
.loadingMessage {
  opacity: 0;
  transition: opacity 0.3s linear 0.3s, top 0.4s cubic-bezier(0.22, 0, 0.41, -0.57);
}
.loadingMessage svg {
  fill: #ffffff;
  margin: 0;
  width: 30px;
}
.successMessage .button-text span {
  left: 5px;
  transition: all 0.2s ease var(--dr);
}
.successMessage {
  font-size: 15px;
}
.successMessage svg {
  color: #5cffa1;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  transition: stroke-dashoffset 0.3s ease-in-out;
  width: 35px;
}
.errorMessage {
  text-align: center;
  font-size: 15px;
}
.errorMessage .button-text span {
  left: 5px;
  transition: all 0.2s ease var(--dr);
}
.errorMessage svg {
  color: #ff5c5c;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  transition: stroke-dashoffset 0.3s ease-in-out;
  width: 35px;
  text-align: center;
}
.loadingCircle:nth-child(2) {
  animation-delay: 0.1s;
}
.loadingCircle:nth-child(3) {
  animation-delay: 0.2s;
}
@media screen and (min-width: 480px) {
  .cd-single-point .cd-more-info {
    position: absolute;
    width: 275px;
    height: 303px;
    overflow-y: visible;
    box-shadow: 0 0 11px #000;
  }
  .cd-single-point .cd-more-info.cd-right::before {
    display: block;
    border-right-color: rgba(255, 255, 255, 0.95);
    right: 100%;
    top: 8%;
  }
  .cd-single-point .cd-more-info.cd-left::before {
    display: block;
    border-left-color: rgba(255, 255, 255, 0.95);
    left: 100%;
    top: 8%;
  }
  .cd-more-info h4.hover_title {
    color: #222d3d;
    font-weight: normal;
    font-size: 15px;
    font-family: "Motiva Sans", Sans-serif;
    font-weight: normal;
    text-transform: unset;
    letter-spacing: 0px;
    margin-top: -4px;
    line-height: 17px;
    margin-bottom: 4px;
  }
  .game_hover_box .hover_release {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
  }
  .hover_body .hover_review_summary {
    border-radius: 2px;
    padding: 4px;
  }
  .hover_body {
    margin-bottom: 6px;
  }
  .hover_tag_row {
    overflow: hidden;
    height: 19px;
    margin-top: 2px;
  }
  .hover_tag_row .app_tag {
    background-color: #96a3ae;
    color: rgba(227,234,239,1);
    box-shadow: none;
    padding: 0 4px;
    font-size: 11px;
    border-radius: 2px;
  }
  .app_tag {
    display: inline-block;
    line-height: 19px;
    padding: 0 7px;
    color: #b0aeac;
    background-color: #384959;
    margin-right: 2px;
    border-radius: 3px;
    box-shadow: 1px 1px 0 0 #000000;
    cursor: pointer;
    margin-bottom: 3px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  .cd-more-info .hover_screenshots {
    position: relative;
    width: 274px;
    height: 153px;
    margin: 5px 0;
  }
  .hover_screenshots {
    position: relative;
    width: 274px;
    height: 153px;
    margin: 1px 0;
  }
  .hover_screenshots .screenshot {
    position: absolute;
    width: 92%; /* Redundant ?? */
    height: 90%;
    background-size: cover;
    background-position: center center;
    opacity: 0;
    transition: opacity 300ms;
    animation: screenshot_hover_fadein 4s linear;
    animation-iteration-count:infinite;
  }
  .hover_screenshots .screenshot:nth-child(1) { animation-delay: 0s }
  .hover_screenshots .screenshot:nth-child(2) { animation-delay: 1s }
  .hover_screenshots .screenshot:nth-child(3) { animation-delay: 2s }
  .hover_screenshots .screenshot:nth-child(4) { animation-delay: 3s }
  .hover_screenshots .screenshot:nth-child(5) { animation-delay: 3s }
  @keyframes screenshot_hover_fadein {
    0% {
      opacity: 0;
    }
    3% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
  
    31% {
      opacity: 0;
    }
  }
  #hover_screenshots .screenshot.active {
    opacity: 1;
  }
}
/* LOCAL PARA RECARGA */
.container-recarga {
  height: 80ch;
}
#msform {
	margin: 50px auto;
	text-align: center;
	position: relative;
}
#msform fieldset {
	background: var(--color-secundario);
	border: 0 none;
	border-radius: 3px;
	padding: 10px 30px;
	box-sizing: border-box;
	margin: 0 10%;
	position: relative;
  text-align: right;
}
#msform fieldset:not(:first-of-type) {
	display: none;
}
#msform input, #msform textarea {
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-bottom: 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: montserrat;
	color: #2C3E50;
	font-size: 13px;
}
#msform .action-button {
	width: 300px;
  font-family: 'Roboto', Arial, sans-serif;
	background: #7F06DE;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 1px;
	cursor: pointer;
	padding: 10px;
	margin: 10px 5px;
  text-decoration: none;
  font-size: 20px;
}
#msform  .action-button:disabled {
	background-color: #3f3a3adc;
	color: rgba(255, 255, 255, 0.647);
}
#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 1px white, 0 0 0 1px #27AE60;
}
#msform  .action-button:disabled:hover {
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.332), 0 0 0 1px rgba(255, 255, 255, 0.315);
}
.fs-title {
	font-size: 15px;
	text-transform: uppercase;
	color: #2C3E50;
	margin-bottom: 10px;
}
.fs-subtitle {
	font-weight: normal;
	font-size: 13px;
	color: #666;
	margin-bottom: 20px;
}
#progressbar {
	margin-bottom: 30px;
	overflow: hidden;
	counter-reset: step;
}
#progressbar li {
  font-family: 'Poppins', sans-serif;
	list-style-type: none;
	color: white;
	text-transform: uppercase;
	font-size: 10px;
	width: 16%;
	float: left;
	position: relative;
}
#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 20px;
	line-height: 20px;
	display: block;
	font-size: 10px;
	color: #333;
	background: white;
	border-radius: 3px;
	margin: 0 auto 5px auto;
}
#progressbar li:first-child:after {
	content: none;
}
#progressbar li.active:before,  #progressbar li.active:after{
	background: #27AE60;
	color: white;
}
#msform {
  width: 75vw;
}
#msform blockquote::before {
  content: none;
}
.fieldset-select-seguir {
  text-align: center;
}
.container-seguir-recarregar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  height: 90%;
}
.container-recarregar-embed {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-anuncio-recarregar {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.img-anuncio {
  height: 100%;
}
.timer-recharge {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: #141414;
}
.snip1336 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  height: 100%;
  width: 100%;
  color: #ffffff;
  text-align: left;
  line-height: 1.4em;
  background-color: #141414;
}
.snip1336 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1336 img {
  max-width: 100%;
  vertical-align: top;
  opacity: 0.85;
}
.snip1336 figcaption {
  width: 100%;
  background-color: #141414;
  padding: 25px;
  position: relative;
}
.snip1336 figcaption:before {
  position: absolute;
  content: '';
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #141414;
}
.snip1336 figcaption div {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  opacity: 0.65;
  width: 47%;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.snip1336 figcaption div:hover {
  opacity: 1;
}
.snip1336 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 100%;
  left: 25px;
  z-index: 1;
  max-width: 90px;
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.snip1336 .follow {
  margin-right: 4%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), rgb(254, 44, 85);
  color: white;
}
.snip1336 h2 {
  margin: 0 0 5px;
  font-weight: 300;
}
.snip1336 h2 span {
  display: block;
  font-size: 0.5em;
  color: #2980b9;
}
.snip1336 p {
  margin: 0 0 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  opacity: 0.8;
}
/* LOCAL ANUNCIO */
.hero {
  display: inline-block;
  position: relative;
  min-height: 300px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
}
.hero-description-bk {
  background-image: linear-gradient(0deg , #3f5efb, #fc466b);
  border-radius: 30px;
  position: absolute;
  top: 55%;
  left: -5px;
  height: 65%;
  width: 108%;
  transform: skew(19deg, -9deg);
}
.second .hero-description-bk {
  background-image: linear-gradient(-20deg , #bb7413, #e7d25c)
}
.hero-logo {
  height: 80px;
  width: 80px;
  border-radius: 20px;
  position: absolute;
  bottom: 30%;
  left: 30px;
  overflow:hidden;
}
.hero-logo img {
  height: 100%;
  filter: drop-shadow(-8px 1px 0px rgba(0, 0, 0, 0.4))
}
.hero-description {
  position: absolute;
  color: #fff;
  font-weight: 900;
  left: 150px;
  bottom: 20%;
}
.hero-description p {
  color: white;
  padding: 20px 30px 0  0;
}
/* .hero-btn {
  position: absolute;
  color: #fff;
  right: 30px;
  bottom: 10%;
  padding: 10px 20px;
  border: 1px solid #fff;
  cursor: pointer;
}
.hero-btn a {
  color: #fff;
} */
.progress {
  margin: 10px;
  height: 25px;
}
.progress-bar {
  color: rgb(255, 254, 254);
  font-size: 15px;
  padding: 5px;
  background-color: green;
}
.texto-recharge-notificacao {
  padding: 0 0 40px 0;
}
.container-fazer-recarga {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68ch;
}
.container-recarga-button {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  height: 560px;
  background-color: white;
  z-index: 1;
  width: 100%;
}
.container-recarga-button div {
  box-sizing: border-box;
  display: inline-block;
  padding: 36px;
}
.heading-block {
  height: 100%;
}
.container-recarga-button div.heading-block {
  width: 45%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.container-recarga-button div.heading-block .vlrnt-logo {
  display: flex;
  align-items: flex-end;
}
.container-recarga-button div.heading-block .vlrnt-logo img {
  width: 100px;
}
.container-recarga-button div.signup-form {
  width: 482px;
}
.heading-block {
  background-color: #400240;
  color: white;
}
.heading-block h1 {
  margin: 0;
  font: 700 64px/66px "Rajdhani", sans-serif;
  text-transform: uppercase;
  letter-spacing: -2px;
}
.signup-form p {
  margin-bottom: 100px;
}
.signup-form form {
  margin: 30px 0;
}
.signup-form form * {
  display: block;
  font-family: "Rajdhani", sans-serif;
}
.signup-form label,
.signup-form .button-box {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.signup-form label {
  margin: 8px 0;
}
.signup-form input {
  width: 90%;
  height: 40px;
  margin-bottom: 30px;
  padding: 0 8px;
  font: 400 18px "Rajdhani", sans-serif;
}
.signup-form select {
  width: 95%;
  height: 48px;
  margin-bottom: 30px;
  padding: 0 8px;
  font: 400 18px "Rajdhani", sans-serif;
}
.signup-form div.button-box {
  display: inline-block;
  width: auto;
  border: 1px solid rgba(15, 25, 35, 0.4);
  padding: 6px;
}
.signup-form div.button-box .button-mask {
  cursor: pointer;
  position: relative;
  padding: 18px 60px;
  display: block;
  overflow: hidden;
  color: white;
  background-color: #ff4654;
}
.signup-form div.button-box .button-mask:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -5%;
  width: 130%;
  height: 100%;
  background-color: #0f1923;
  transform: translateX(-100%) skew(-15deg);
  transition: transform 0.4s ease;
}
.signup-form div.button-box .button-mask:hover:before {
  transform: translateX(0) skew(-15deg);
}
.signup-form div.button-box .button-mask span {
  position: relative;
  z-index: 1;
}
/* Outlined text background */
.bg-text-stroke {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 30px 48px;
  text-align: left;
  z-index: 0;
}
.bg-text-stroke span {
  font: 20 7rem "Teko", sans-serif;
  line-height: 0.8;
  text-transform: uppercase;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
  -webkit-text-stroke-width: 1px;
}
.tilte-recarga {
  text-align: left;
}
.signup-form label {
  color: var(--tg-body-color);
}
.container-ativar-chave {
  padding: 15px 0 0 0;
}
.container-button-chave {
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.popup__content input {
  width: 100%;
  text-align: center;
  color: #000;
}
.popup__content button {
  border-radius: 5px;
  border: 1px solid #FF4B2B;
  background-color: #FF4B2B;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
@media screen and (min-width: 500px) and (max-width: 1000px){
  .galeria-injetor {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    justify-content: space-around;
    min-height: 330px;
    max-height: 330px;
  }
  .container-info-mods {
    display: block;
    flex-direction: column;
    margin-top: 0px;
  }
  .video-detail {
    height: 50%;
  }
  .video-stream {
    height: 75ch;
  }
  .chat-stream {
    display: block;
  }
  .video-js {
    height: 300px;
  }
  .container-info-mods .video-stream {
    width: 100%;
  }
  .container-info-mods .chat-stream {
    margin-left: 0;
    margin-top: 30px;
  }
  .chat-stream {
    width: 100%;
  }
  .chat {
    width: 100%;
  }
  .chat-vid__container {
    margin-bottom: 50px;
    min-height: 330px;
    max-height: 330px;
  }
  .chat-vid {
    padding: 20px 30px 60px 30px;
  }

  /* RECHARGE */
  .main-container {
    padding: 0;
  }
  .container-fazer-recarga {
    height: 100%;
    width: 100%;
    min-width: 200px;
  }
  #progressbar li {
    width: 15%;
  }
  #msform fieldset {
    margin: 0 2%;
    padding: 5px;
    width: 95%;
  }
  .container-recarga-button {
    min-width: 200px;
    width: 100%;
  }
  .container-recarga-button div.signup-form {
    width: 50%;
  }
  .heading-block h1 {
    font: 700 40px/40px "Rajdhani", sans-serif;
  }
  .container-recarga-button div.signup-form {
    padding: 36px 0 0 0;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .galeria-injetor {
    min-height: 200px;
    max-height: 200px;
  }
  .mod-opcao-text {
    font-size: 10px;
  }
  .toggle-switch label {
    width: 45px;
    height: 20px;
  }
  .toggle-switch label:after {
    width: 20px;
    height: 19px;
  }
  .video-js {
    height: 400px;
  }
  .chat-stream {
    display: inline-block;
    width: 50%;
    margin-left: 10px;
  }
  .chat {
    height: 0;
    min-height: 420px;
    max-height: 420px;
  }
  .chat-vid h1{
    font-size: 25px;
  }
  .chat-vid__button {
    height: 33%;
  }
  .galeria-injetor {
    height: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    justify-content: space-around;
  }
  .pre-img-injetor {
    width: 100%;
  }
  .video-detail {
    height: 55%;
  }
  .chat-vid__container {
    margin-top: 30px;
    height: 40%;
    min-height: 320px;
    max-height: 320px;
  }
  .container-recarga-button div.signup-form {
    width: 50%;
  }
  .heading-block h1 {
    font: 700 40px/40px "Rajdhani", sans-serif;
  }
  .container-recarga-button div.signup-form {
    padding: 36px 0 0 0;
  }
    /* RECHARGE */
    .main-container {
      padding: 0;
    }
    .container-fazer-recarga {
      height: 100%;
      width: 100%;
      min-width: 200px;
    }
    #progressbar li {
      width: 15%;
    }
    #msform fieldset {
      margin: 0 2%;
      padding: 5px;
      width: 95%;
    }
    .container-recarga-button {
      min-width: 200px;
      width: 100%;
    }
    .container-recarga-button div.signup-form {
      width: 50%;
    }
    .heading-block h1 {
      font: 700 40px/40px "Rajdhani", sans-serif;
    }
    .container-recarga-button div.signup-form {
      padding: 36px 0 0 0;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .galeria-injetor {
    min-height: 260px;
    max-height: 260px;
  }
  .mod-opcao-text {
    font-size: 10px;
  }
  .toggle-switch label {
    width: 45px;
    height: 20px;
  }
  .toggle-switch label:after {
    width: 20px;
    height: 19px;
  }
  .video-js {
    height: 400px;
  }
  .chat-stream {
    display: inline-block;
    width: 50%;
    margin-left: 10px;
  }
  .chat {
    height: 0;
    min-height: 420px;
    max-height: 420px;
  }
  .chat-vid h1{
    font-size: 25px;
  }
  .chat-vid__button {
    height: 33%;
  }
  .galeria-injetor {
    height: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    justify-content: space-around;
  }
  .pre-img-injetor {
    width: 100%;
  }
  .video-detail {
    height: 40%;
  }
  .chat-vid__container {
    margin-top: 30px;
    height: 40%;
    min-height: 330px;
    max-height: 330px;
  }
  .container-recarga-button div.signup-form {
    width: 50%;
  }
  .heading-block h1 {
    font: 700 40px/40px "Rajdhani", sans-serif;
  }
  .container-recarga-button div.signup-form {
    padding: 36px 0 0 0;
  }
}
@media  screen and (min-width: 1440px) and (max-width: 1600px) {
  .mod-opcao-text {
    font-size: 10px;
  }
  .galeria-injetor {
    height: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    justify-content: space-around;
    margin-top: 55px;
    min-height: 250px;
    max-height: 250px;
  }
  .chat-vid__container {
    min-height: 350px;
    max-height: 350px;
    height: 0;
  }
  .pre-img-injetor {
    width: 90%;
  }
  .toggle-switch label {
    width: 45px;
    height: 20px;
  }
  .toggle-switch label:after {
    width: 20px;
    height: 19px;
  }
  .video-js {
    height: 400px;
  }
  .chat-stream {
    width: 50%;
    margin-left: 10px;
  }
  .chat {
    min-height: 420px;
    max-height: 420px;
    height: 0;
  }
  .chat-vid h1{
    font-size: 30px;
  }
  .chat-vid__button {
    height: 25%;
  }
  .video-detail {
    height: 35%;
  }
  .chat-stream {
    display: block;
  }
}
@media  screen and (min-width: 1600px) and (max-height: 1100px) {
  .galeria-injetor {
    min-height: 130px;
  }
  .chat-vid__container {
    height: 38%;
    min-height: 300px;
  }
  .video-js {
    min-height: 500px;
  }
  .chat {
    min-height: 440px;
  }
  .timer-recharge {
    min-height: 190px;
  }

}
@media  screen and (min-width: 2134px)  {
  /* LOCAL DE RECARGA*/
  .container-recarga {
    height: 100ch;
  }
  #msform {
    margin: 0 auto;
  }
  #msform fieldset {
    width: 75%;
  }
  .container-recarregar-embed {
    width: 85%;
  }
  .timer-recharge {
    min-height: 158px;
  }
}

@media  screen and (min-width: 2150px) and (max-height: 900px)  {
  .galeria-injetor {
    height: 60%;
  }
  .pre-img-injetor {
    text-align: center;
  }
  .pre-img-injetor img {
    width: 75%;
  }
}

@media  screen and (min-width: 2100px)  {
  .galeria-injetor {
    height: 60%;
  }
  .pre-img-injetor {
    text-align: center;
  }
  .pre-img-injetor img {
    width: 85%;
  }
}
.img-notificacao {
  width: 500px;
  cursor: pointer;
}
.tab-funcoes {
  text-transform: uppercase !important;
}
.img_game_product {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.1;
  z-index: -1;
}
.anuncio_img {
  height: 100%;
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* max-height: 300px; */
}

.pulse-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 8px; /* Ajuste conforme o design do botão */
  padding: 10px 20px; /* Ajuste conforme necessário */
  background-color: #007bff; /* Cor base do botão */
  color: white; /* Cor do texto */
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.pulse-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  transition: opacity 0.3s;
  animation: shine 3s infinite;
  pointer-events: none;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}


.form-select-v2 {
  background-color: transparent;
  border: transparent;
  text-decoration: none;
  background-image: none;
  padding: 0;
}
.informations_store {
  text-transform: uppercase;
}
.funcional_information, .functional_information {
  color: green;
}
.manutencao_information, .maintenance_information {
  color: rgb(255, 136, 0);
}
.vip_information {
  color: rgb(238, 255, 0);
}
.container-recharge {
  margin-top: 10px;
}
.recharge_img {
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  z-index: 1;
  background-color: #121212;
  background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));;
}
.recharge_type_container {
  margin-top: 35px;
}
.activekey_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activekey_img img {
  width: 100px;
}
.category-title-container {
  display: flex;
  justify-content: right;
  align-items: right;
}
.section-filters-bar-actions {
  display: flex;
  gap: 30px; /* Opcional, para adicionar espaço entre as divs */
  width: 100%;
}

.section-filters-bar-actions .last-div {
  flex-grow: 1; /* Faz com que a última div ocupe o espaço restante */
}

@media screen and (max-width: 768px) {
  .category-title-container {
    justify-content: center;
    align-items: center;
  }
}

.img_login_page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.1;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.inject-notificacao{
  text-shadow: 
  -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000;
}
.inject-notificacao svg {
  filter: drop-shadow(-1px -1px 0 #00000091) 
          drop-shadow(1px -1px 0 #00000091) 
          drop-shadow(-1px 1px 0 #00000091) 
          drop-shadow(1px 1px 0 #00000091);
}
.btn_request_product {
  display: flex;
  margin-top: 10px !important;
  width: 100%;
  justify-content: center;
}
