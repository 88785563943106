@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap");
@font-face {
  font-family: "berlin_sans_fb_demibold";
  src: url("../fonts/berlin_sans_fb_demi_bold-webfont.woff2") format("woff2"), url("../fonts/berlin_sans_fb_demi_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --tg-body-font-family: 'Poppins', sans-serif;
  --tg-heading-font-family: 'Barlow', sans-serif;
  --tg-berlin-font-family: 'berlin_sans_fb_demibold';
  --tg-icon-font-family: "Font Awesome 5 Free";
  --tg-body-font-size: 16px;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.2;
  --tg-common-color-blue: #0d6efd;
  --tg-common-color-indigo: #6610f2;
  --tg-common-color-purple: #6f42c1;
  --tg-common-color-pink: #d63384;
  --tg-common-color-red: #dc3545;
  --tg-common-color-orange: #fd7e14;
  --tg-common-color-yellow: #ffc107;
  --tg-common-color-green: #198754;
  --tg-common-color-teal: #8841FB;
  --tg-common-color-cyan: #0dcaf0;
  --tg-common-color-rating: #faa706;
  --tg-common-color-white: #fff;
  --tg-common-color-gray: #1d2333;
  --tg-common-color-black: #0f161b;
  --tg-common-color-black-2: #201f2a;
  --tg-common-color-black-3: #0b0e13;
  --tg-common-color-black-4: #10181f;
  --tg-common-color-black-5: #090d10;
  --tg-common-color-black-6: #050506;
  --tg-common-color-black-7: #040608;
  --tg-common-color-black-8: #0d141b;
  --tg-common-color-black-9: #111922;
  --tg-body-color: #adb0bc;
  --tg-heading-color: #fff;
  --tg-theme-primary: #8841FB;
  --tg-theme-secondary: #ffbe18;
  --tg-border-1: #19222b;
  --tg-border-2: #22292f;
  --tg-border-3: #2c3137;
  --tg-border-4: #333333;
  --tg-border-5: #1b242e;
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
} /*=============================
	Typography css start
===============================*/
html {
  /* overflow: hidden; */
}
body {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  line-height: var(--tg-body-line-height);
  background: var(--tg-common-color-gray);
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

img,
.img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
button {
  color: var(--tg-theme-primary);
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

a:hover,
button:hover {
  color: var(--tg-theme-secondary);
  text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
  margin-top: 0px;
  font-weight: var(--tg-fw-bold);
  line-height: var(--tg-heading-line-height);
  text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.list-wrap {
  margin: 0px;
  padding: 0px;
}
.list-wrap li {
  list-style: none;
}

p {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  font-weight: var(--tg-fw-regular);
  color: var(--tg-body-color);
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid var(--tg-common-color-gray);
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: var(--tg-heading-color);
  cursor: pointer;
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
}

input,
textarea {
  color: var(--tg-body-color);
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--tg-common-color-gray);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--tg-common-color-gray);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7750f8;
}

*::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}

::selection {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  text-shadow: none;
}

/*=============================
    - Input Placeholder
===============================*/
*::-moz-placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

*::placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

/*=============================
    - Common Classes
===============================*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}
/*=============================
    - Bootstrap Custom
=============================*/
.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.container {
  max-width: 1300px;
}
@media (max-width: 1500px) {
  .container {
    max-width: 1260px;
  }
}
@media (max-width: 1199.98px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}

.custom-container {
  max-width: 1590px;
}
@media (max-width: 1800px) {
  .custom-container {
    max-width: 1490px;
  }
}
@media (max-width: 1500px) {
  .custom-container {
    max-width: 1260px;
  }
}
@media (max-width: 1199.98px) {
  .custom-container {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .custom-container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .custom-container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.grey-bg {
  background: var(--tg-common-color-gray);
}

.white-bg {
  background: var(--tg-common-color-white);
}

.black-bg {
  background: var(--tg-common-color-black);
}

.btn {
  user-select: none;
  -moz-user-select: none;
  background: var(--tg-theme-primary) none repeat scroll 0 0;
  border: medium none;
  border-radius: 2px;
  color: var(--tg-common-color-black-2);
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 0.8px;
  margin-bottom: 0;
  padding: 15px 34px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  vertical-align: middle;
  font-family: var(--tg-heading-font-family);
  white-space: nowrap;
}
.btn:hover {
  background: var(--tg-common-color-gray);
  color: var(--tg-common-color-white);
}

.tg-btn-1 {
  display: inline-flex;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-extra-bold);
  text-transform: uppercase;
  padding: 14px 49px;
  font-size: 15px;
  letter-spacing: 0.8px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  min-width: 195px;
  z-index: 1;
  cursor: pointer;
}
.tg-btn-1::before, .tg-btn-1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='100.25' height='126.657' viewBox='0 0 30.25 56.657'%3e%3cpath id='shape.svg' class='cls-1' d='M248.391%2c576.3L218.17%2c605.258l30.221%2c27.7L226.985%2c604Z' transform='translate(-218.156 -576.312)'/%3e%3c/svg%3e");
  width: 30px;
  height: calc(100% + 6px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.tg-btn-1::after {
  left: auto;
  right: 0;
  -webkit-transform: translateY(-50%) rotateY(180deg);
  -moz-transform: translateY(-50%) rotateY(180deg);
  -ms-transform: translateY(-50%) rotateY(180deg);
  -o-transform: translateY(-50%) rotateY(180deg);
  transform: translateY(-50%) rotateY(180deg);
}
.tg-btn-1 span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--tg-theme-primary);
  clip-path: polygon(28px 0, calc(85% + 2px) 0, 100% 50%, calc(85% + 1px) 100%, 28px 100%, 0% 50%);
  border-top: 2px solid var(--tg-common-color-white);
  border-bottom: 2px solid var(--tg-common-color-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.tg-btn-1:hover {
  color: var(--tg-theme-primary);
}
.tg-btn-1:hover span::after {
  background: var(--tg-common-color-white);
}
.tg-btn-1.-btn-yellow span::after {
  background: var(--tg-theme-secondary);
}
.tg-btn-1.-btn-yellow:hover span::after {
  background: var(--tg-theme-primary);
}
.tg-border-btn {
  display: inline-flex;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-extra-bold);
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 15px;
  letter-spacing: 0.8px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='157' height='48' viewBox='0 0 157 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_1_14)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M131.75 2L155.75 25L131.75 47L148.75 24L131.75 2Z' fill='%2345F882'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 1L1 24.5111L25 47L8 23.4889L25 1Z' fill='%2345F882'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.75 1L0.75 25L23.75 47H131.75L155.75 25L131.75 1H24.75Z' stroke='%2345F882' stroke-width='1.5'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_1_14'%3e%3crect width='156.5' height='47.5' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 157px;
  min-height: 48px;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(104%) contrast(100%);
  z-index: 1;
  cursor: pointer;
}
.tg-border-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--tg-theme-primary);
  clip-path: polygon(27px 0, calc(85% - 3px) 0, 100% 50%, calc(85% - 1px) 100%, 24px 100%, 0% 50%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  z-index: -1;
}
.tg-border-btn i {
  margin-right: 10px;
}
.tg-border-btn:hover {
  color: var(--tg-common-color-white);
}
.tg-border-btn:hover::before {
  opacity: 1;
}
.tg-btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-bold);
  border: 2px solid var(--tg-theme-primary);
  white-space: nowrap;
  font-size: 20px;
  padding: 14px 35px;
  line-height: 1;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tg-btn-2::before, .tg-btn-2::after {
  content: "";
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 15px;
  height: 51px;
  pointer-events: none;
}
.tg-btn-2::after {
  left: auto;
  right: -2px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.tg-btn-2:hover {
  background-color: var(--tg-theme-primary);
  color: var(--tg-common-color-black-2);
}
.tg-btn-2.-secondary {
  border: 2px solid var(--tg-theme-secondary);
}
.tg-btn-2.-secondary:hover {
  background-color: var(--tg-theme-secondary);
}
.tg-btn-3 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-black-2);
  font-weight: var(--tg-fw-extra-bold);
  background: transparent;
  border: none;
  white-space: nowrap;
  font-size: 16px;
  padding: 15px 25px;
  line-height: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 188px;
  height: 61px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .tg-btn-3 {
    width: 166px;
    height: 53px;
    padding: 13px 22px;
    font-size: 15px;
  }
}
.tg-btn-3:hover {
  color: var(--tg-common-color-black-2);
}
.tg-btn-3 .svg-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  fill: var(--tg-theme-primary);
  stroke: var(--tg-common-color-white);
  stroke-width: 2px;
  fill-rule: evenodd;
  z-index: -1;
}
.tg-btn-3 .svg-icon svg {
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.search__popup-wrap {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  margin-top: -370px;
  transform: translateY(-100%);
  -webkit-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 1500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.search__popup-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 370px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
}
.search__layer {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(15, 22, 27, 0.9);
  -webkit-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 1500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: -1;
}
.search__close {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 30px;
  color: var(--tg-theme-primary);
  cursor: pointer;
}
.search__wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.search__wrap .title {
  font-size: 47px;
  margin: 0 0 70px 0;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-extra-bold);
  text-transform: uppercase;
  color: var(--tg-theme-primary);
  letter-spacing: -1px;
}
.search__wrap .title span {
  color: var(--tg-common-color-white);
  text-shadow: -2px 2.5px 0px rgba(69, 248, 130, 0.66);
  letter-spacing: 5px;
}
.search__form {
  position: relative;
}
.search__form form input {
  display: block;
  width: 100%;
  border: none;
  padding: 10px 50px 20px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  background: transparent;
  color: var(--tg-common-color-white);
}
.search__form form input::placeholder {
  font-size: 30px;
  opacity: 0.5;
}
.search__form form button {
  position: absolute;
  right: 20px;
  background: transparent;
  border: 0;
  font-size: 25px;
  color: var(--tg-theme-primary);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.search__form::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: var(--tg-theme-primary);
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}
.search__active .search__popup-wrap {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  margin-top: 0;
}
.search__active .search__form::after {
  width: 100%;
  -webkit-transition-delay: 1200ms;
  -moz-transition-delay: 1200ms;
  -ms-transition-delay: 1200ms;
  -o-transition-delay: 1200ms;
  transition-delay: 1200ms;
}
.page-revealer {
  pointer-events: none;
  visibility: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scaleY(0);
  z-index: 12000;
  background-color: var(--tg-common-color-gray);
}

.tg-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tg-common-color-gray);
  z-index: 99999;
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
html.show-preloader body {
  display: none;
}
.offCanvas__wrap {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 485px;
  transform: translateX(100%);
  height: 100%;
  display: block;
  background-color: var(--tg-common-color-gray);
  z-index: 110;
  -webkit-transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.offCanvas__wrap::-webkit-scrollbar {
  width: 0px;
}
@media (max-width: 1500px) {
  .offCanvas__wrap {
    width: 430px;
  }
}
@media (max-width: 767.98px) {
  .offCanvas__wrap {
    /* width: 320px; */
  }
}
.offCanvas__body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.offCanvas__top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 35px 40px 25px;
  /* border-bottom: 1px solid #18202a; */
}
.offCanvas__toggle {
  margin-left: auto;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--tg-body-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #1f2935;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offCanvas__toggle:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
}
@media (max-width: 1500px) {
  .offCanvas__toggle {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}
.offCanvas__content {
  padding: 25px 40px 50px 40px;
}
.offCanvas__content .title {
  font-size: 30px;
  letter-spacing: 1px;
  margin: 0 0 10px;
}
.offCanvas__content .title span {
  color: var(--tg-theme-primary);
}
.offCanvas__newsletter .small-title {
  margin: 0 0 22px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-primary);
}
.offCanvas__newsletter-form {
  position: relative;
  margin: 0 0 20px;
}
.offCanvas__newsletter-form [type=email] {
  display: block;
  width: 100%;
  background: transparent;
  border: 1px solid #202b36;
  padding: 15px 80px 15px 22px;
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-medium);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offCanvas__newsletter-form [type=email]:focus {
  border-color: #535d68;
}
.offCanvas__newsletter-form [type=submit] {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background: transparent;
  width: 60px;
  font-size: 22px;
}
.offCanvas__newsletter-form [type=submit]::before {
  content: "";
  position: absolute;
  width: 1px;
  top: 6px;
  background: #202b36;
  bottom: 6px;
  left: 0;
}
.offCanvas__newsletter p {
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  margin: 0 0;
}
.offCanvas__contact {
  margin: 0 0 40px;
}
.offCanvas__contact .small-title {
  margin: 0 0 22px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: var(--tg-fw-semi-bold);
}
.offCanvas__contact-list li {
  color: var(--tg-body-color);
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-medium);
  position: relative;
  padding-left: 23px;
  margin: 0 0 8px;
}
.offCanvas__contact-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 7px;
  height: 7px;
  background: var(--tg-body-color);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offCanvas__contact-list li:hover::before {
  background: var(--tg-theme-primary);
}
.offCanvas__contact-list li:last-child {
  margin: 0 0;
}
.offCanvas__contact-list li a {
  color: var(--tg-body-color);
}
.offCanvas__contact-list li a:hover {
  color: var(--tg-theme-primary);
}
.offCanvas__social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px 28px;
  border-top: 1px solid #202b36;
  
  /* padding: 10px 0 0; */
  /* margin: 50px 0 0; */
}
.offCanvas__social li {
  line-height: 1;
}
.offCanvas__social li a {
  display: block;
  font-size: 16px;
  color: var(--tg-common-color-white);
}
.offCanvas__social li a:hover {
  color: var(--tg-theme-primary);
}
.offCanvas__copyright {
  margin: auto 0 0;
  background: #252836;
  padding: 30px 40px;
}
.offCanvas__copyright p {
  margin: 0 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: var(--tg-fw-semi-bold);
  font-family: var(--tg-heading-font-family);
  letter-spacing: 1px;
}
.offCanvas__copyright p span {
  color: var(--tg-theme-primary);
}
.offCanvas__overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: var(--tg-common-color-black-9);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.offCanvas__menu-visible .offCanvas__overlay {
  opacity: 0.8;
  visibility: visible;
}
.offCanvas__menu-visible .offCanvas__wrap {
  transform: translateX(0);
}
.breadcrumb-area {
  position: relative;
  padding: 110px 0 75px;
  background-position: center;
  background-size: cover;
  min-height: 561px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 1500px) {
  .breadcrumb-area {
    min-height: 480px;
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb-area {
    min-height: 400px;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb-area {
    min-height: 310px;
    padding: 120px 0 75px;
  }
}
.breadcrumb-area::before, .breadcrumb-area::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background-color: var(--tg-theme-primary);
  height: 50px;
}
@media (max-width: 1500px) {
  .breadcrumb-area::before, .breadcrumb-area::after {
    height: 40px;
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb-area::before, .breadcrumb-area::after {
    height: 30px;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb-area::before, .breadcrumb-area::after {
    height: 20px;
  }
}
.breadcrumb-area::after {
  left: auto;
  right: 0;
  -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
.breadcrumb__wrapper {
  position: relative;
  padding: 0 80px;
}
@media (max-width: 1199.98px) {
  .breadcrumb__wrapper {
    padding: 0 0;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb__content {
    text-align: center;
  }
}
.breadcrumb__content .title {
  font-size: 60px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 3px;
  line-height: 1;
  margin: 0 0;
}
@media (max-width: 1500px) {
  .breadcrumb__content .title {
    font-size: 50px;
    letter-spacing: 2px;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb__content .title {
    font-size: 43px;
  }
}
.breadcrumb__content .breadcrumb {
  margin: 12px 0 0;
}
@media (max-width: 991.98px) {
  .breadcrumb__content .breadcrumb {
    justify-content: center;
  }
}
.breadcrumb__content .breadcrumb .breadcrumb-item {
  text-transform: uppercase;
  font-weight: var(--tg-fw-bold);
  font-size: 14px;
  letter-spacing: 2px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.breadcrumb__content .breadcrumb .breadcrumb-item::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: var(--tg-theme-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin: 0 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.breadcrumb__content .breadcrumb .breadcrumb-item:hover::after {
  background: var(--tg-theme-secondary);
}
.breadcrumb__content .breadcrumb .breadcrumb-item div {
  cursor: pointer;
  color: var(--tg-theme-primary);
}
.breadcrumb__content .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding: 0;
}
.breadcrumb__content .breadcrumb .breadcrumb-item + .breadcrumb-item::before, .breadcrumb__content .breadcrumb .breadcrumb-item + .breadcrumb-item::after {
  display: none;
}
.breadcrumb__content .breadcrumb .breadcrumb-item.active {
  color: var(--tg-common-color-white);
}
.breadcrumb__img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
@media (max-width: 1500px) {
  .breadcrumb__img {
    right: 60px;
    top: 60%;
  }
}
.breadcrumb__img img {
  max-height: 412px;
  max-width: 402px;
}
@media (max-width: 1500px) {
  .breadcrumb__img img {
    max-height: 320px;
    max-width: 310px;
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb__img img {
    max-height: 260px;
    max-width: 255px;
  }
}
.breadcrumb__img:hover img {
  -webkit-animation: breadcrumbShake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: breadcrumbShake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.breadcrumb__hide-img .breadcrumb__wrapper {
  padding: 0 0;
}
.breadcrumb__hide-img .breadcrumb__content {
  text-align: center;
}
.breadcrumb__hide-img .breadcrumb {
  justify-content: center;
}
.faq-area {
  background-color: var(--tg-common-color-black-6);
  padding: 120px 0;
}
.faq__content-wrap .section__title {
  margin: 0 0 55px;
}
.faq__content-wrap .section__title::after {
  display: none;
}
.faq__content-wrap .section__title .title {
  margin: 0 0 24px;
}
.faq__content-wrap .section__title p {
  margin: 0 0;
}
@media (max-width: 1199.98px) {
  .faq__content-wrap .section__title p br {
    display: none;
  }
}
.faq__wrapper .accordion-item {
  background: transparent;
  border: none;
  border-radius: 0;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #202428;
}
@media (max-width: 1199.98px) {
  .faq__wrapper .accordion-item {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}
.faq__wrapper .accordion-item:last-child {
  margin: 0 0;
}
.faq__wrapper .accordion-header {
  margin: 0 0;
}
.faq__wrapper .accordion-button {
  padding: 0 0;
  background: transparent;
  border-radius: 0 !important;
  box-shadow: none;
  color: var(--tg-common-color-white);
  font-size: 20px;
  font-weight: var(--tg-fw-semi-bold);
  letter-spacing: 1px;
  border: none !important;
  box-shadow: none;
}
.faq__wrapper .accordion-button::after {
  content: "\f067";
  background-image: none !important;
  font-size: 16px;
  font-family: var(--tg-icon-font-family);
  color: var(--tg-body-color);
  opacity: 0.76;
  width: auto;
  height: auto;
}
.faq__wrapper .accordion-button .count {
  width: 29px;
  height: 29px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--tg-common-color-black);
  background: var(--tg-body-color);
  font-weight: var(--tg-fw-extra-bold);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-right: 16px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.faq__wrapper .accordion-button:hover {
  color: var(--tg-theme-primary);
}
.faq__wrapper .accordion-button:hover .count {
  background: var(--tg-theme-primary);
}
.faq__wrapper .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.faq__wrapper .accordion-button:not(.collapsed) .count {
  background: var(--tg-theme-primary);
}
.faq__wrapper .accordion-body {
  padding: 11px 0 0 45px;
}
@media (max-width: 1199.98px) {
  .faq__wrapper .accordion-body {
    padding: 15px 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .faq__wrapper .accordion-body {
    padding: 11px 0 0 45px;
  }
}
@media (max-width: 767.98px) {
  .faq__wrapper .accordion-body {
    padding: 15px 0 0 0;
  }
}
.nav-tabs {
  border: none;
}
.nav-tabs .nav-item {
  margin: 0 0;
}
.nav-tabs .nav-link {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.nav-tabs .nav-link.active {
  background: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: auto;
}

.tab-pane.active {
  animation: tgSlideDown 0.5s ease-in-out;
}
.scroll__top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: fixed;
  bottom: -10%;
  right: 10px;
  font-size: 20px;
  border-radius: 3px;
  z-index: 99;
  color: var(--tg-common-color-black-2);
  text-align: center;
  cursor: pointer;
  background: var(--tg-theme-primary);
  transition: 0.8s ease;
  border: none;
}
.scroll__top.open {
  bottom: 30px;
}
@media (max-width: 767.98px) {
  .scroll__top.open {
    bottom: 20px;
  }
}
.scroll__top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll__top:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-common-color-black-2);
}
.scroll__top i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media (max-width: 1199.98px) {
  .scroll__top {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    right: 30px;
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .scroll__top {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    right: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .scroll__top {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    right: 25px;
  }
}
.section__title {
  position: relative;
}
.section__title .sub-title {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-primary);
  line-height: 1;
  margin: 0 0 7px;
}
@media (max-width: 767.98px) {
  .section__title .sub-title {
    margin: 0 0 10px;
  }
}
.section__title .title {
  font-size: 45px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 1px;
  margin: 0 0;
}
@media (max-width: 767.98px) {
  .section__title .title {
    font-size: 35px;
    line-height: 1.1;
  }
}
@media (max-width: 1199.98px) {
  .section__title .title br {
    display: none;
  }
}
.section__title::after {
  content: "";
  display: block;
  background-image: url(../img/bg/title_shape.svg);
  width: 65px;
  height: 5px;
  margin: 20px auto 0;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(104%) contrast(100%);
}
.section__title.title-shape-none::after {
  display: none;
}
.section__title.text-start .title {
  line-height: 1.15;
}
.section__title.text-start::after {
  margin: 20px 0 0;
}
.section__title-link {
  text-align: right;
}
@media (max-width: 991.98px) {
  .section__title-link {
    text-align: center;
    margin: 10px 0 0;
  }
}
.section__title-link a {
  display: inline-block;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-body-color);
  position: relative;
  padding: 0 0 3px;
}
.section__title-link a:hover {
  color: var(--tg-theme-primary);
}
.section__title-link a::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: var(--tg-theme-primary);
}
.tg__animate-text em {
  opacity: 0;
}
.tg__animate-text.ready {
  opacity: 1;
}
.transparent-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  height: auto;
}
@media (max-width: 767.98px) {
  .transparent-header {
    top: 0;
  }
}

.tg-header__area {
  padding: 11px 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tg-header__area.tg-sticky-menu {
  padding: 0 0;
}
@media (max-width: 1199.98px) {
  .tg-header__area.tg-sticky-menu {
    padding: 18px 0;
  }
}
.tg-header__area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 26px;
  cursor: pointer;
  line-height: 1;
  color: var(--tg-theme-primary);
  display: none;
  margin-top: 3px;
  border: 2px solid var(--tg-theme-primary);
  padding: 5px 10px;
}
@media (max-width: 1199.98px) {
  .tg-header__area .mobile-nav-toggler {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .tg-header__area .mobile-nav-toggler {
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .tg-header__area {
    padding: 25px 0;
  }
}
@media (max-width: 767.98px) {
  .tg-header__area {
    padding: 20px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tg-header__area {
    padding: 25px 0;
  }
}

.tgmenu__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 1199.98px) {
  .tgmenu__nav {
    /* justify-content: space-between; */
  }
}
.tgmenu__navbar-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-grow: 1;
}
.tgmenu__navbar-wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto 0 122px;
}
@media (max-width: 1500px) {
  .tgmenu__navbar-wrap ul {
    margin: 0 auto 0 0;
  }
}
.tgmenu__navbar-wrap ul li {
  display: block;
  position: relative;
  list-style: none;
}
.tgmenu__navbar-wrap ul li div {
  font-size: 15px;
  font-weight: var(--tg-fw-bold);
  text-transform: uppercase;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  padding: 38px 23px;
  display: block;
  line-height: 1;
  position: relative;
  letter-spacing: 0.8px;
  z-index: 1;
  cursor: pointer;
}
.tgmenu__navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid var(--tg-border-2);
  background: var(--tg-common-color-gray);
  margin: 0;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.tgmenu__navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.tgmenu__navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.tgmenu__navbar-wrap ul li .sub-menu li div {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-heading-color);
  text-transform: uppercase;
  letter-spacing: 0.8px;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.tgmenu__navbar-wrap ul li .sub-menu li div::before {
  content: "";
  display: block;
  width: 0;
  height: 7px;
  background-color: var(--tg-theme-primary);
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
  -o-border-radius: 0 5px 0 0;
  -ms-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
  margin: 7px 9px 0 0;
  -webkit-transition: width 0.2s linear;
  -moz-transition: width 0.2s linear;
  -ms-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}
.tgmenu__navbar-wrap ul li .sub-menu li:hover > div::before, .tgmenu__navbar-wrap ul li .sub-menu li.active > div::before {
  width: 7px;
}
.tgmenu__navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tgmenu__navbar-wrap > ul > li > div::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 42px;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(0deg);
  -moz-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  -o-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  background: var(--tg-theme-primary);
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tgmenu__navbar-wrap > ul > li.active > div, .tgmenu__navbar-wrap > ul > li:hover > div {
  color: var(--tg-theme-primary);
}
.tgmenu__navbar-wrap > ul > li.active > div::before, .tgmenu__navbar-wrap > ul > li:hover > div::before {
  opacity: 1;
  -webkit-transform: translateY(-50%) rotate(-40deg);
  -moz-transform: translateY(-50%) rotate(-40deg);
  -ms-transform: translateY(-50%) rotate(-40deg);
  -o-transform: translateY(-50%) rotate(-40deg);
  transform: translateY(-50%) rotate(-40deg);
}
.tgmenu__main-menu li.menu-item-has-children .dropdown-btn {
  display: none;
}
.tgmenu__action > ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.tgmenu__action > ul li {
  position: relative;
  margin-left: 15px;
}
.tgmenu__action > ul li:first-child {
  margin-left: 0;
}
.tgmenu__action > ul li a {
  color: var(--tg-heading-color);
}
.tgmenu__action > ul li .tg-btn-3 {
  width: 150px;
  height: 45px;
  color: var(--tg-theme-primary);
  font-size: 15px;
  font-weight: var(--tg-fw-bold);
  letter-spacing: 1px;
}
.tgmenu__action > ul li .tg-btn-3 i {
  margin-right: 12px;
}
.tgmenu__action > ul li .tg-btn-3 .svg-icon {
  fill: #0f1d29;
  stroke: var(--tg-theme-primary);
}
@media (max-width: 1199.98px) {
  .tgmenu__action {
    margin-right: 40px;
  }
}
.tgmenu__action .search a {
  display: block;
  font-size: 20px;
}
.tgmenu__action .search a:hover {
  color: var(--tg-theme-primary);
}
.tgmenu__action .header-btn {
  padding-left: 25px;
}
.tgmenu__action .header-btn .btn {
  color: var(--tg-common-color-black-2);
  font-size: 14px;
  padding: 13px 22px;
  font-weight: var(--tg-fw-extra-bold);
}
.tgmenu__action .header-btn::before {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6px;
  height: 28px;
  background-color: #131b22;
  box-shadow: inset 0px 3px 7px 0px rgba(0, 0, 0, 0.61);
}
@media (max-width: 1199.98px) {
  .tgmenu__action .header-btn::before {
    box-shadow: inset 0px 3px 7px 0px var(--tg-theme-primary);
    opacity: 0.1;
  }
}
.tgmenu__action .side-toggle-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-width: 45px;
  gap: 10px;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .tgmenu__action .side-toggle-icon {
    display: none;
  }
}
.tgmenu__action .side-toggle-icon span {
  display: block;
  height: 3px;
  background-color: var(--tg-common-color-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tgmenu__action .side-toggle-icon span:nth-child(1) {
  width: 26px;
}
.tgmenu__action .side-toggle-icon span:nth-child(2) {
  width: 45px;
}
.tgmenu__action .side-toggle-icon span:nth-child(3) {
  width: 26px;
  align-self: flex-end;
}
.tgmenu__action .side-toggle-icon:hover span {
  width: 45px;
}
.logo {
  cursor: pointer;
  padding: 5px;
}
.logo div {
  display: inline-block;
}
.logo img {
  max-width: 240px;
}
.tg-sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 99;
  background: var(--tg-common-color-gray);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
  opacity: 0;
}
.tg-sticky-menu.sticky-menu__show {
  -webkit-transform: unset;
  -moz-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
  transform: unset;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.tgmobile__search {
  padding: 0 20px 25px 25px;
}
.tgmobile__search form {
  position: relative;
}
.tgmobile__search input {
  display: block;
  width: 100%;
  border: none;
  padding: 10px 45px 10px 20px;
  font-size: 14px;
  height: 45px;
  background: var(--tg-common-color-gray);
  color: var(--tg-common-color-white);
}
.tgmobile__search input::placeholder {
  font-size: 14px;
  color: #c7c7c7;
}
.tgmobile__search button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 0;
  right: 20px;
  line-height: 1;
  background: transparent;
  color: var(--tg-heading-color);
}
.tgmobile__menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -moz-transform: translateX(101%);
  -ms-transform: translateX(101%);
  -o-transform: translateX(101%);
  transform: translateX(101%);
}
.tgmobile__menu .navbar-collapse {
  display: block !important;
}
.tgmobile__menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.tgmobile__menu .nav-logo img {
  max-width: 150px;
}
.tgmobile__menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.tgmobile__menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid var(--tg-border-2);
}
.tgmobile__menu .navigation li.current > div::before {
  height: 100%;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 18px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-common-color-white);
  background: var(--tg-common-color-gray);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 12px;
  height: 2px;
  background-color: var(--tg-common-color-white);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 2px;
  height: 12px;
  background-color: var(--tg-common-color-white);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open {
  background-color: var(--tg-theme-primary);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line {
  background-color: var(--tg-common-color-black);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line::after {
  display: none;
}
.tgmobile__menu .navigation li > div {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 11px 60px 11px 25px;
  font-size: 15px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  cursor: pointer;
}
.tgmobile__menu .navigation li > div::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li ul li > div {
  margin-left: 20px;
}
.tgmobile__menu .navigation li ul li ul li div {
  margin-left: 40px;
}
.tgmobile__menu .navigation li ul li ul li ul li div {
  margin-left: 60px;
}
.tgmobile__menu .navigation li > ul {
  display: none;
}
.tgmobile__menu .navigation li > ul > li > ul {
  display: none;
}
.tgmobile__menu .navigation ul {
  padding: 0;
  margin: 0;
}
.tgmobile__menu .navigation li:last-child {
  border-bottom: 1px solid var(--tg-border-2);
}
.tgmobile__menu .navigation ul li div {
  display: block;
}
.tgmobile__menu .navigation ul li ul li > div {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}
.tgmobile__menu .navigation:last-child {
  /* border-bottom: 1px solid var(--tg-border-2); */
}
.tgmobile__menu .navigation > li > ul > li:first-child {
  border-top: 1px solid var(--tg-border-2);
}
.tgmobile__menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: var(--tg-theme-primary);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.tgmobile__menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.tgmobile__menu .social-links ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}
.tgmobile__menu .social-links ul li {
  position: relative;
  display: block;
}
.tgmobile__menu .social-links ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 16px;
  color: var(--tg-common-color-white);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid var(--tg-border-2);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.tgmobile__menu .social-links ul li a:hover {
  border-color: var(--tg-theme-primary);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
}
.tgmobile__menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-common-color-black);
  padding: 0px 0px;
  z-index: 110;
  box-shadow: -9px 0 14px 0px rgba(0, 0, 0, 0.06);
}
.tgmobile__menu-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-common-color-white);
  margin-right: 30px;
  top: 15px;
}

.mobile-remove-mode {
  display: none !important;
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .tgmobile__menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu-visible .tgmobile__menu-backdrop {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .tgmobile__menu .close-btn {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.slider__bg {
  background-position: center;
  background-size: cover;
  padding: 158px 0 54px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .slider__bg {
    padding: 175px 0 55px;
  }
}
@media (max-width: 767.98px) {
  .slider__bg {
    padding: 160px 0 55px;
  }
}
.slider__content {
  margin: 135px 0 0 40px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1800px) {
  .slider__content {
    margin: 135px 0 0 0;
  }
}
@media (max-width: 1500px) {
  .slider__content {
    margin: 90px 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .slider__content {
    margin: 0 0 70px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .slider__content {
    margin: 0 0 50px;
  }
}
.slider__content .sub-title {
  margin: 0 0 19px;
  line-height: 1;
  font-family: var(--tg-body-font-family);
  font-weight: var(--tg-fw-bold);
  letter-spacing: 4px;
  font-size: 20px;
  color: var(--tg-theme-primary);
  position: relative;
  padding: 17px 0 17px 20px;
}
@media (max-width: 1199.98px) {
  .slider__content .sub-title {
    font-size: 16px;
    padding: 15px 0 15px 20px;
  }
}
@media (max-width: 991.98px) {
  .slider__content .sub-title {
    font-size: 18px;
    padding: 15px 20px 15px;
  }
}
@media (max-width: 767.98px) {
  .slider__content .sub-title {
    font-size: 17px;
    padding: 15px 20px 15px;
  }
}
.slider__content .sub-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.28;
}
@media (max-width: 1199.98px) {
  .slider__content .sub-title::before {
    background-size: contain;
  }
}
@media (max-width: 991.98px) {
  .slider__content .sub-title::before {
    left: 86px;
    top: 0;
    right: 0;
    width: 297px;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .slider__content .sub-title::before {
    left: 55px;
    width: 256px;
    margin: 0 auto;
  }
}
.slider__content .title {
  margin: 0 0 21px;
  font-size: 150px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-extra-bold);
  line-height: 0.8;
  text-shadow: -1px 5px 0px #8841FB
}
@media (max-width: 1500px) {
  .slider__content .title {
    font-size: 118px;
  }
}
@media (max-width: 1199.98px) {
  .slider__content .title {
    font-size: 94px;
  }
}
@media (max-width: 991.98px) {
  .slider__content .title {
    font-size: 120px;
  }
}
@media (max-width: 767.98px) {
  .slider__content .title {
    font-size: 18vw;
    text-shadow: -1px 3px 0px rgba(69, 248, 130, 0.66);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__content .title {
    font-size: 17vw;
    text-shadow: -1px 5px 0px rgba(69, 248, 130, 0.66);
  }
}
.slider__content p {
  margin: 0 0;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5px;
  line-height: 1.4;
  color: var(--tg-common-color-white);
}
@media (max-width: 1199.98px) {
  .slider__content p {
    font-size: 22px;
  }
}
@media (max-width: 991.98px) {
  .slider__content p {
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .slider__content p {
    font-size: 20px;
    letter-spacing: 3px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__content p {
    font-size: 24px;
    letter-spacing: 5px;
  }
}
.slider__btn {
  margin: 33px 0 0;
}
.slider__btn .tg-btn-1 {
  padding: 15px 49px;
}
.slider__img img {
  margin: 0 auto;
}
@media (max-width: 1199.98px) {
  .slider__img img {
    margin: 0 0 0 auto;
    max-width: 90%;
    display: block;
  }
}
@media (max-width: 991.98px) {
  .slider__img img {
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    display: block;
  }
}
.slider__shapes {
  opacity: 0.27;
}
.slider__shapes img {
  position: absolute;
  animation: shapeBlinker 1.5s linear infinite;
  opacity: 0;
  z-index: -1;
}
.slider__shapes img:nth-child(1) {
  left: 23%;
  top: 17%;
  animation-delay: 0.2s;
}
@media (max-width: 991.98px) {
  .slider__shapes img:nth-child(1) {
    left: 13%;
    top: 12%;
  }
}
@media (max-width: 767.98px) {
  .slider__shapes img:nth-child(1) {
    left: 11%;
    top: 11%;
  }
}
.slider__shapes img:nth-child(2) {
  left: 29%;
  bottom: 45%;
  animation-delay: 0.4s;
}
@media (max-width: 991.98px) {
  .slider__shapes img:nth-child(2) {
    left: 12%;
  }
}
@media (max-width: 767.98px) {
  .slider__shapes img:nth-child(2) {
    left: 10%;
    bottom: 55%;
  }
}
.slider__shapes img:nth-child(3) {
  right: 47%;
  top: 20%;
  animation-delay: 0.2s;
}
@media (max-width: 991.98px) {
  .slider__shapes img:nth-child(3) {
    right: 23%;
    top: 15%;
  }
}
@media (max-width: 767.98px) {
  .slider__shapes img:nth-child(3) {
    right: 10%;
    top: 17%;
  }
}
.slider__shapes img:nth-child(4) {
  right: 42%;
  top: 23%;
  animation-delay: 0.6s;
}
@media (max-width: 1500px) {
  .slider__shapes img:nth-child(4) {
    right: 40%;
    top: 28%;
  }
}
@media (max-width: 991.98px) {
  .slider__shapes img:nth-child(4) {
    right: 20%;
    top: 35%;
  }
}
@media (max-width: 767.98px) {
  .slider__shapes img:nth-child(4) {
    right: 14%;
    top: 33%;
  }
}
.slider__brand-wrap {
  position: absolute;
  left: 0;
  bottom: 130px;
  width: 100%;
  height: auto;
}
@media (max-width: 1500px) {
  .slider__brand-wrap {
    bottom: 150px;
  }
}
@media (max-width: 1199.98px) {
  .slider__brand-wrap {
    bottom: 85px;
  }
}
@media (max-width: 991.98px) {
  .slider__brand-wrap {
    bottom: 0;
    position: relative;
  }
}
.slider__brand-list {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  align-items: center;
  gap: 20px 60px;
  margin: 0 0 0 40px;
}
@media (max-width: 1800px) {
  .slider__brand-list {
    margin: 0 0 0;
  }
}
@media (max-width: 1199.98px) {
  .slider__brand-list {
    width: 60%;
    gap: 20px 30px;
  }
}
@media (max-width: 991.98px) {
  .slider__brand-list {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .slider__brand-list {
    flex-wrap: nowrap;
    gap: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__brand-list {
    gap: 35px;
  }
}
.slider__brand-list li a {
  display: block;
}
.slider__area {
  position: relative;
}
.slider__area::before, .slider__area::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  background-color: var(--tg-theme-primary);
  height: 50px;
}
@media (max-width: 1500px) {
  .slider__area::before, .slider__area::after {
    height: 40px;
  }
}
@media (max-width: 1199.98px) {
  .slider__area::before, .slider__area::after {
    height: 30px;
  }
}
@media (max-width: 767.98px) {
  .slider__area::before, .slider__area::after {
    height: 20px;
  }
}
.slider__area::after {
  left: auto;
  right: 0;
  -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.banner__padding {
  padding: 310px 0 295px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  .banner__padding {
    padding: 260px 0 220px;
  }
}
@media (max-width: 767.98px) {
  .banner__padding {
    padding: 180px 0 150px;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
.banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.banner__content {
  margin: 0 0;
}
@media (max-width: 1500px) {
  .banner__content .title {
    font-size: 136px;
  }
}
@media (max-width: 991.98px) {
  .banner__content .title {
    font-size: 118px;
  }
}
@media (max-width: 767.98px) {
  .banner__content .title {
    font-size: 18vw;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content .title {
    font-size: 17vw;
  }
}
.banner__btn {
  margin: 33px 0 0;
}
.nft-item__area {
  background: var(--tg-common-color-black-3);
  padding: 100px 0 70px;
}
.nft-item__area .row {
  --bs-gutter-x: 37px;
}
@media (max-width: 1500px) {
  .nft-item__area .row {
    --bs-gutter-x: 30px;
  }
}
.nft-item__box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  background-color: #121a23;
  background-image: -moz-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -webkit-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -ms-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid rgba(76, 76, 76, 0.2);
  padding: 25px;
  margin: 0 0 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767.98px) {
  .nft-item__box {
    display: block;
    text-align: center;
    max-width: 320px;
    padding: 35px 25px 25px;
    margin: 0 auto 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nft-item__box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: left;
    max-width: 100%;
    padding: 25px 25px;
    margin: 0 0 30px;
  }
}
.nft-item__box:hover {
  border-color: rgba(69, 248, 130, 0.2);
}
.nft-item__thumb {
  flex: 0 0 auto;
  margin-right: 20px;
  max-width: 187px;
}
@media (max-width: 1800px) {
  .nft-item__thumb {
    max-width: 165px;
  }
}
@media (max-width: 1500px) {
  .nft-item__thumb {
    max-width: 170px;
  }
}
@media (max-width: 991.98px) {
  .nft-item__thumb {
    max-width: 187px;
  }
}
@media (max-width: 767.98px) {
  .nft-item__thumb {
    margin: 0 auto 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nft-item__thumb {
    margin: 0 20px 0 0;
  }
}
.nft-item__thumb img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.nft-item__content {
  flex-grow: 1;
}
.nft-item__content .title {
  font-size: 20px;
  letter-spacing: 0.5px;
  margin: 0 0 15px;
}
.nft-item__avatar {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  .nft-item__avatar {
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nft-item__avatar {
    justify-content: flex-start;
  }
}
.nft-item__avatar .avatar-img {
  margin: 0 10px 0 0;
  flex: 0 0 auto;
}
.nft-item__avatar .avatar-img img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.nft-item__avatar .avatar-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.nft-item__avatar .avatar-name .name {
  margin: 0 0 0;
  font-size: 18px;
  font-weight: var(--tg-fw-semi-bold);
  color: #9b9b9b;
  text-transform: capitalize;
}
.nft-item__avatar .avatar-name .designation {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #9b9b9b;
}
.nft-item__avatar .avatar-name .designation::before {
  content: "|";
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  padding: 0 8px 0 10px;
}
.nft-item__price {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  background-color: #121a23;
  background-image: -moz-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 14, 18) 0%, rgb(16, 24, 31) 100%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid rgba(76, 76, 76, 0.5);
  padding: 14px 15px 14px 20px;
}
.nft-item__price p {
  margin: 0 0;
  font-family: var(--tg-heading-font-family);
  font-size: 18px;
  color: #e8e8e8;
  font-weight: var(--tg-fw-semi-bold);
}
.nft-item__price p span {
  text-transform: uppercase;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-theme-secondary);
}
.nft-item__price .bid-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: var(--tg-theme-secondary);
  color: var(--tg-common-color-black-2);
  text-transform: capitalize;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 1;
  padding: 11px 14px;
  margin-left: auto;
}
.nft-item__price .bid-btn:hover {
  background: var(--tg-theme-primary);
}

.section-pt-120 {
  padding-top: 120px;
}
@media (max-width: 767.98px) {
  .section-pt-120 {
    padding-top: 100px;
  }
}

.section-pb-90 {
  padding-bottom: 90px;
}
@media (max-width: 767.98px) {
  .section-pb-90 {
    padding-bottom: 70px;
  }
}

.trendingNft-area {
  background-color: var(--tg-common-color-black-4);
}
.trendingNft__title-wrap {
  margin: 0 0 50px;
}
.trendingNft__title .title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 0 0;
  font-size: 36px;
}
@media (max-width: 767.98px) {
  .trendingNft__title .title {
    justify-content: center;
  }
}
.trendingNft__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;
}
@media (max-width: 767.98px) {
  .trendingNft__nav {
    justify-content: center;
    margin-top: 18px;
  }
}
.trendingNft__nav > * {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #adb0bc;
  background: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px;
  color: #adb0bc;
}
.trendingNft__nav > *:hover {
  border-color: var(--tg-theme-primary);
  color: var(--tg-theme-primary);
}
.trendingNft__item {
  background-image: url(../img/bg/trendnft_img.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 403px;
  height: 426px;
  padding: 24px 20px;
  margin: 0 0 30px;
}
@media (max-width: 1199.98px) {
  .trendingNft__item {
    margin: 0 auto 30px;
  }
}
@media (max-width: 991.98px) {
  .trendingNft__item {
    max-width: 330px;
    height: 345px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .trendingNft__item {
    max-width: 403px;
    height: 426px;
  }
}
.trendingNft__item-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}
.trendingNft__item-avatar {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 9px;
}
.trendingNft__item-avatar .image {
  max-width: 45px;
  flex: 0 0 auto;
}
.trendingNft__item-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.trendingNft__item-avatar .info .name {
  font-size: 16px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: capitalize;
  color: #fcfcfc;
  margin: 0 0 5px;
}
.trendingNft__item-avatar .info .userName {
  display: block;
  line-height: 1;
  font-size: 12px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-body-color);
  font-family: var(--tg-heading-font-family);
  text-decoration: underline;
}
.trendingNft__item-avatar .info .userName:hover {
  color: var(--tg-theme-primary);
}
.trendingNft__item-wish {
  margin-left: auto;
}
.trendingNft__item-wish a {
  color: #c9c9c9;
}
.trendingNft__item-wish a:hover {
  color: var(--tg-theme-primary);
}
.trendingNft__item-image img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  width: 100%;
  max-height: 249px;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .trendingNft__item-image img {
    max-height: 170px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .trendingNft__item-image img {
    max-height: 249px;
  }
}
.trendingNft__item-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.trendingNft__item-bottom .bid-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: var(--tg-theme-secondary);
  color: var(--tg-common-color-black-2);
  text-transform: capitalize;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 1;
  padding: 11px 14px;
  margin-left: auto;
  margin-right: 35px;
}
.trendingNft__item-bottom .bid-btn:hover {
  background: var(--tg-theme-primary);
}
.trendingNft__item-bottom::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, #383d44 50%, transparent 100%);
  flex: 0 0 100%;
  margin: 14px 0 13px;
}
.trendingNft__item-price .bid {
  display: block;
  font-family: var(--tg-heading-font-family);
  text-transform: capitalize;
  font-size: 13px;
  font-weight: var(--tg-fw-semi-bold);
  line-height: 1;
  margin: 0 0 7px;
}
.trendingNft__item-price .eth {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #e8e8e8;
  font-weight: var(--tg-fw-semi-bold);
  margin: 0 0;
}
.trendingNft__item-price .eth i {
  color: var(--tg-theme-secondary);
  margin-right: 10px;
}
.trendingNft__item-price .eth span {
  color: var(--tg-theme-secondary);
  font-weight: var(--tg-fw-bold);
  margin-left: 5px;
}
.area-background {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-pt-130 {
  padding-top: 130px;
}
@media (max-width: 767.98px) {
  .section-pt-130 {
    padding-top: 100px;
  }
}

.section-pb-130 {
  padding-bottom: 130px;
}
@media (max-width: 767.98px) {
  .section-pb-130 {
    padding-bottom: 100px;
  }
}

.about__buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 35px;
  margin: 0 0 40px;
}
.about__tab-wrap .nav-tabs {
  justify-content: center;
  padding: 22px 0;
  position: relative;
  gap: 15px 35px;
}
@media (max-width: 991.98px) {
  .about__tab-wrap .nav-tabs {
    gap: 15px 30px;
  }
}
@media (max-width: 767.98px) {
  .about__tab-wrap .nav-tabs {
    gap: 20px 25px;
  }
}
.about__tab-wrap .nav-tabs::before, .about__tab-wrap .nav-tabs::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, var(--tg-theme-primary) 50%, transparent 100%);
}
.about__tab-wrap .nav-tabs::after {
  top: auto;
  bottom: 0;
}
.about__tab-wrap .nav-tabs .nav-item {
  position: relative;
  z-index: 1;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link {
  position: relative;
  background: linear-gradient(180deg, #10181f 0%, #e3b17d 48%, #10181f 100%) border-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link .img-shape {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 85px;
  height: 84px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: linear-gradient(180deg, #10181f 0%, #e3b17d 48%, #10181f 100%) border-box;
  border: 1px solid transparent;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link .img-shape::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #0c1319;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #0c1319;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link:hover, .about__tab-wrap .nav-tabs .nav-item .nav-link.active {
  background: linear-gradient(180deg, #10181f 0%, var(--tg-theme-primary) 48%, #10181f 100%) border-box;
}
.about__tab-wrap .nav-tabs .nav-item .nav-link:hover .img-shape, .about__tab-wrap .nav-tabs .nav-item .nav-link.active .img-shape {
  background: linear-gradient(180deg, #10181f 0%, var(--tg-theme-primary) 48%, #10181f 100%) border-box;
}
.about__area .tab-content {
  margin: 80px 0 0;
  position: relative;
  overflow: hidden;
}
.about__area .tab-content::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--tg-theme-primary);
  background: linear-gradient(45deg, rgba(2, 0, 36, 0) 0%, var(--tg-theme-primary) 100%);
  opacity: 0.329;
}
@media (max-width: 1199.98px) {
  .about__area .tab-content::after {
    right: 0;
    width: 770px;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .about__area .tab-content::after {
    width: 100%;
    opacity: 0.5;
  }
}
@media (max-width: 1199.98px) {
  .about__img img {
    margin: 0 0 35px;
  }
}
@media (max-width: 1199.98px) {
  .about__img img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}
@media (max-width: 767.98px) {
  .about__img img {
    height: auto;
  }
}
.about__flex-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
}
.about__content-wrap {
  display: grid;
  grid-template-columns: 56% 1fr;
  gap: 30px 30px;
  margin: 0 0 30px;
}
@media (max-width: 767.98px) {
  .about__content-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
.about__content .title {
  margin: 0 0 2px;
  font-size: 30px;
}
.about__content .rate {
  display: block;
  font-size: 20px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-theme-secondary);
  margin: 0 0 18px;
}
.about__content p {
  margin: 0 0 0;
}
.about__content-list li {
  background-image: -moz-linear-gradient(0deg, #1f2935 0%, transparent 100%, #10181f 100%);
  background-image: -webkit-linear-gradient(0deg, #1f2935 0%, transparent 100%, #10181f 100%);
  background-image: -ms-linear-gradient(0deg, #1f2935 0%, transparent 100%, #10181f 100%);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21);
  padding: 7px 13px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  margin: 0 0 11px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: var(--tg-fw-bold);
  font-family: var(--tg-heading-font-family);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.about__content-list li:last-child {
  margin: 0 0;
}
.about__content-list li:hover {
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  transform: translateX(-10px);
}
.about__content-list li img {
  margin-right: 13px;
}
.about__btn-wrap {
  margin-top: auto;
}
.about__btn-wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 7px 7px;
}
@media (max-width: 767.98px) {
  .about__btn-wrap ul {
    flex-wrap: wrap;
  }
}
.about__btn-wrap ul li {
  flex: auto;
}
.about__btn-wrap ul li a {
  display: block;
  background-color: #1f2935;
  background-image: -moz-linear-gradient(90deg, #10181f 0%, transparent 0%, #141a20 100%);
  background-image: -webkit-linear-gradient(90deg, #10181f 0%, transparent 0%, #141a20 100%);
  background-image: -ms-linear-gradient(90deg, #10181f 0%, transparent 0%, #141a20 100%);
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-size: 16px;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-body-color);
  text-align: center;
  letter-spacing: 1px;
  padding: 25px 30px;
}
@media (max-width: 767.98px) {
  .about__btn-wrap ul li a {
    padding: 13px 30px;
  }
}
.about__btn-wrap ul li a:hover {
  color: var(--tg-theme-primary);
}

.section-pt-160 {
  padding-top: 160px;
}
@media (max-width: 1199.98px) {
  .section-pt-160 {
    padding-top: 130px;
  }
}
@media (max-width: 991.98px) {
  .section-pt-160 {
    padding-top: 120px;
  }
}

.section-pb-190 {
  padding-bottom: 190px;
}
@media (max-width: 1199.98px) {
  .section-pb-190 {
    padding-bottom: 25px;
  }
}

.about__funFact-images {
  position: relative;
  max-width: 511px;
  margin: 60px 25px 0 auto;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .about__funFact-images {
    max-width: 510px;
    margin: 60px auto 30px;
  }
}
@media (max-width: 767.98px) {
  .about__funFact-images {
    max-width: 400px;
    margin: 60px 25px 30px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__funFact-images {
    max-width: 480px;
    margin: 60px 40px 30px 0;
  }
}
.about__funFact-images::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100% + 15px);
  background: var(--tg-theme-primary);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0.84;
  z-index: -1;
}
.about__funFact-images .main-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 382px;
  margin: 0 auto;
}
@media (max-width: 1199.98px) {
  .about__funFact-images .main-img {
    max-width: 315px;
  }
}
@media (max-width: 991.98px) {
  .about__funFact-images .main-img {
    max-width: 350px;
  }
}
@media (max-width: 767.98px) {
  .about__funFact-images .main-img {
    max-width: 260px;
  }
}
.about__funFact-images:hover::after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  height: calc(100% + 0px);
}
.about__funFact-trophy {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin: 35px 25px 0 0;
}
@media (max-width: 991.98px) {
  .about__funFact-trophy {
    margin: 35px auto 40px;
    max-width: 510px;
  }
}
@media (max-width: 767.98px) {
  .about__funFact-trophy {
    margin: 35px 25px 40px auto;
  }
}
.about__funFact-trophy .icon {
  position: relative;
  margin-left: 22px;
  z-index: 1;
}
.about__funFact-trophy .icon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 95px;
  height: 96px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.about__funFact-trophy .icon img {
  max-height: 40px;
}
.about__funFact-trophy .content {
  text-align: right;
}
.about__funFact-trophy .content h5 {
  margin: 0 0 2px;
  font-size: 22px;
}
.about__funFact-trophy .content span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
}
.about__funFact-wrap {
  flex-grow: 1;
}
.about__funFact-lists {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px 50px;
}
.about__funFact-item .count {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: var(--tg-fw-semi-bold);
  margin: 0 0 8px;
  line-height: 1;
}
.about__funFact-item .count .formatting-mark {
  display: block;
  line-height: 1;
  margin-left: 3px;
}
.about__funFact-item p {
  line-height: 1;
  margin: 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
}
.about__content-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin: 50px 0 0;
}
@media (max-width: 1199.98px) {
  .about__content-bottom {
    margin: 40px 0 0;
    align-items: flex-start;
  }
}
@media (max-width: 991.98px) {
  .about__content-bottom {
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .about__content-bottom {
    align-items: flex-start;
    flex-direction: column-reverse;
    row-gap: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__content-bottom {
    align-items: center;
    flex-direction: row-reverse;
  }
}
.about__content-circle {
  width: 137px;
  height: 137px;
  position: relative;
  -webkit-animation: mykdRotation 12s linear infinite;
  animation: mykdRotation 12s linear infinite;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  margin: 0 50px 0 30px;
}
@media (max-width: 1199.98px) {
  .about__content-circle {
    width: 100px;
    height: 100px;
    margin: 0 0 0 auto;
  }
}
@media (max-width: 991.98px) {
  .about__content-circle {
    width: 137px;
    height: 137px;
    margin: 0 0 0 30px;
  }
}
@media (max-width: 767.98px) {
  .about__content-circle {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
}
.about__content-circle img {
  pointer-events: none;
}
.about__content-circle svg {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-33deg);
  -moz-transform: translate(-50%, -50%) rotate(-33deg);
  -ms-transform: translate(-50%, -50%) rotate(-33deg);
  -o-transform: translate(-50%, -50%) rotate(-33deg);
  transform: translate(-50%, -50%) rotate(-33deg);
  overflow: initial;
}
@media (max-width: 1199.98px) {
  .about__content-circle svg {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 991.98px) {
  .about__content-circle svg {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 767.98px) {
  .about__content-circle svg {
    width: 70px;
    height: 70px;
  }
}
.about__content-circle svg path {
  fill: none;
}
.about__content-circle svg text {
  fill: var(--tg-common-color-white);
  text-transform: uppercase;
  font-size: 22px;
  font-weight: var(--tg-fw-semi-bold);
  letter-spacing: 3px;
}
.about__content-circle:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.about__content-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 20px 30px;
  margin: 40px 0 0;
}
.about__content-btns .tg-btn-3 {
  font-size: 14px;
  color: var(--tg-common-color-white);
  letter-spacing: 0.5px;
  font-weight: var(--tg-fw-bold);
  width: 158px;
  height: 55px;
}
.about__content-btns .svg-icon {
  fill: transparent;
  stroke: var(--tg-theme-primary);
}
.about__content-btns .popup-video {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 0 14px;
  font-size: 13px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-common-color-white);
  letter-spacing: 0.3px;
  cursor: pointer;
}
.about__content-btns .popup-video i {
  width: 46px;
  height: 46px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border: 2px solid var(--tg-common-color-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-theme-primary);
  font-size: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about__content-btns .popup-video:hover {
  color: var(--tg-theme-primary);
}
.about__content-btns .popup-video:hover i {
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black-2);
  border-color: var(--tg-theme-primary);
}

.about__area-three {
  background-color: var(--tg-common-color-black-4);
  position: relative;
  z-index: 1;
}
.about__area-three .big-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 252px;
  max-width: 1270px;
  font-weight: var(--tg-fw-extra-bold);
  margin: 0 auto;
  letter-spacing: 20px;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.004);
  line-height: 0.8;
  opacity: 0.01;
  pointer-events: none;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .about__area-three .big-title {
    max-width: 930px;
    top: auto;
    bottom: 35px;
    font-size: 220px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  .about__area-three .big-title {
    max-width: 690px;
    top: auto;
    bottom: 35px;
    font-size: 21vw;
    letter-spacing: 10px;
  }
}
.about__title-wrap {
  max-width: 340px;
}
@media (max-width: 1199.98px) {
  .about__title-wrap {
    max-width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 55px;
  }
}
@media (max-width: 991.98px) {
  .about__title-wrap {
    flex-direction: column;
    text-align: center;
    gap: 35px;
  }
}
.about__title-wrap .title {
  font-size: 55px;
  letter-spacing: 3.5px;
  margin: 0 0 50px;
}
@media (max-width: 1199.98px) {
  .about__title-wrap .title {
    margin: 0 0 0;
    width: 60%;
    flex: 0 0 auto;
  }
}
@media (max-width: 991.98px) {
  .about__title-wrap .title {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .about__title-wrap .title {
    font-size: 34px;
    letter-spacing: 2.5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__title-wrap .title {
    font-size: 44px;
  }
}
.about__title-wrap .title i {
  font-style: normal;
  font-weight: var(--tg-fw-light);
}
.about__title-wrap .title span {
  color: var(--tg-theme-primary);
}
.about__title-wrap .title b {
  font-weight: var(--tg-fw-extra-bold);
}
.about__title-wrap .about__content-circle {
  margin: 0 0 0 auto;
}
@media (max-width: 1199.98px) {
  .about__title-wrap .about__content-circle {
    margin: 0 auto 0;
    width: 125px;
    height: 125px;
  }
  .about__title-wrap .about__content-circle svg {
    width: 75px;
    height: 75px;
  }
}
.about__three-images {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 26px;
  margin-left: -30px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about__three-images {
    margin: 0 0;
  }
}
@media (max-width: 991.98px) {
  .about__three-images {
    margin: 0 0 35px;
  }
}
@media (max-width: 767.98px) {
  .about__three-images {
    flex-direction: column-reverse;
  }
}
.about__three-images::after {
  content: "";
  position: absolute;
  left: 25%;
  top: 47%;
  width: 75px;
  height: 75px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.32);
  background-color: var(--tg-theme-primary);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transition: 1s;
  transition: 1s;
}
@media (max-width: 1500px) {
  .about__three-images::after {
    left: 23%;
    top: 46%;
  }
}
@media (max-width: 1199.98px) {
  .about__three-images::after {
    left: 24%;
    top: 48%;
  }
}
@media (max-width: 991.98px) {
  .about__three-images::after {
    display: none;
  }
}
.about__three-images .left {
  width: 363px;
  height: 487px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-mask-position: top left;
  mask-position: top left;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
}
@media (max-width: 1500px) {
  .about__three-images .left {
    width: 40%;
    height: 450px;
  }
}
@media (max-width: 1199.98px) {
  .about__three-images .left {
    height: 487px;
  }
}
@media (max-width: 991.98px) {
  .about__three-images .left {
    height: 290px;
    -webkit-mask-image: none !important;
    mask-image: none !important;
  }
}
@media (max-width: 767.98px) {
  .about__three-images .left {
    width: 100%;
  }
}
.about__three-images .right {
  width: 519px;
  height: 382px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-mask-position: top left;
  mask-position: top left;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
}
@media (max-width: 1500px) {
  .about__three-images .right {
    width: calc(60% - 26px);
    height: 340px;
  }
}
@media (max-width: 1199.98px) {
  .about__three-images .right {
    height: 382px;
  }
}
@media (max-width: 991.98px) {
  .about__three-images .right {
    height: 290px;
  }
}
@media (max-width: 767.98px) {
  .about__three-images .right {
    width: 100%;
    -webkit-mask-size: 100% 290px;
    mask-size: 100% 290px;
  }
}
.about__three-images:hover::after {
  -webkit-transform: rotateZ(90deg) translateY(-75px);
  -moz-transform: rotateZ(90deg) translateY(-75px);
  -ms-transform: rotateZ(90deg) translateY(-75px);
  -o-transform: rotateZ(90deg) translateY(-75px);
  transform: rotateZ(90deg) translateY(-75px);
}
.about__three-paragraph {
  margin: -35px 5px 0 auto;
  max-width: 77%;
}
.about__three-paragraph p {
  font-weight: var(--tg-fw-medium);
  margin: 0 0;
}
@media (max-width: 991.98px) {
  .about__three-paragraph {
    margin: 0 0;
    max-width: 100%;
    text-align: center;
  }
}
.about__dots {
  position: absolute;
  top: 20px;
  right: 22px;
  color: var(--tg-theme-primary);
  -webkit-animation: xAnimation 5s infinite;
  animation: xAnimation 5s infinite;
}
@media (max-width: 1500px) {
  .about__dots {
    top: 15px;
    right: 50px;
  }
}
@media (max-width: 1199.98px) {
  .about__dots {
    right: 65px;
  }
}
@media (max-width: 991.98px) {
  .about__dots {
    right: 12px;
    top: 7px;
    -webkit-animation: none;
    animation: none;
  }
}
@media (max-width: 767.98px) {
  .about__dots {
    right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__dots {
    right: 65px;
    -webkit-animation: xAnimation 5s infinite;
    animation: xAnimation 5s infinite;
  }
}
.about__dots svg {
  display: block;
  width: 109px;
  height: 35px;
}
.gallery__slider .swiper-slide {
  padding-left: 15px;
  padding-right: 15px;
}
.swiper-slide {
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .gallery__slider .swiper-slide {
    padding-left: 0;
    padding-right: 0;
  }
}
.gallery__item {
  margin: 0 0 55px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}
.gallery__thumb {
  margin: 0 0 30px;
}
@media (max-width: 767.98px) {
  .gallery__thumb {
    margin: 0 0 20px;
  }
}
.gallery__thumb img {
  width: 100%;
  border: 8px solid var(--tg-border-3);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.42);
}
@media (max-width: 767.98px) {
  .gallery__thumb img {
    border-width: 5px;
  }
}
.gallery__content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
}
.gallery__content .title {
  margin: 0 0;
  font-size: 26px;
}
@media (max-width: 767.98px) {
  .gallery__content .title {
    font-size: 20px;
  }
}
.gallery__content .rate {
  display: block;
  text-transform: uppercase;
  color: #aaaaa9;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
}

.gallery-active.swiper-container {
  overflow: visible;
}
.gallery-active .swiper-slide-active .gallery__item {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.gallery-active .swiper-slide-active .gallery__content {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.gallery-active .swiper-scrollbar {
  background: var(--tg-common-color-gray);
}
.gallery-active.swiper-container-horizontal > .swiper-scrollbar {
  border-radius: 0;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  max-width: calc(100% - 30px);
}
.gallery-active.swiper-container-horizontal .swiper-scrollbar-drag {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  height: 8px;
  top: -2px;
  background: var(--tg-theme-primary);
}

.mfp-title {
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
}

.mfp-bottom-bar {
  margin-top: -30px;
}

.section-pb-140 {
  padding-bottom: 140px;
}

.project-bg {
  background-color: #09090b;
  overflow: hidden;
}
.project__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin: 0 0 75px;
}
@media (max-width: 991.98px) {
  .project__wrapper {
    flex-direction: column;
    row-gap: 50px;
  }
}
@media (max-width: 767.98px) {
  .project__wrapper {
    margin: 0 0 50px;
  }
}
.project__wrapper .section__title {
  width: 420px;
  flex: 0 0 auto;
  margin: 70px 0 0;
}
@media (max-width: 991.98px) {
  .project__wrapper .section__title {
    width: 100%;
    flex: 0 0 auto;
    margin: 0 0;
    text-align: center !important;
  }
}
.project__wrapper .section__title::after {
  display: none;
}
.project__wrapper .section__title .title {
  margin: 0 0 10px;
}
.project__wrapper .section__title .sub-title {
  margin: 0 0;
}
.project__wrapper .slider-button-prev {
  position: absolute;
  left: 30.5%;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 38px;
  letter-spacing: -7px;
}
@media (max-width: 1500px) {
  .project__wrapper .slider-button-prev {
    left: 34.5%;
  }
}
@media (max-width: 1199.98px) {
  .project__wrapper .slider-button-prev {
    left: 43.5%;
  }
}
@media (max-width: 991.98px) {
  .project__wrapper .slider-button-prev {
    left: 0;
    top: calc(50% + 60px);
  }
}
@media (max-width: 767.98px) {
  .project__wrapper .slider-button-prev {
    display: none;
  }
}
.project__wrapper .slider-button-prev > * {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: var(--tg-common-color-white);
  animation: shapeBlinker 0.6s linear infinite;
}
.project__wrapper .slider-button-prev > *:last-child {
  color: var(--tg-theme-primary);
  animation-delay: 0.2s;
}
.project__wrapper .slider-button-prev:hover > * {
  animation-play-state: paused;
  color: var(--tg-theme-primary);
}
.project__wrapper .slider-button-prev:hover > *:last-child {
  color: var(--tg-common-color-white);
}
.project-active {
  margin: 0 -180px 0 160px;
}
@media (max-width: 1500px) {
  .project-active {
    margin: 0 -230px 0 100px;
  }
}
@media (max-width: 1199.98px) {
  .project-active {
    margin: 0 -230px 0 80px;
  }
}
@media (max-width: 991.98px) {
  .project-active {
    margin: 0 -230px 0 110px;
  }
}
@media (max-width: 767.98px) {
  .project-active {
    margin: 0 0;
  }
}
.project-area .swiper-scrollbar {
  display: block;
  width: 100%;
  height: 2px;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background: transparent;
  margin: 11px 0;
}
.project-area .swiper-scrollbar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--tg-theme-primary);
  pointer-events: none;
  opacity: 0.65;
}
.project-area .swiper-scrollbar .swiper-scrollbar-drag {
  height: 24px;
  background: var(--tg-theme-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  top: -11px;
  z-index: 1;
}
.section-pb-100 {
  padding-bottom: 100px;
}
@media (max-width: 767.98px) {
  .section-pb-100 {
    padding-bottom: 70px;
  }
}

.team-bg {
  background-position: center;
  background-size: cover;
}
.team__item {
  margin: 0 0 30px;
  text-align: center;
  background: #1c242f;
  border: 1px solid #27313f;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.17);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  padding: 30px 25px 35px;
  position: relative;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .team__item {
    max-width: 320px;
    margin: 0 auto 30px;
  }
}
.team__item::before, .team__item::after {
  content: "";
  position: absolute;
  left: 0;
  top: -60px;
  width: 70px;
  height: 320px;
  background: var(--tg-theme-primary);
  -webkit-transform: rotate(-55deg);
  -moz-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  -o-transform: rotate(-55deg);
  transform: rotate(-55deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0.55;
  z-index: -1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__item::before, .team__item::after {
    height: 295px;
    top: -48px;
  }
}
.team__item::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -o-transform: rotate(55deg);
  transform: rotate(55deg);
}
.team__item:hover {
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
  transform: translateY(-7px);
}
.team__item:hover::before, .team__item:hover::after {
  opacity: 1;
}
.team__item:hover .team__thumb::before, .team__item:hover .team__thumb::after {
  opacity: 0.4;
}
.team__thumb {
  margin: 0 0 33px;
}
.team__thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid var(--tg-common-color-white);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21), inset 0px 3px 9px 0px rgba(0, 0, 0, 0.92);
  max-width: 224px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__thumb img {
    max-width: 100%;
  }
}
.team__thumb::before, .team__thumb::after {
  content: "";
  position: absolute;
  left: 75px;
  top: -50px;
  width: 1px;
  height: 260px;
  background: var(--tg-theme-primary);
  -webkit-transform: rotate(-55deg);
  -moz-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  -o-transform: rotate(-55deg);
  transform: rotate(-55deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
  opacity: 0.2;
}
.team__thumb::after {
  left: auto;
  right: 75px;
  -webkit-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -o-transform: rotate(55deg);
  transform: rotate(55deg);
}
.team__content .name {
  font-size: 20px;
  font-weight: var(--tg-fw-extra-bold);
  margin: 0 0 1px;
  letter-spacing: 1px;
}
.team__content .name a:hover {
  color: var(--tg-common-color-white);
}
.team__content .designation {
  display: block;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  font-size: 16px;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team__info-area {
  background: #0d1116;
}
.team__info-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  /* display: -webkit-flex; */
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .team__info-wrap {
    display: block;
  }
}
.team__info-discord {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  column-gap: 25px;
  background: #070d10;
  padding: 35px 40px;
  min-width: 350px;
}
@media (max-width: 1199.98px) {
  .team__info-discord {
    padding: 35px 35px;
    min-width: auto;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
}
@media (max-width: 991.98px) {
  .team__info-discord {
    padding: 35px 0;
    min-width: auto;
    flex-direction: row;
    text-align: left;
    gap: 25px;
    justify-content: center;
  }
}
.team__info-discord .about__content-circle {
  margin: 0 0;
  animation: none !important;
  position: relative;
  width: 104px;
  height: 104px;
}
.team__info-discord .about__content-circle svg {
  width: 60px;
  height: 60px;
}
.team__info-discord .about__content-circle > i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #faa706;
  font-size: 30px;
}
.team__info-discord-info {
  flex-grow: 1;
}
@media (max-width: 991.98px) {
  .team__info-discord-info {
    flex-grow: inherit;
  }
}
.team__info-discord-info .sub {
  display: block;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  font-size: 14px;
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
  margin: 0 0 2px;
}
.team__info-discord-info .title {
  font-size: 22px;
  margin: 0 0;
}
.team__info-list {
  flex-grow: 1;
}
@media (max-width: 991.98px) {
  .team__info-list {
    padding: 30px 0;
  }
}
@media (max-width: 767.98px) {
  .team__info-list {
    padding: 30px 0 50px;
  }
}
.team__info-list ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px 60px;
}
@media (max-width: 991.98px) {
  .team__info-list ul {
    justify-content: center;
    gap: 30px 50px;
  }
}
@media (max-width: 767.98px) {
  .team__info-list ul {
    flex-wrap: wrap;
    gap: 30px 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__info-list ul {
    justify-content: space-between;
  }
}
.team__info-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .team__info-item {
    flex-direction: column;
    text-align: center;
  }
}
.team__info-icon {
  font-size: 50px;
  color: var(--tg-common-color-white);
  line-height: 1;
}
.team__info-icon img {
  max-width: 67px;
}
@media (max-width: 767.98px) {
  .team__info-icon img {
    max-width: 100%;
    max-height: 50px;
  }
}
.team__info-content {
  flex-grow: 1;
}
.team__info-content .sub {
  display: block;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  font-size: 14px;
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
  margin: 0 0 2px;
}
.team__info-content .title {
  font-size: 22px;
  margin: 0 0;
}
@media (max-width: 767.98px) {
  .team__info-content .title {
    font-size: 20px;
  }
}

.section-pt-95 {
  padding-top: 95px;
}

.section-pb-120 {
  padding-bottom: 120px;
}

.streamers-active {
  margin: 0 -15px 0;
}
.streamers__item {
  border: 3px solid #383c4e;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 3px;
  margin: 0 0 30px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.streamers__item:hover {
  border-color: var(--tg-theme-primary);
}
.streamers__item:hover .streamers__thumb::before {
  opacity: 0;
}
.streamers__item:hover .streamers__thumb::after {
  height: 100%;
  opacity: 0.8;
}
.streamers__thumb {
  position: relative;
  z-index: 1;
}
.streamers__thumb::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  background: var(--tg-common-color-gray);
  background: linear-gradient(0deg, rgba(72, 1, 90, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.streamers__thumb::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  background: var(--tg-theme-primary);
  background: linear-gradient(0deg, var(--tg-theme-primary) 0%, transparent 100%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.streamers__thumb img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #383c4e;
  width: 100%;
}
.streamers__content {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 28px;
  pointer-events: none;
  z-index: 1;
}
.streamers__content .name {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 0;
}
.streamers__pagination {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px 13px;
  flex-wrap: wrap;
  margin: 20px 0 0;
  line-height: 1;
}
.streamers__pagination-arrow {
  font-size: 15px;
  color: var(--tg-common-color-white);
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.streamers__pagination-arrow:hover {
  color: var(--tg-theme-primary);
}
.streamers__pagination-dots {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
}
.streamers__pagination-dots > * {
  display: block;
  width: 7px;
  height: 7px;
  background: #fefdff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0.52;
}
.streamers__pagination-dots > *.swiper-pagination-bullet-active {
  background: var(--tg-theme-primary);
  opacity: 1;
}

.team__details-area {
  background-position: top center;
  background-size: cover;
}
.team__details-img {
  position: relative;
  margin: 0 0 40px;
}
@media (max-width: 767.98px) {
  .team__details-img {
    margin: 0 0 25px;
  }
}
.team__details-img img {
  display: block;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 80% 85%, 80% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 80% 85%, 80% 100%, 0 100%);
}
.team__details-img svg {
  position: absolute;
  bottom: 15px;
  right: 8%;
  width: 145px;
  height: 66px;
  color: var(--tg-theme-primary);
  -webkit-animation: xAnimation 5s infinite;
  animation: xAnimation 5s infinite;
}
@media (max-width: 1500px) {
  .team__details-img svg {
    right: 7%;
  }
}
@media (max-width: 1199.98px) {
  .team__details-img svg {
    bottom: 8px;
    width: 11.5%;
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .team__details-img svg {
    right: 8%;
  }
}
@media (max-width: 767.98px) {
  .team__details-img svg {
    right: 5%;
    animation: none !important;
    bottom: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__details-img svg {
    right: 8%;
    bottom: 3px;
    -webkit-animation: xAnimation 5s infinite !important;
    animation: xAnimation 5s infinite !important;
  }
}
.team__details-content {
  font-weight: var(--tg-fw-medium);
}
.team__details-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--tg-theme-primary);
  font-size: 14px;
  font-weight: var(--tg-fw-semi-bold);
  letter-spacing: 2px;
  margin: 0 0 5px;
}
.team__details-content .title {
  font-size: 55px;
  line-height: 1.15;
  margin: 0 0 35px;
}
@media (max-width: 1199.98px) {
  .team__details-content .title {
    font-size: 46px;
  }
}
@media (max-width: 991.98px) {
  .team__details-content .title {
    font-size: 38px;
  }
}
@media (max-width: 767.98px) {
  .team__details-content .title {
    font-size: 28px;
    margin: 0 0 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__details-content .title {
    font-size: 34px;
  }
}
.team__details-content p {
  font-weight: var(--tg-fw-medium);
}
.team__details-content p:last-child {
  margin: 0 0;
}
.team__details-quote {
  margin: 40px 85px 35px 60px;
}
@media (max-width: 1199.98px) {
  .team__details-quote {
    margin: 40px 0 35px 0;
  }
}
.team__details-quote::before {
  content: "\f132";
  top: 3px;
}
@media (max-width: 767.98px) {
  .team__details-quote::before {
    top: 0;
  }
}
.team__details-quote cite {
  display: block;
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
}
@media (max-width: 767.98px) {
  .team__details-quote cite {
    display: inline-block;
  }
}
.team__details-quote cite::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 5px;
  background: var(--tg-theme-primary);
}
.team__details-inner-wrap {
  margin: 40px 0 35px;
}
@media (max-width: 767.98px) {
  .team__details-inner-wrap {
    margin: 35px 0 30px;
  }
}
.team__details-inner-wrap > * {
  row-gap: 30px;
}
@media (max-width: 767.98px) {
  .team__details-inner-wrap > * {
    --bs-gutter-x: 20px;
    row-gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .team__details-inner-wrap img {
    width: 100%;
  }
}
.video-bg {
  background-position: center;
  background-size: cover;
  min-height: 565px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
  padding: 100px 0;
  z-index: 1;
}
.video-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1b242e;
  opacity: 0.22;
  z-index: -1;
}
.video-bg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/bg/video_overlay.png);
  background-position: center;
  background-size: cover;
  opacity: 0.38;
  z-index: -1;
}
.video__content .popup-video {
  display: inline-block;
  font-size: 86px;
  line-height: 1;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.13);
  color: var(--tg-common-color-white);
  margin: 0 0 14px;
}
.video__content .popup-video:hover {
  color: var(--tg-theme-primary);
}
.video__content .title {
  font-size: 55px;
  font-weight: var(--tg-fw-extra-bold);
  margin: 0 0 6px;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.33);
  letter-spacing: 3px;
}
@media (max-width: 767.98px) {
  .video__content .title {
    font-size: 48px;
    letter-spacing: 1px;
    line-height: 1.1;
  }
}
.video__content .title span {
  color: var(--tg-theme-primary);
}
.video__content p {
  margin: 0 0 30px;
  font-size: 16px;
  font-weight: var(--tg-fw-medium);
  color: #fefefe;
  letter-spacing: 0.9px;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}
@media (max-width: 1500px) {
  .mfp-iframe-holder .mfp-content {
    max-width: 900px;
  }
}
.section-pt-150 {
  padding-top: 150px;
}
@media (max-width: 767.98px) {
  .section-pt-150 {
    padding-top: 100px;
  }
}

.section-pb-150 {
  padding-bottom: 150px;
}
@media (max-width: 767.98px) {
  .section-pb-150 {
    padding-bottom: 100px;
  }
}

.roadMap-bg {
  background-position: center;
  background-size: cover;
}
.roadMap__content .title {
  font-size: 55px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 3px;
  line-height: 1;
  margin: 0 0 28px;
}
@media (max-width: 1199.98px) {
  .roadMap__content .title {
    font-size: 50px;
  }
}
@media (max-width: 767.98px) {
  .roadMap__content .title {
    font-size: 40px;
  }
}
.roadMap__content p {
  max-width: 95%;
  letter-spacing: 1px;
  margin: 0 0 35px;
}
@media (max-width: 1199.98px) {
  .roadMap__content p {
    max-width: 90%;
  }
}
@media (max-width: 767.98px) {
  .roadMap__content p {
    max-width: 100%;
  }
}
.roadMap__img {
  margin: 50px 0 0 -35px;
}
@media (max-width: 1199.98px) {
  .roadMap__img {
    margin: 50px 0 0 0;
  }
}
.roadMap__steps-wrap {
  margin-left: 60px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .roadMap__steps-wrap {
    margin-left: 0;
  }
}
.roadMap__steps-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: -moz-linear-gradient(90deg, #10161c 0%, var(--tg-theme-primary) 100%);
  background: -webkit-linear-gradient(90deg, #10161c 0%, var(--tg-theme-primary) 100%);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.roadMap__steps-item {
  margin: 0 0 20px 45px;
  background-color: #1b242e;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 45px 45px;
  position: relative;
}
@media (max-width: 767.98px) {
  .roadMap__steps-item {
    margin: 0 0 20px 35px;
    padding: 35px 25px;
  }
}
.roadMap__steps-item .title {
  font-size: 30px;
  font-weight: var(--tg-fw-extra-bold);
  line-height: 1;
  margin: 0 0 22px;
}
@media (max-width: 1199.98px) {
  .roadMap__steps-item .title {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .roadMap__steps-item .title {
    font-size: 24px;
  }
}
.roadMap__steps-item::before {
  content: "";
  position: absolute;
  left: -39px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 18px;
  border-color: transparent transparent transparent var(--tg-theme-primary);
  opacity: 0;
}
@media (max-width: 767.98px) {
  .roadMap__steps-item::before {
    left: -34px;
  }
}
.roadMap__steps-item.active::before {
  opacity: 0.9;
}
.roadMap__steps-item:last-child {
  margin-bottom: 0;
}
.roadMap__steps-item:hover .roadMap__steps-img, .roadMap__steps-item.active .roadMap__steps-img {
  opacity: 0.11;
}
.roadMap__steps-img {
  position: absolute;
  right: 20px;
  top: 30px;
  max-height: 210px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.roadMap__list li {
  display: block;
  font-size: 16px;
  font-weight: var(--tg-fw-medium);
  padding-left: 37px;
  position: relative;
  margin: 0 0 6px;
}
.roadMap__list li:last-child {
  margin: 0 0 0;
}
.roadMap__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 20px;
  height: 20px;
  background: #10161c;
  border: 2px solid #262e38;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.roadMap__list li.active::before {
  background-color: var(--tg-theme-primary);
  border-color: #1b242e;
}
.roadMap__list li.active:hover::before {
  background-color: var(--tg-theme-secondary);
  border-color: #1b242e;
}
.section-pb-85 {
  padding-bottom: 85px;
}

.upcoming-match__area {
  background-position: bottom center;
  background-size: cover;
}
.upcoming-match__lists {
  margin: 0 80px;
}
@media (max-width: 1199.98px) {
  .upcoming-match__lists {
    margin: 0 0;
  }
}
.upcoming-match__item {
  position: relative;
  margin: 0 0 60px;
}
@media (max-width: 767.98px) {
  .upcoming-match__item {
    background: #0f1c23;
    border: 1px solid #2c2b2b;
    border-radius: 8px;
    padding: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__item {
    padding: 30px;
  }
}
.upcoming-match__item::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 80%;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  opacity: 0.1;
}
@media (max-width: 1199.98px) {
  .upcoming-match__item::before {
    opacity: 0.05;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__item::before {
    top: 60%;
  }
}
.upcoming-match__item .svg-icon {
  fill: #0f1c23;
  stroke: #2c2b2b;
  stroke-width: 1px;
  fill-rule: evenodd;
}
@media (max-width: 991.98px) {
  .upcoming-match__item .svg-icon svg {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .upcoming-match__item .svg-icon {
    background: #0f1c23;
    min-height: 140px;
    border: 1px solid #2c2b2b;
    border-radius: 8px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__item .svg-icon {
    display: none !important;
  }
}
.upcoming-match__item:hover .svg-icon {
  stroke: var(--tg-theme-primary);
}
.upcoming-match__position {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  left: 60px;
  right: 60px;
  top: 21px;
  bottom: 14px;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .upcoming-match__position {
    top: 17px;
    bottom: 18px;
  }
}
@media (max-width: 991.98px) {
  .upcoming-match__position {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: auto;
    left: 30px;
    right: 30px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__position {
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    top: auto;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    left: 0;
    right: 0;
    row-gap: 15px;
  }
}
.upcoming-match__team {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.14);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  background: #14242c;
  border: 3px solid #34353d;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .upcoming-match__team {
    width: 90px;
    height: 90px;
    flex: 0 0 auto;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__team {
    width: 75px;
    height: 75px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__team {
    width: 90px;
    height: 90px;
  }
}
.upcoming-match__team:hover {
  border-color: var(--tg-theme-primary);
}
.upcoming-match__team img {
  max-height: 75px;
}
@media (max-width: 1199.98px) {
  .upcoming-match__team img {
    max-width: 60px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__team img {
    max-width: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__team img {
    max-width: 60px;
  }
}
.upcoming-match__team.team-left {
  margin-right: 20px;
}
@media (max-width: 991.98px) {
  .upcoming-match__team.team-left {
    margin-right: 15px;
  }
}
.upcoming-match__team.team-right {
  margin-left: 20px;
}
@media (max-width: 991.98px) {
  .upcoming-match__team.team-right {
    margin-left: 15px;
  }
}
.upcoming-match__content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .upcoming-match__content {
    order: 2;
    width: 100%;
    flex: 0 0 100%;
    gap: 30px;
    justify-content: space-between;
  }
}
.upcoming-match__content .team--info .game-name {
  display: block;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
}
.upcoming-match__content .team--info .name {
  font-size: 26px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 1.5px;
  margin: 0 0;
}
@media (max-width: 1199.98px) {
  .upcoming-match__content .team--info .name {
    font-size: 22px;
  }
}
@media (max-width: 991.98px) {
  .upcoming-match__content .team--info .name {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__content .team--info .name {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__content .team--info .name {
    font-size: 20px;
  }
}
.upcoming-match__content .team--info .name a:hover {
  color: var(--tg-theme-primary);
}
.upcoming-match__content .team--info.info-left {
  text-align: right;
}
@media (max-width: 767.98px) {
  .upcoming-match__content .team--info.info-left {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__content .team--info.info-right {
    text-align: right;
  }
}
.upcoming-match__time {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  border: 4px solid #3f3f49;
  background: rgba(12, 23, 29, 0.671);
  width: 140px;
  height: 110px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 35px;
}
@media (max-width: 1199.98px) {
  .upcoming-match__time {
    width: 130px;
    height: 85px;
  }
}
@media (max-width: 991.98px) {
  .upcoming-match__time {
    width: 100px;
    height: 85px;
    margin: 0 20px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__time {
    position: absolute;
    top: 0;
    width: 95px;
    height: 75px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__time {
    width: 130px;
    height: 90px;
  }
}
.upcoming-match__time .time {
  font-size: 34px;
  margin: 0 0;
}
@media (max-width: 1199.98px) {
  .upcoming-match__time .time {
    font-size: 30px;
  }
}
@media (max-width: 991.98px) {
  .upcoming-match__time .time {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .upcoming-match__time .time {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .upcoming-match__time .time {
    font-size: 30px;
  }
}
.upcoming-match__date {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -24px;
  max-width: 287px;
  height: 24px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.upcoming-match__date svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #101d25;
  fill-rule: evenodd;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.upcoming-match__date span {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-bold);
  letter-spacing: 1px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  line-height: 1;
  padding: 0 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upcoming-match__item:hover .upcoming-match__date svg {
  fill: var(--tg-theme-primary);
}
.upcoming-match__item:hover .upcoming-match__date span {
  color: #0f1c23;
  opacity: 1;
}
.match__result-area {
  position: relative;
  padding: 95px 0 120px;
  z-index: 1;
}
.match__result-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% + 70px);
  background-position: top center;
  background-size: cover;
  z-index: -1;
}
.match__result-wrapper > *:nth-child(even) .match__winner-wrap {
  flex-direction: row-reverse;
}
@media (max-width: 991.98px) {
  .match__result-wrapper > *:nth-child(even) .match__winner-wrap {
    flex-direction: column-reverse;
    margin-right: 0;
    margin-left: 10px;
    align-items: flex-start;
  }
}
@media (max-width: 767.98px) {
  .match__result-wrapper > *:nth-child(even) .match__winner-wrap {
    align-items: flex-end;
    margin: 20px auto 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .match__result-wrapper > *:nth-child(even) .match__winner-wrap {
    margin: 0 auto 0;
  }
}
.match__result-wrapper > *:nth-child(even) .match__winner-img {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 22px;
}
@media (max-width: 767.98px) {
  .match__result-wrapper > *:nth-child(even) .match__winner-img {
    margin: 0 0;
  }
}
.match__result-wrapper > *:nth-child(even) .match__winner-img .svg-icon {
  right: auto;
  left: 0;
  -webkit-transform: translateY(-50%) rotateY(180deg);
  -moz-transform: translateY(-50%) rotateY(180deg);
  -ms-transform: translateY(-50%) rotateY(180deg);
  -o-transform: translateY(-50%) rotateY(180deg);
  transform: translateY(-50%) rotateY(180deg);
}
.match__result-wrapper > *:nth-child(even) .match__winner-place {
  padding: 10px 15px 10px 35px;
}
.match__result-wrapper > *:nth-child(even) .match__winner-info {
  text-align: left;
}
@media (max-width: 767.98px) {
  .match__result-wrapper > *:nth-child(even) .match__winner-info {
    text-align: right;
  }
}
.match__winner-title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 2px;
  color: var(--tg-theme-primary);
}
@media (max-width: 767.98px) {
  .match__winner-title {
    margin: 0 0 30px;
  }
}
.match__winner-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 991.98px) {
  .match__winner-wrap {
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column-reverse;
    row-gap: 13px;
    margin-right: 10px;
  }
}
@media (max-width: 767.98px) {
  .match__winner-wrap {
    align-items: flex-start;
    margin: 0 auto;
    max-width: 280px;
  }
}
.match__winner-info {
  flex-grow: 1;
  text-align: right;
}
@media (max-width: 767.98px) {
  .match__winner-info {
    text-align: left;
  }
}
.match__winner-info .name {
  font-size: 24px;
  line-height: 1;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 2px;
  margin: 0 0 6px;
}
.match__winner-info .price-amount {
  display: block;
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-primary);
  letter-spacing: 0.5px;
}
.match__winner-img {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 22px;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .match__winner-img {
    margin-left: 0;
  }
}
.match__winner-img .team-logo-img {
  width: 112px;
  height: 112px;
  background: #171717;
  border: 3px solid var(--tg-theme-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
}
.match__winner-img .team-logo-img img {
  max-width: 70px;
}
.match__winner-img .svg-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 75px;
  width: 135px;
  fill: var(--tg-theme-primary);
  z-index: -1;
}
.match__winner-place {
  font-size: 30px;
  font-weight: var(--tg-fw-extra-bold);
  color: #20202a;
  letter-spacing: 2px;
  margin: 0 0;
  text-transform: uppercase;
  padding: 10px 35px 10px 15px;
  line-height: 1;
}

.grand__final {
  text-align: center;
  margin: 30px 0 0;
}
@media (max-width: 991.98px) {
  .grand__final {
    margin: 50px 0 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .grand__final {
    margin: 30px 0 0;
  }
}
.grand__final-date {
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  color: #e5e5e5;
  letter-spacing: 1px;
  margin: 0 0 3px;
}
.grand__final-place {
  display: block;
  text-transform: uppercase;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-primary);
  font-family: var(--tg-heading-font-family);
  letter-spacing: 1px;
}
.grand__final-button {
  margin: 32px 0 0;
}
.services__bg-color {
  background-color: var(--tg-common-color-black-7);
}
@media (max-width: 1199.98px) {
  .services__bg-color .section__title {
    width: calc(100% + 200px);
  }
}
@media (max-width: 991.98px) {
  .services__bg-color .section__title {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .services__bg-color .section__title {
    text-align: center !important;
  }
  .services__bg-color .section__title::after {
    margin: 20px auto 0;
  }
}
.services__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px 50px;
}
@media (max-width: 1199.98px) {
  .services__wrapper {
    gap: 50px 45px;
  }
}
@media (max-width: 767.98px) {
  .services__wrapper {
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .services__wrapper {
    gap: 50px 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.services__icon {
  font-size: 40px;
  display: block;
  line-height: 1;
  color: var(--tg-theme-primary);
  margin: 0 0 30px;
}
.services__content .title {
  font-size: 20px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: capitalize;
  letter-spacing: 1.2px;
  margin: 0 0 18px;
}
.services__content .title a:hover {
  color: var(--tg-theme-primary);
}
.services__content p {
  font-size: 15px;
  font-weight: var(--tg-fw-medium);
  margin: 0 0;
}
.services__images {
  margin: 0 0 30px 36px;
  position: relative;
  height: 681px;
}
@media (max-width: 1199.98px) {
  .services__images {
    margin: 0 0 30px 20px;
    height: 490px;
  }
}
@media (max-width: 991.98px) {
  .services__images {
    margin: 70px 0 30px 0;
  }
}
@media (max-width: 767.98px) {
  .services__images {
    height: 360px;
  }
}
.services__images-item {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.1s ease-in-out;
  z-index: 1;
}
.services__images-item img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  clip-path: inset(0 100% 0 0);
  -webkit-animation: defaultInset 0.8s forwards ease;
  animation: defaultInset 0.8s forwards ease;
}
.services__images-item.active {
  z-index: 2;
}
.services__images-item.active img {
  -webkit-animation: activeInset 0.8s forwards ease;
  animation: activeInset 0.8s forwards ease;
}
.services__images-item.active .services__link i {
  animation: tg_arrow 0.8s forwards;
}
.services__link {
  position: absolute;
  right: -30px;
  bottom: -30px;
  width: 153px;
  height: 153px;
  font-size: 98px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #faa706;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #000;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  .services__link {
    right: 0;
    bottom: -30px;
    width: 120px;
    height: 120px;
    font-size: 70px;
  }
}
@media (max-width: 767.98px) {
  .services__link {
    width: 80px;
    height: 80px;
    font-size: 45px;
  }
}
.services__link:hover {
  color: #000;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.services__details-area {
  background-position: top center;
  background-size: cover;
}
.social-bg {
  background-position: bottom center;
  padding: 140px 0 150px;
  margin: -18px 0 -30px;
  filter: drop-shadow(0px 1px 0 rgba(255, 255, 255, 0.07));
  position: relative;
  background-size: cover;
}
@media (max-width: 1199.98px) {
  .social-bg {
    padding: 120px 0 120px;
  }
}
.social-bg .gutter-20 {
  --bs-gutter-x: 20px;
  gap: 20px 0;
}
.social__item div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #0a0b0f;
  border: 3px solid var(--tg-common-color-teal);
  width: 195px;
  height: 160px;
  position: relative;
  margin: 0 0 5px;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  .social__item div {
    width: 100%;
    height: 115px;
  }
}
.social__item div svg {
  fill: var(--tg-common-color-teal);
  fill-rule: evenodd;
  position: absolute;
  left: -3px;
  bottom: -8px;
}
.social__item div i {
  font-size: 44px;
  color: #d8d8d8;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .social__item div i {
    font-size: 40px;
  }
}
.social__item div span {
  display: block;
  text-transform: uppercase;
  color: var(--tg-common-color-teal);
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  letter-spacing: 2px;
  line-height: 1;
  margin: 22px 0 0;
}
@media (max-width: 1199.98px) {
  .social__item div span {
    margin: 15px 0 0;
  }
}
.social__item div:hover i {
  color: var(--tg-common-color-teal);
}
.tournament-area {
  background: var(--tg-common-color-black-4);
}
.tournament__wrapper {
  padding: 0 80px;
}
@media (max-width: 1500px) {
  .tournament__wrapper {
    padding: 0 60px;
  }
}
@media (max-width: 1199.98px) {
  .tournament__wrapper {
    padding: 0 0;
  }
}
.tournament__wrapper .gutter-25 {
  --bs-gutter-x: 25px;
}
@media (max-width: 1199.98px) {
  .tournament__wrapper .gutter-25 {
    --bs-gutter-x: 30px;
  }
}
.tournament__box-wrap {
  padding: 15px 0 0;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 0 0 17px 17px;
  -moz-border-radius: 0 0 17px 17px;
  -o-border-radius: 0 0 17px 17px;
  -ms-border-radius: 0 0 17px 17px;
  border-radius: 0 0 17px 17px;
  transition: 0.4s ease;
  margin: 0 0 30px;
  z-index: 1;
  cursor: pointer;
}
.tournament__box-wrap:hover {
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
  transform: translateY(-7px);
}
@media (max-width: 991.98px) {
  .tournament__box-wrap {
    background: #19222b;
    padding: 25px 30px 40px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    -o-border-radius: 17px;
    -ms-border-radius: 17px;
    border-radius: 17px;
    border: 1px solid #212d38;
  }
  .tournament__box-wrap:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.tournament__box-wrap.active {
  --tg-theme-primary: var(--tg-theme-secondary);
}
.tournament__box-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  background: var(--tg-theme-primary);
  background: radial-gradient(circle, var(--tg-common-color-white) 0%, transparent 100%);
  width: 90px;
  height: 90px;
  filter: blur(50px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
}
.tournament__box-wrap .main-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: -2;
}
@media (max-width: 991.98px) {
  .tournament__box-wrap .main-bg {
    display: none;
  }
}
.tournament__box-wrap .price-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 175px;
  height: 60px;
  color: var(--tg-theme-primary);
  z-index: -1;
  pointer-events: none;
}
.tournament__box-price {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-extra-bold);
  color: var(--tg-common-color-white);
  font-size: 20px;
  line-height: 1;
  padding: 17px 17px;
  letter-spacing: 1px;
  z-index: 2;
}
.tournament__box-price i {
  font-size: 18px;
  letter-spacing: 0;
}
.tournament__box-countdown {
  margin: 0 0 46px;
}
.tournament__box-countdown .coming-time {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  line-height: 1;
  text-align: center;
}
.tournament__box-countdown .time-count {
  line-height: 1;
  font-size: 13px;
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  font-weight: var(--tg-fw-semi-bold);
  color: #888888;
  position: relative;
}
.tournament__box-countdown .time-count::after {
  content: ":";
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 14px;
}
.tournament__box-countdown .time-count:last-child::after {
  display: none;
}
.tournament__box-countdown .time-count span {
  display: block;
  color: var(--tg-common-color-white);
  font-size: 14px;
  margin: 0 0 7px;
}
.tournament__box-caption {
  text-align: center;
  margin: 0 0 28px;
}
.tournament__box-caption .sub {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 1px;
  margin: 0 0 5px;
}
.tournament__box-caption .title {
  font-size: 30px;
  font-weight: var(--tg-fw-extra-bold);
  margin: 0 0;
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
}
.tournament__box-prize {
  text-align: center;
  background: var(--tg-common-color-black-4);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  letter-spacing: 1px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 13px;
  padding: 24px 20px;
  border: 1px solid #1e2933;
  margin: 0 0 40px;
}
.tournament__box-prize i {
  color: var(--tg-theme-secondary);
  font-size: 16px;
}
.tournament__box-list li {
  margin: 0 0 20px;
}
.tournament__box-list li:last-child {
  margin: 0 0;
}
.tournament__box-list-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.tournament__box-list-item:hover .tournament__player-name {
  color: var(--tg-common-color-white);
}
.tournament__player-thumb {
  flex: 0 0 auto;
  width: 40px;
  margin-right: 20px;
}
.tournament__player-thumb img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
.tournament__player-name {
  font-size: 14px;
  margin: 0 0;
  color: var(--tg-body-color);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  letter-spacing: 1px;
}
.tournament__player-price {
  margin-left: auto;
  color: var(--tg-theme-primary);
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  letter-spacing: 1px;
}
.tournament__player-price i {
  color: var(--tg-theme-secondary);
  margin-left: 7px;
  font-size: 14px;
}
.tournament__list-area {
  background-position: center;
  background-size: cover;
}
.tournament__list-item {
  position: relative;
  margin: 0 0 25px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .tournament__list-item {
    background: var(--tg-border-1);
    margin: 0 0;
    padding: 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #212d38;
  }
  .tournament__list-item-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
@media (max-width: 767.98px) {
  .tournament__list-item-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tournament__list-item-wrapper {
    max-width: 75%;
    margin: 0 auto;
  }
}
.tournament__list-item:last-child {
  margin: 0 0;
}
.tournament__list-item:hover {
  --tg-theme-primary: var(--tg-theme-secondary);
}
.tournament__list-item:hover .tournament__list-live a {
  color: var(--tg-common-color-white);
}
.tournament__list-item:hover .tournament__list-live a i {
  color: var(--tg-theme-secondary);
}
.tournament__list-item::before {
  content: "";
  position: absolute;
  left: 13%;
  top: 19px;
  width: 200px;
  height: 60px;
  background: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .tournament__list-item::before {
    display: none;
  }
}
.tournament__list-item svg {
  display: block;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .tournament__list-item svg {
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .tournament__list-item svg {
    display: none;
  }
}
.tournament__list-item .background-path {
  fill: var(--tg-border-1);
  stroke: #4c4c4c;
  stroke-width: 0.25px;
  fill-rule: evenodd;
}
.tournament__list-content {
  position: absolute;
  left: 60px;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .tournament__list-content {
    left: 50px;
    right: 45px;
  }
}
@media (max-width: 991.98px) {
  .tournament__list-content {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    flex-wrap: wrap;
    gap: 40px 0;
  }
}
.tournament__list-thumb {
  width: 150px;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .tournament__list-thumb {
    width: 115px;
  }
}
@media (max-width: 991.98px) {
  .tournament__list-thumb {
    width: 50%;
  }
}
.tournament__list-thumb img {
  max-width: 89px;
  max-height: 91px;
}
@media (max-width: 1199.98px) {
  .tournament__list-thumb img {
    max-width: 80px;
    max-height: 75px;
  }
}
.tournament__list-name {
  padding-left: 35px;
  position: relative;
  z-index: 1;
}
.tournament__list-name::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 53px;
  width: 1px;
  background: var(--tg-common-color-white);
  background: radial-gradient(circle, var(--tg-common-color-white) 0%, transparent 100%);
  opacity: 0.18;
  z-index: 1;
}
.tournament__list-name::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 60px;
  width: 3px;
  background: #121920;
}
.tournament__list-name {
  width: 205px;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .tournament__list-name {
    width: 50%;
    flex: 0 0 auto;
    padding-left: 20px;
    text-align: right;
  }
}
.tournament__list-name .team-name {
  font-size: 18px;
  margin: 0 0 8px;
}
.tournament__list-name .status {
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: var(--tg-fw-bold);
  font-family: var(--tg-heading-font-family);
  letter-spacing: 1px;
  color: var(--tg-theme-primary);
  padding-left: 13px;
  position: relative;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tournament__list-name .status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 8px;
  height: 8px;
  background: currentColor;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
@media (max-width: 991.98px) {
  .tournament__list-name .status {
    padding-left: 0;
    padding-right: 13px;
  }
  .tournament__list-name .status::before {
    left: auto;
    right: 0;
  }
}
.tournament__list-prize {
  width: 216px;
  flex: 0 0 auto;
  padding-left: 55px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .tournament__list-prize {
    width: 180px;
    padding-left: 40px;
  }
}
@media (max-width: 991.98px) {
  .tournament__list-prize {
    width: 50%;
    padding: 0 20px 0 0;
  }
  .tournament__list-prize::before {
    display: none;
  }
}
.tournament__list-prize::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 53px;
  width: 1px;
  background: var(--tg-common-color-white);
  background: radial-gradient(circle, var(--tg-common-color-white) 0%, transparent 100%);
  opacity: 0.18;
  z-index: 1;
}
.tournament__list-prize .title {
  font-size: 15px;
  color: var(--tg-body-color);
  letter-spacing: 1px;
  margin: 0 0 3px;
}
.tournament__list-prize i {
  color: var(--tg-theme-primary);
  font-size: 14px;
  margin-right: 7px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tournament__list-prize span {
  color: var(--tg-theme-primary);
  font-family: var(--tg-heading-font-family);
  font-size: 17px;
  font-weight: var(--tg-fw-semi-bold);
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tournament__list-time {
  width: 216px;
  flex: 0 0 auto;
  padding-left: 55px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .tournament__list-time {
    width: 180px;
    padding-left: 40px;
  }
}
@media (max-width: 991.98px) {
  .tournament__list-time {
    width: 50%;
    padding-left: 20px;
    text-align: right;
  }
}
.tournament__list-time::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 53px;
  width: 1px;
  background: var(--tg-common-color-white);
  background: radial-gradient(circle, var(--tg-common-color-white) 0%, transparent 100%);
  opacity: 0.18;
  z-index: 1;
}
.tournament__list-time .title {
  font-size: 15px;
  color: var(--tg-body-color);
  letter-spacing: 1px;
  margin: 0 0 3px;
}
.tournament__list-time i {
  font-size: 14px;
  margin-right: 7px;
}
.tournament__list-time span {
  color: var(--tg-body-color);
  font-family: var(--tg-heading-font-family);
  font-size: 17px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tournament__list-live {
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .tournament__list-live {
    margin: 0 auto;
  }
}
.tournament__list-live a {
  display: inline-block;
  background: #0c1217;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  font-size: 13px;
  color: var(--tg-body-color);
  padding: 12px 45px;
  letter-spacing: 1px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
@media (max-width: 1199.98px) {
  .tournament__list-live a {
    padding: 12px 22px;
  }
}
.tournament__list-live a i {
  margin-left: 3px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tournament__details-area {
  padding: 120px 0;
}
.tournament__details-content {
  background: #182029;
  padding: 40px 45px 40px 40px;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%, 0 0);
  border: 1px solid #232a30;
  font-weight: var(--tg-fw-medium);
}
@media (max-width: 1199.98px) {
  .tournament__details-content {
    padding: 30px 25px;
  }
}
@media (max-width: 767.98px) {
  .tournament__details-content {
    padding: 25px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tournament__details-content {
    padding: 30px 25px;
  }
}
.tournament__details-content p {
  font-weight: var(--tg-fw-medium);
}
.tournament__details-content .title {
  font-size: 32px;
  margin: 0 0 18px;
}
@media (max-width: 767.98px) {
  .tournament__details-content .title {
    font-size: 24px;
    margin: 0 0 15px;
  }
}
.tournament__details-content .blog-post-meta {
  padding-bottom: 30px;
  border-bottom: 1px solid #323c46;
  margin: 0 0 30px;
}
@media (max-width: 767.98px) {
  .tournament__details-content .blog-post-meta {
    margin: 0 0 25px;
  }
}
.tournament__details-content .blog-details-bottom {
  border-top: 1px solid #323c46;
}
.tournament__details-video {
  margin: 35px 0 30px;
}
.tournament__details-video .popup-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 68px;
  color: var(--tg-common-color-white);
  z-index: 1;
}
@media (max-width: 767.98px) {
  .tournament__details-video .popup-video {
    font-size: 54px;
  }
}
.tournament__details-video .popup-video:hover {
  color: var(--tg-theme-secondary);
}
.tournament__details-form {
  margin: 40px 0 0;
}
.tournament__details-form-title {
  font-size: 24px;
  margin: 0 0 10px;
}
.tournament__details-form form {
  margin: 25px 0 0;
}
.tournament__details-form input {
  display: block;
  width: 100%;
  border: 1px solid #23292f;
  background: var(--tg-common-color-black);
  padding: 15px 30px;
  margin: 0 0 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.tournament__details-form input::placeholder {
  opacity: 0.6;
}
.tournament__details-form-btn {
  display: inline-block;
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  text-transform: uppercase;
  border: none;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  padding: 14px 40px;
  font-size: 17px;
  letter-spacing: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  margin-top: 15px;
}
@media (max-width: 767.98px) {
  .tournament__details-form-btn {
    -webkit-clip-path: polygon(100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 0);
    padding: 12px 32px;
  }
}
.tournament__details-form-btn:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-common-color-black);
}
.tournament__sidebar .shop__widget, .tournament__sidebar .shop__widget-inner {
  border-color: #232a30;
}
.tournament__sidebar .shop__widget-inner {
  background: #182029;
}
.tournament__advertisement img {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.trending__matches-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
.trending__matches-item:last-child {
  margin: 0 0;
}
.trending__matches-thumb {
  width: 73px;
  flex: 0 0 auto;
  margin-right: 18px;
}
.trending__matches-thumb img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.trending__matches-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.trending__matches-content .title {
  font-size: 18px;
  margin: 0 0 5px;
}
.trending__matches-content .price {
  display: block;
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
}
.trending__matches-content .play a {
  display: block;
  color: var(--tg-body-color);
}
.trending__matches-content .play a:hover {
  color: var(--tg-theme-secondary);
}
.brand-area {
  padding: 0px 0 100px;
  background-color: var(--tg-common-color-black-6);
}
.brand__title {
  margin: 0 0 70px;
}
@media (max-width: 767.98px) {
  .brand__title {
    margin: 0 0 50px;
  }
}
.brand__title .title {
  font-size: 45px;
  font-weight: var(--tg-fw-medium);
  line-height: 1;
  letter-spacing: 3px;
  margin: 0 0;
}
@media (max-width: 767.98px) {
  .brand__title .title {
    font-size: 36px;
    letter-spacing: 2px;
  }
}
.brand__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
}
@media (max-width: 767.98px) {
  .brand__link {
    height: 35px;
  }
}
.brand__link img {
  max-height: 100%;
}
.brand-active .col {
  padding-left: 15px;
  padding-right: 15px;
}
.shop-area {
  padding: 120px 0 120px;
}
.shop-details-area {
  padding: 120px 0 85px;
}
.shop-sidebar {
  margin-right: 17px;
}
@media (max-width: 991.98px) {
  .shop-sidebar {
    margin: 100px 0 0;
  }
}
@media (max-width: 767.98px) {
  .shop-sidebar {
    margin: 70px 0 0;
  }
}
.shop__widget {
  border: 1px solid var(--tg-border-4);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin: 0 0 40px;
}
.shop__widget:last-child {
  margin: 0 0;
}
.shop__widget-title {
  font-size: 18px;
  margin: 0 0;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  column-gap: 10px;
  padding: 19px 20px;
  line-height: 1;
}
.shop__widget-title::before {
  content: "\f0da";
  font-family: var(--tg-icon-font-family);
  font-size: 20px;
  color: var(--tg-theme-primary);
}
.shop__widget-inner {
  background: #171d24;
  border: 1px solid #26292c;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  margin: -1px;
  padding: 20px;
}
.shop__search {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  line-height: 1;
}
.shop__search input {
  display: block;
  flex-grow: 1;
  border: none;
  padding: 0 20px 0 0;
  background: transparent;
  font-weight: var(--tg-fw-medium);
}
.shop__search input::placeholder {
  opacity: 0.63;
  font-weight: var(--tg-fw-medium);
}
.shop__search button {
  background: transparent;
  color: var(--tg-body-color);
  transform: rotate(-90deg);
  font-size: 14px;
}
.shop__search button:hover {
  color: var(--tg-theme-primary);
}
.shop__price-filter {
  margin: 5px 0 0;
}
.shop__price-filter #slider-range {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  height: 6px;
  background: #0d1216;
}
.shop__price-filter .ui-slider-range {
  background: var(--tg-theme-primary);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.shop__price-filter .ui-slider-handle {
  background: transparent;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent var(--tg-theme-primary) transparent;
  top: -5px;
  margin: 0 0 0 -1px;
}
.shop__price-filter .ui-slider-handle:focus-visible {
  outline: none;
}
.shop__price-filter .ui-slider-handle:last-child {
  margin: 0 0 0 -9px;
}
.shop__price-slider-amount {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin: 15px 0 0;
}
.shop__price-slider-amount [type=submit] {
  background: transparent;
  line-height: 1;
  flex-grow: 1;
  text-align: left;
  text-transform: uppercase;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-common-color-white);
  letter-spacing: 0.5px;
  font-size: 15px;
}
.shop__price-slider-amount [type=text] {
  border: none;
  padding: 0;
  width: auto;
  background: transparent;
  text-align: right;
  width: 50%;
  flex: 0 0 auto;
  font-weight: var(--tg-fw-medium);
  font-size: 15px;
  pointer-events: none;
}
.shop__top-wrap {
  margin: 0 0 30px;
}
@media (max-width: 767.98px) {
  .shop__showing-result {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__showing-result {
    text-align: left;
  }
}
.shop__showing-result p {
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  text-transform: uppercase;
  margin: 0 0;
}
.shop__ordering {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
  position: relative;
  width: 187px;
}
@media (max-width: 767.98px) {
  .shop__ordering {
    width: 200px;
    margin: 15px auto 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__ordering {
    margin: 0 0 0 auto;
  }
}
.shop__ordering::after {
  content: "\f107";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: var(--tg-icon-font-family);
  font-weight: var(--tg-fw-bold);
  right: 20px;
  font-size: 14px;
}
.shop__ordering select {
  background-image: -moz-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-color: #171d24;
  border: 1px solid var(--tg-border-4);
  color: var(--tg-body-color);
  font-weight: var(--tg-fw-medium);
  font-size: 14px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 12px 40px 12px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
@media (max-width: 767.98px) {
  .shop__ordering select {
    padding: 10px 40px 10px 20px;
  }
}
.shop__item {
  width: 100%;
  height: 406px;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 19px;
  margin: 0 0 35px;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  .shop__item {
    height: 395px;
  }
}
@media (max-width: 991.98px) {
  .shop__item {
    height: 406px;
    width: 298px;
    margin: 0 auto 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__item {
    height: auto;
    width: 100%;
  }
}
.shop__item-thumb {
  position: relative;
  background: var(--tg-common-color-black);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 262px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__item-thumb {
    min-height: 200px;
  }
}
.shop__item-thumb img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__item-thumb img {
    max-height: 200px;
  }
}
.shop__item-thumb .wishlist-button {
  position: absolute;
  top: 18px;
  right: 18px;
  line-height: 1;
  color: var(--tg-body-color);
}
.shop__item-thumb .wishlist-button:hover {
  color: var(--tg-theme-primary);
}
.shop__item-line {
  display: block;
  width: 100%;
  background: rgb(245, 246, 249);
  background: radial-gradient(circle, rgb(245, 246, 249) 0%, rgba(255, 255, 255, 0) 100%);
  height: 1px;
  opacity: 0.15;
  margin: 18px 0 25px;
}
.shop__item-content-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 13px;
  line-height: 1;
}
.shop__item-content-top .title {
  font-size: 18px;
  margin: 0 0;
  line-height: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shop__item-content-top .title a:hover {
  color: var(--tg-theme-primary);
}
.shop__item-price {
  font-size: 18px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  color: var(--tg-theme-primary);
  margin-left: 20px;
}
.shop__item-cat {
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-body-color);
  text-transform: uppercase;
}
.shop__item-cat a {
  color: var(--tg-body-color);
}
.shop__item-cat a:hover {
  color: var(--tg-theme-primary);
}

.related__products-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.related__products-item:last-child {
  margin: 0 0;
}
.related__products-thumb {
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.related__products-thumb img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--tg-border-4);
}
.related__products-content {
  flex-grow: 1;
}
.related__products-content .product-name {
  font-size: 14px;
  margin: 0 0 5px;
}
.related__products-content .product-name a:hover {
  color: var(--tg-theme-primary);
}
.related__products-content .amount {
  display: block;
  font-weight: var(--tg-fw-semi-bold);
  font-family: var(--tg-heading-font-family);
  font-size: 15px;
}

.product-categories li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
  font-size: 14px;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--tg-border-4);
}
.product-categories li:last-child {
  margin-bottom: 10px;
  padding-bottom: 0;
  border: none;
}
.product-categories li:hover {
  color: var(--tg-common-color-white);
}
.product-categories li a {
  display: block;
  color: var(--tg-body-color);
  text-transform: uppercase;
}
.product-categories li a:hover {
  color: var(--tg-common-color-white);
}
.product-categories li .float-right {
  margin-left: auto;
}

.shop__details-images-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 55%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .shop__details-images-wrap {
    flex-direction: column-reverse;
  }
}
@media (max-width: 991.98px) {
  .shop__details-images-wrap {
    width: 80%;
    margin: 0 0 50px;
  }
}
@media (max-width: 767.98px) {
  .shop__details-images-wrap {
    width: 100%;
  }
}
.shop__details-images-wrap .nav-tabs {
  flex-direction: column;
  width: 93px;
  flex: 0 0 auto;
  gap: 13px;
  margin-right: 18px;
}
@media (max-width: 1199.98px) {
  .shop__details-images-wrap .nav-tabs {
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin: 15px 0 0;
  }
}
.shop__details-images-wrap .nav-tabs .nav-item {
  margin: 0 0;
  filter: drop-shadow(0 0 0.3rem rgba(19, 19, 19, 0.49));
}
.shop__details-images-wrap .nav-tabs .nav-link {
  padding: 9px;
  display: block;
  width: 100%;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 0);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: #1b242e;
}
.shop__details-images-wrap .nav-tabs .nav-link img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.shop__details-images-wrap .tab-content {
  filter: drop-shadow(0 0 0.75rem rgba(19, 19, 19, 0.49));
}
.shop__details-images-wrap .tab-pane {
  padding: 19px;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  background: #1b242e;
}
@media (max-width: 767.98px) {
  .shop__details-images-wrap .tab-pane {
    padding: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__details-images-wrap .tab-pane {
    padding: 19px;
  }
}
.shop__details-images-wrap .tab-pane img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.shop__details-images-wrap .tab-pane.active {
  animation: none !important;
}
.shop__details-content {
  width: 44%;
  flex: 0 0 auto;
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .shop__details-content {
    width: 100%;
    margin: 0 0;
  }
}
.shop__details-content .title {
  font-size: 40px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 1.5px;
  margin: 0 0 13px;
}
@media (max-width: 767.98px) {
  .shop__details-content .title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop__details-content .title {
    font-size: 40px;
  }
}
.shop__details-rating {
  font-size: 13px;
  margin: 0 0 8px;
}
.shop__details-rating i {
  color: var(--tg-common-color-rating);
}
.shop__details-rating .rating-count {
  margin-left: 5px;
  font-weight: var(--tg-fw-medium);
}
.shop__details-price {
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  margin: 13px 0 28px;
}
.shop__details-price .amount {
  font-size: 18px;
  color: var(--tg-common-color-white);
}
.shop__details-price .stock-status {
  font-size: 16px;
  color: var(--tg-theme-primary);
}
.shop__details-short-description {
  margin: 0 0 27px;
  text-align: center;
  color: white;
}
.shop__details-short-description p {
  font-weight: var(--tg-fw-medium);
}
.shop__details-model {
  margin: 0 0 35px;
}
.shop__details-model .model {
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-common-color-white);
}
.shop__details-model ul {
  gap: 10px;
  margin-left: 15px;
}
.shop__details-model ul li {
  display: block;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18);
  border-width: 0.25px;
  border-color: #282f36;
  border-style: solid;
  border-radius: 2px;
  background: #1b242e;
  text-transform: uppercase;
  font-weight: var(--tg-fw-extra-bold);
  font-size: 13px;
  font-family: var(--tg-heading-font-family);
  line-height: 1;
  padding: 7px 17px;
  cursor: pointer;
}
.shop__details-model ul li.active {
  border-color: var(--tg-common-color-rating);
  background: var(--tg-common-color-rating);
  color: var(--tg-common-color-black);
}
.shop__details-qty {
  padding: 30px 0 0;
  border-top: 1px solid #1b242e;
}
.shop__details-qty > * {
  gap: 12px;
}
.shop__details-qty .quantity {
  position: relative;
  width: 99px;
}
.shop__details-qty .quantity input {
  width: 100%;
  border-width: 0.25px;
  border-color: #282f36;
  border-style: solid;
  border-radius: 3px;
  background: #1b242e;
  height: 53px;
  padding: 13px 40px 13px 25px;
  font-weight: var(--tg-fw-extra-bold);
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
}
.shop__details-qty .quantity .qtybutton-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 18px;
  height: 100%;
  line-height: 1;
  align-items: center;
  justify-content: center;
}
.shop__details-qty .quantity .qtybutton-box span {
  cursor: pointer;
}
.shop__details-cart-btn {
  display: block;
  width: 100%;
  height: 53px;
  text-align: center;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-extra-bold);
  font-size: 15px;
  text-transform: uppercase;
  color: var(--tg-common-color-white);
  letter-spacing: 1px;
  background-color: var(--tg-theme-primary);
  border: none;
  /* -webkit-clip-path: path("M8,0H152a8,8,0,0,1,8,8V33.63C160,37.36,140,53,140,53H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"); */
  /* clip-path: path("M8,0H152a8,8,0,0,1,8,8V33.63C160,37.36,140,53,140,53H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"); */
}
.shop__details-cart-btn:hover {
  color: var(--tg-common-color-white);
  background-color: var(--tg-theme-secondary);
}
.shop__details-cart-btn:disabled {
  background-color: #3a3a3a60;
  color: var(--tg-theme-primary);
}
.shop__details-bottom {
  margin: 34px 0 0;
}
.shop__details-bottom > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
}
.shop__details-bottom > div:not(:last-child) {
  margin-bottom: 13px;
}
.shop__details-bottom b {
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-semi-bold);
  margin-right: 6px;
}
.shop__details-bottom a {
  display: inline-block;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-body-color);
  margin-left: 5px;
}
.shop__details-bottom a:hover {
  color: var(--tg-theme-primary);
}
.shop__details-bottom .product_share a:not(:last-child) {
  margin-right: 10px;
}

.animation-none {
  animation: none !important;
}

.product__desc-wrap {
  margin: 90px 0 0;
}
.product__desc-wrap .nav {
  border-bottom: 1px solid var(--tg-border-5);
  margin: 0 0 5px;
}
@media (max-width: 767.98px) {
  .product__desc-wrap .nav {
    margin: 0 0 20px;
    padding-bottom: 15px;
    gap: 5px 25px;
  }
}
.product__desc-wrap .nav-item {
  margin: 0 0 -1px;
}
.product__desc-wrap .nav-link {
  padding: 11px 25px;
  text-transform: uppercase;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-body-color);
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
@media (max-width: 767.98px) {
  .product__desc-wrap .nav-link {
    padding: 0 0;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
}
.product__desc-wrap .nav-link:hover {
  border-color: transparent;
}
.product__desc-wrap .nav-link.active {
  color: var(--tg-theme-primary);
  border-color: #282f36;
  background: #1b242e;
}
@media (max-width: 767.98px) {
  .product__desc-wrap .nav-link.active {
    background: transparent;
  }
}
.product__desc-wrap .tab-content {
  background: #161e26;
  padding: 25px 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 1199.98px) {
  .product__desc-wrap .tab-content {
    padding: 25px 25px;
  }
}
.product__desc-wrap .tab-content p {
  font-weight: var(--tg-fw-medium);
}
.product__desc-wrap .tab-content table {
  margin: 0 0;
}
.product__desc-wrap .tab-content table th, .product__desc-wrap .tab-content table td {
  padding: 10px 25px;
  border-color: #30363d;
  border: 1px solid #30363d;
}
@media (max-width: 767.98px) {
  .product__desc-wrap .tab-content table th, .product__desc-wrap .tab-content table td {
    padding: 10px 20px;
  }
}
.product__desc-wrap .tab-content table th {
  color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-semi-bold);
  width: 25%;
}
@media (max-width: 1199.98px) {
  .product__desc-wrap .tab-content table th {
    width: 30%;
  }
}
@media (max-width: 991.98px) {
  .product__desc-wrap .tab-content table th {
    width: 40%;
  }
}
@media (max-width: 767.98px) {
  .product__desc-wrap .tab-content table th {
    width: auto;
  }
}
.product__desc-wrap .tab-content table td {
  color: var(--tg-body-color);
  font-weight: var(--tg-fw-medium);
  font-style: italic;
}
.product__desc-review {
  padding: 25px 30px;
  border: 1px solid #30363d;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .product__desc-review {
    padding: 20px 20px;
  }
}

.related__product-wrapper {
  padding: 110px 0 0;
}
.related__product-wrapper .related-title {
  font-size: 36px;
  font-weight: var(--tg-fw-extra-bold);
  letter-spacing: 2px;
  margin: 0 0 35px;
}
@media (max-width: 991.98px) {
  .related__product-wrapper .related-title {
    text-align: center;
  }
}
.blog-area {
  padding: 120px 0;
}
.blog-post-wrapper {
  width: 69%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .blog-post-wrapper {
    width: 65%;
  }
}
@media (max-width: 991.98px) {
  .blog-post-wrapper {
    width: 100%;
  }
}
.blog-post-wrapper .pagination__wrap {
  margin: 60px 0 0;
}
.blog-post-wrapper .pagination__wrap ul {
  justify-content: flex-start !important;
}
@media (max-width: 991.98px) {
  .blog-post-wrapper .pagination__wrap ul {
    justify-content: center !important;
  }
}
.blog-post-item {
  margin: 0 0 50px;
}
.blog-post-thumb img {
  max-width: 100%;
}
.blog-post-content {
  background: #182029;
  padding: 40px 45px 40px 40px;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%, 0 0);
  border: 1px solid #232a30;
}
@media (max-width: 1199.98px) {
  .blog-post-content {
    padding: 35px 30px;
  }
}
@media (max-width: 767.98px) {
  .blog-post-content {
    padding: 30px 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-post-content {
    padding: 40px 30px;
  }
}
.blog-post-content .title {
  font-size: 32px;
  letter-spacing: 1px;
  margin: 0 0 23px;
}
@media (max-width: 767.98px) {
  .blog-post-content .title {
    font-size: 24px;
    letter-spacing: 0;
    margin: 0 0 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-post-content .title {
    font-size: 28px;
  }
}
.blog-post-content .title a:hover {
  color: var(--tg-theme-primary);
}
.blog-post-content p {
  font-weight: var(--tg-fw-medium);
}
.blog-post-meta {
  margin: 0 0 17px;
}
.blog-post-meta ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 40px;
}
@media (max-width: 767.98px) {
  .blog-post-meta ul {
    gap: 10px 25px;
  }
}
.blog-post-meta ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--tg-fw-semi-bold);
  line-height: 1;
}
.blog-post-meta ul li i {
  color: var(--tg-theme-primary);
  margin-right: 5px;
}
.blog-post-meta ul li a {
  display: block;
  color: var(--tg-body-color);
}
.blog-post-meta ul li a:hover {
  color: var(--tg-theme-primary);
}
.blog-post-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 0;
  margin: 35px 0 0;
}
.blog-post-read {
  margin-right: 20px;
}
.blog-post-read a {
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-body-color);
  font-family: var(--tg-body-font-family);
  letter-spacing: 0.5px;
}
.blog-post-read a i {
  font-size: 16px;
  color: var(--tg-theme-primary);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-left: 4px;
}
.blog-post-read a:hover {
  color: var(--tg-theme-primary);
}
.blog-post-read a:hover i {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.blog-post-share {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.blog-post-share .share {
  margin: 0 0;
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-body-color);
  font-family: var(--tg-body-font-family);
  letter-spacing: 1px;
}
.blog-post-share ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.blog-post-share ul li {
  margin-left: 16px;
}
.blog-post-share ul a {
  display: block;
  line-height: 1;
  font-size: 14px;
  color: var(--tg-body-color);
}
.blog-post-share ul a:hover {
  color: var(--tg-theme-primary);
}
.blog-post-sidebar {
  width: 31%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .blog-post-sidebar {
    width: 35%;
  }
}
@media (max-width: 991.98px) {
  .blog-post-sidebar {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .blog-post-sidebar {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-post-sidebar {
    width: 80%;
  }
}
.blog-sidebar {
  margin-left: 20px;
}
@media (max-width: 991.98px) {
  .blog-sidebar {
    margin: 100px 0 0;
  }
}
.blog-widget {
  margin: 0 0 50px;
}
.blog-widget:last-child {
  margin: 0 0;
}
.blog-details-area .blog-post-item {
  margin: 0 0 65px;
}
.blog-details-area .blog-post-thumb {
  margin: 0 0 35px;
}
@media (max-width: 767.98px) {
  .blog-details-area .blog-post-thumb {
    margin: 0 0 25px;
  }
}
.blog-details-content {
  background: transparent;
  padding: 0 0;
  -webkit-clip-path: none;
  clip-path: none;
  border: none;
}
.blog-details-content p {
  font-weight: var(--tg-fw-regular);
}
.blog-details-inner {
  margin: 35px 0 30px;
}
.blog-details-inner .inner-title {
  margin: 0 0 15px;
}
.blog-details-inner-img {
  margin: 0 0 30px;
}
.blog-details-bottom {
  padding-top: 25px;
  border-top: 2px solid #19222b;
  margin: 65px 0 0;
}
.blog-details-bottom > * {
  row-gap: 15px;
}
.blog-details-bottom .tg-post-tags {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
}
.blog-details-bottom .tg-post-tags ul {
  line-height: 1;
}
.blog-details-bottom .tg-post-tags ul li {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: var(--tg-fw-medium);
  margin-right: 8px;
}
.blog-details-bottom .tg-post-tags ul li a {
  display: inline-block;
  line-height: 1;
  color: var(--tg-body-color);
  font-weight: var(--tg-fw-medium);
}
.blog-details-bottom .tg-post-tags ul li a:hover {
  color: var(--tg-theme-primary);
}
.blog-details-bottom .tags-title {
  font-size: 14px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  font-family: var(--tg-body-font-family);
  line-height: 1;
  margin: 0 13px 0 0;
}
.blog-details-bottom .blog-post-share ul a {
  color: var(--tg-common-color-white);
  font-size: 15px;
}
.blog-details-bottom .blog-post-share ul a:hover {
  color: var(--tg-theme-primary);
}

blockquote {
  padding-left: 80px;
  position: relative;
  margin: 27px 0 28px;
  font-size: 20px;
  color: var(--tg-common-color-white);
  line-height: 1.5;
  font-style: italic;
}
@media (max-width: 767.98px) {
  blockquote {
    padding: 0;
    text-align: center;
    font-size: 18px;
  }
}
blockquote::before {
  content: "\f137";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Flaticon";
  color: var(--tg-theme-primary);
  font-size: 52px;
  line-height: 1;
  font-style: normal;
}
@media (max-width: 767.98px) {
  blockquote::before {
    position: relative;
    display: block;
    font-size: 40px;
    margin: 0 0 7px;
  }
}
blockquote p {
  font-size: 20px;
  color: var(--tg-common-color-white);
  line-height: 1.5;
  font-style: italic;
  font-weight: var(--tg-fw-medium) !important;
}
@media (max-width: 767.98px) {
  blockquote p {
    font-size: 18px;
  }
}
.blog__avatar-wrap {
  overflow: hidden;
  padding: 20px 55px 20px 30px;
  display: flex;
  align-items: center;
  border: 1px solid #232a30;
  background: #182029;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
@media (max-width: 1199.98px) {
  .blog__avatar-wrap {
    padding: 20px 45px 20px 30px;
  }
}
@media (max-width: 767.98px) {
  .blog__avatar-wrap {
    display: block;
    text-align: center;
    padding: 35px 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__avatar-wrap {
    padding: 30px 30px;
  }
}
.blog__avatar-img {
  margin-right: 30px;
  width: 145px;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .blog__avatar-img {
    margin-right: 30px;
    width: 130px;
  }
}
@media (max-width: 767.98px) {
  .blog__avatar-img {
    margin: 0 auto 25px;
  }
}
.blog__avatar-img img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  height: 145px;
  object-fit: cover;
}
@media (max-width: 1199.98px) {
  .blog__avatar-img img {
    height: 130px;
  }
}
.blog__avatar-info .designation {
  font-size: 13px;
  color: var(--tg-theme-secondary);
  font-weight: var(--tg-fw-semi-bold);
  display: block;
  margin-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.blog__avatar-info .name {
  font-size: 20px;
  margin-bottom: 12px;
}
.blog__avatar-info .name a:hover {
  color: var(--tg-theme-primary);
}
.blog__avatar-info p {
  margin: 0 0 0;
}

.comments-wrap-title {
  font-size: 24px;
  letter-spacing: 1px;
  margin: 0 0 40px;
}
.comments-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #19222b;
}
@media (max-width: 767.98px) {
  .comments-box {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}
.comments-avatar {
  margin-right: 25px;
  width: 132px;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .comments-avatar {
    width: 100px;
  }
}
@media (max-width: 767.98px) {
  .comments-avatar {
    margin: 0 0 15px;
  }
}
.comments-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.comments-text {
  flex-grow: 1;
}
.comments-text .avatar-name {
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
}
.comments-text .avatar-name .name {
  font-size: 18px;
  margin-bottom: 3px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
  margin-top: 0;
}
.comments-text .avatar-name .date {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
}
.comments-text .comment-reply-link {
  font-size: 16px;
  letter-spacing: 1.5px;
}
.comments-text .comment-reply-link i {
  color: var(--tg-theme-primary);
  margin-right: 3px;
}
.comments-text .comment-reply-link:hover {
  color: var(--tg-theme-primary);
}
.comments-text p {
  margin: 0 0;
}

.latest-comments {
  margin: 0 0 70px;
}
.latest-comments .children {
  margin: 0 0 0 80px;
  padding: 0;
}
@media (max-width: 767.98px) {
  .latest-comments .children {
    margin: 0 0;
  }
}

.comment-reply-title {
  font-size: 24px;
  letter-spacing: 1px;
  margin: 0 0 17px;
}
.comment-form .form-grp {
  position: relative;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  margin: 0 0 30px;
}
.comment-form .form-grp::after {
  content: "";
  position: absolute;
  background-color: #19222b;
  width: 60px;
  height: 2px;
  right: -21px;
  bottom: 12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.comment-form .form-grp input, .comment-form .form-grp textarea {
  display: block;
  width: 100%;
  border: 2px solid #19222b;
  background: transparent;
  color: var(--tg-common-color-white);
  padding: 14px 25px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.comment-form .form-grp textarea {
  height: 160px;
  max-height: 160px;
}
.comment-form .form-grp input::placeholder, .comment-form .form-grp textarea::placeholder {
  opacity: 0.8;
}
.comment-form [type=submit] {
  -webkit-clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  font-weight: var(--tg-fw-bold);
  letter-spacing: 1px;
  border: none;
  padding: 14px 30px;
}
.comment-form [type=submit]:hover {
  background: var(--tg-theme-secondary);
}
.comment-notes {
  margin: 0 0 40px;
}

@media (max-width: 767.98px) {
  .sidebar__author {
    text-align: center;
  }
}
.sidebar__author-thumb {
  margin: 0 0 25px;
}
.sidebar__author-content .name {
  font-size: 22px;
  letter-spacing: 1px;
  margin: 0 0 10px;
}
.sidebar__author-content p {
  font-weight: var(--tg-fw-medium);
  margin: 0 0;
}
.sidebar__author-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 20px;
  margin: 20px 0 0;
}
@media (max-width: 767.98px) {
  .sidebar__author-social {
    justify-content: center;
  }
}
.sidebar__author-social a {
  display: block;
  line-height: 1;
  color: var(--tg-common-color-white);
}
.sidebar__author-social a:hover {
  color: var(--tg-theme-primary);
}
.sidebar-search-form input {
  display: block;
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  background: #182029;
  padding: 17px 50px 17px 25px;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  border: 1px solid #232a30;
  color: var(--tg-common-color-white);
}
.sidebar-search-form input::placeholder {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--tg-body-color);
  opacity: 0.54;
  font-weight: var(--tg-fw-medium);
}
.sidebar-search-form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 30px;
  border: none;
  padding: 0 0;
  background: transparent;
  color: var(--tg-common-color-white);
  font-size: 18px;
}
.sidebar-search-form button:hover {
  color: var(--tg-theme-primary);
}

.fw-title {
  font-size: 22px;
  margin: 0 0 25px;
}

.widget_categories ul li {
  font-size: 15px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-body-color);
  text-transform: uppercase;
  position: relative;
  margin-bottom: 14px;
}
.widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget_categories ul li a {
  color: var(--tg-body-color);
  position: relative;
  display: block;
}
.widget_categories ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 7px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--tg-theme-primary);
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
  -o-border-radius: 0 5px 0 0;
  -ms-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
  -webkit-transition: width 0.2s linear;
  -moz-transition: width 0.2s linear;
  -ms-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}
.widget_categories ul li a:hover {
  padding-left: 18px;
  color: var(--tg-theme-primary);
}
.widget_categories ul li a:hover::before {
  width: 7px;
}
.widget_categories ul li .float-right {
  position: absolute;
  top: 0;
  right: 0;
}

.rc__post-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 30px 20px 0;
}
@media (max-width: 1500px) {
  .rc__post-item {
    margin: 0 0 20px;
  }
}
.rc__post-item:last-child {
  margin-bottom: 0;
}
.rc__post-thumb {
  width: 112px;
  height: 88px;
  margin-right: 24px;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .rc__post-thumb {
    width: 90px;
  }
}
@media (max-width: 991.98px) {
  .rc__post-thumb {
    width: 100px;
  }
}
.rc__post-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rc__post-content {
  flex-grow: 1;
}
.rc__post-content .title {
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.rc__post-content .title a:hover {
  color: var(--tg-theme-primary);
}
.rc__post-content .date {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--tg-fw-medium);
  letter-spacing: 0.5px;
}

.sidebar__newsletter p {
  font-weight: var(--tg-fw-medium);
  margin: 0 0 25px;
}
.sidebar__newsletter-form {
  position: relative;
}
.sidebar__newsletter-form [type=email] {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #19222b;
  background: transparent;
  font-size: 15px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
  padding: 0 0 15px;
}
.sidebar__newsletter-form [type=email]::placeholder {
  font-size: 15px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
}
.sidebar__newsletter-form [type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  font-size: 18px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sidebar__insta {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 11px;
}
.sidebar__insta img {
  width: 100%;
}

.tagcloud {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tagcloud a {
  display: block;
  font-size: 14px !important;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  color: var(--tg-body-color);
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 13px), calc(100% - 13px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 13px), calc(100% - 13px) 100%, 0 100%, 0 0);
  border: 1px solid #232a30;
  background: #182029;
  padding: 9px 24px;
}
@media (max-width: 1500px) {
  .tagcloud a {
    padding: 8px 22px;
  }
}
@media (max-width: 767.98px) {
  .tagcloud a {
    padding: 6px 20px;
  }
}
.tagcloud a:hover {
  background: var(--tg-theme-primary);
  border-color: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
}
.contact-area {
  padding: 140px 0 125px;
}
.contact__content {
  margin-right: 50px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  .contact__content {
    margin-right: 0;
  }
}
.contact__content .overlay-title {
  font-size: 90px;
  line-height: 0.8;
  font-weight: var(--tg-fw-extra-bold);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  -webkit-transform: translateY(calc(-100% + 13px));
  -moz-transform: translateY(calc(-100% + 13px));
  -ms-transform: translateY(calc(-100% + 13px));
  -o-transform: translateY(calc(-100% + 13px));
  transform: translateY(calc(-100% + 13px));
  margin: 0 0;
  letter-spacing: 4px;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .contact__content .overlay-title {
    font-size: 16vw;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact__content .overlay-title {
    font-size: 13vw;
  }
}
.contact__content .overlay-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 100%;
  background: rgb(15, 22, 27);
  background: linear-gradient(0deg, rgb(15, 22, 27) 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.65;
}
.contact__content .overlay-title span {
  display: block;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--tg-common-color-white);
  opacity: 0.05;
}
.contact__content > .title {
  font-size: 45px;
  font-weight: var(--tg-fw-extra-bold);
  margin: 0 0 21px;
}
@media (max-width: 767.98px) {
  .contact__content > .title {
    font-size: 40px;
  }
}
.contact__content p {
  font-weight: var(--tg-fw-medium);
  margin: 0 0 0;
}
.contact__content .footer-el-widget {
  margin: 30px 0 0 !important;
}
@media (max-width: 991.98px) {
  .contact__form-wrap {
    margin: 50px 0 0;
  }
}
.contact__form-wrap .input-grp {
  position: relative;
  -webkit-clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0);
  margin: 0 0 30px;
}
.contact__form-wrap .input-grp::after {
  content: "";
  position: absolute;
  background-color: #262f39;
  width: 60px;
  height: 1px;
  right: -21px;
  bottom: 12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.contact__form-wrap .input-grp input, .contact__form-wrap .input-grp textarea {
  display: block;
  width: 100%;
  border: 1px solid #262f39;
  background: transparent;
  color: var(--tg-common-color-white);
  padding: 15px 25px;
}
.contact__form-wrap .input-grp textarea {
  height: 148px;
  max-height: 148px;
}
.contact__form-wrap .input-grp.message-grp {
  margin: 0 0 35px;
}
.contact__form-wrap .submit-btn {
  -webkit-clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 65%, 89% 100%, 0 100%, 0 0);
  background: var(--tg-theme-primary);
  color: var(--tg-common-color-black);
  font-family: var(--tg-heading-font-family);
  text-transform: uppercase;
  font-weight: var(--tg-fw-bold);
  letter-spacing: 1px;
  border: none;
  padding: 14px 30px;
}
.contact__form-wrap .submit-btn:hover {
  background: var(--tg-theme-secondary);
}
.contact__form-wrap .ajax-response {
  margin: 0 0;
}
.contact__form-wrap .ajax-response.error, .contact__form-wrap .ajax-response.success {
  margin-top: 15px;
}
.contact__form-wrap .ajax-response.error {
  color: var(--tg-theme-secondary);
}
.contact__form-wrap .ajax-response.success {
  color: var(--tg-theme-primary);
}
.contact-map iframe {
  display: block;
  width: 100%;
  height: 600px;
  opacity: 0.8;
}
@media (max-width: 1500px) {
  .contact-map iframe {
    height: 450px;
  }
}
.pagination__wrap {
  margin: 20px 0 0;
}
.pagination__wrap ul {
  gap: 10px 15px;
}
@media (max-width: 767.98px) {
  .pagination__wrap ul {
    gap: 10px;
  }
}
.pagination__wrap .page-numbers {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-image: -moz-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(18, 21, 24, 0.4902) 0%, rgba(31, 41, 53, 0.36078) 100%);
  background-color: #171d24;
  border: 1px solid var(--tg-border-4);
  color: var(--tg-body-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-bold);
}
@media (max-width: 767.98px) {
  .pagination__wrap .page-numbers {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}
.pagination__wrap .page-numbers.current {
  background-image: none;
  border-color: var(--tg-theme-primary);
  background-color: var(--tg-theme-primary);
  color: var(--tg-common-color-black-2);
}
.footer__top-wrap {
  padding: 80px 0 30px;
  border-top: 1px solid #151d23;
}
.footer-widget {
  margin: 0 0 50px;
}
.footer-widget .fw-title {
  font-size: 20px;
  margin: 0 0 28px;
}
@media (max-width: 767.98px) {
  .footer-widget .fw-title {
    margin: 0 0 20px;
  }
}
.footer-widget ul li {
  margin: 0 0 6px;
}
.footer-widget ul li a {
  font-size: 15px;
  display: inline-block;
  color: var(--tg-body-color);
  position: relative;
}
.footer-widget ul li a:hover {
  color: var(--tg-theme-primary);
}
.footer-widget.widget_nav_menu ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--tg-theme-primary);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-widget.widget_nav_menu ul li a:hover::after {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-logo {
  margin: 0 0 30px;
}
.footer-text {
  margin-right: 30px;
}
@media (max-width: 767.98px) {
  .footer-text {
    margin-right: 0;
  }
}
.footer-text .desc {
  margin: 0 0 25px;
  font-size: 15px;
}
.footer-text .social-title {
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  color: #ecebeb;
  line-height: 1;
}
.footer-text .social-title span {
  color: var(--tg-theme-primary);
}
.footer-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.footer-social a {
  display: block;
}
.footer-social img {
  max-width: 30px;
}
.footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+2) .footer-widget {
  padding-left: 40px;
}
@media (max-width: 767.98px) {
  .footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+2) .footer-widget {
    padding-left: 0;
  }
}
.footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+3) .footer-widget {
  padding-left: 50px;
}
@media (max-width: 991.98px) {
  .footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+3) .footer-widget {
    padding-left: 0;
  }
}
.footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+4) .footer-widget {
  padding-left: 78px;
}
@media (max-width: 1199.98px) {
  .footer-style-one .footer__top-wrap .row:first-child [class*=col-]:nth-child(4n+4) .footer-widget {
    padding-left: 0;
  }
}
.footer__country {
  margin: 0 0 100px;
}
.footer__country-name {
  line-height: 1;
}
.footer__country-name .text {
  margin: 0 0;
  font-size: 90px;
  text-transform: capitalize;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--tg-common-color-white);
  font-weight: var(--tg-fw-extra-bold);
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  line-height: 0.85;
  opacity: 0.66;
}
@media (max-width: 767.98px) {
  .footer__country-name .text {
    display: block;
    font-size: 12vw;
    -webkit-text-stroke-width: 1px;
    line-height: 0.9;
  }
}
.footer__country-name .text:hover {
  opacity: 1;
}
.footer__country-name .text::selection {
  -webkit-text-stroke-color: transparent;
}
.footer-style-two {
  background-color: var(--tg-common-color-black-6);
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.footer-style-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../img/bg/footer_shape.png);
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 2.5s ease-out 0s;
  -moz-transition: all 2.5s ease-out 0s;
  -ms-transition: all 2.5s ease-out 0s;
  -o-transition: all 2.5s ease-out 0s;
  transition: all 2.5s ease-out 0s;
  z-index: -1;
}
.footer-style-two.active-footer::before {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.footer__two-widgets {
  margin: 0 0 20px;
}
.footer-el-widget {
  margin: 0 0 50px;
  padding: 50px 0 0 0;
}
.footer-el-widget:not(.widget_nav_menu) {
  margin-top: 30px;
}
@media (max-width: 767.98px) {
  .footer-el-widget:not(.widget_nav_menu) {
    margin-top: 0;
  }
}
.footer-el-widget .title {
  position: relative;
  font-size: 18px;
  padding-left: 30px;
  letter-spacing: 1px;
  margin: 0 0 20px;
}
.footer-el-widget .title::before, .footer-el-widget .title::after {
  content: "\f0d9";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: var(--tg-icon-font-family);
  font-size: 20px;
}
.footer-el-widget .title::after {
  content: "\f0da";
  color: var(--tg-theme-primary);
  left: 11px;
}
.footer-el-widget.text-start .title {
  padding-left: 0;
  padding-right: 30px;
}
@media (max-width: 767.98px) {
  .footer-el-widget.text-start .title {
    padding-left: 30px;
    padding-right: 0;
  }
}
.footer-el-widget.text-start .title::before {
  left: auto;
  right: 11px;
}
@media (max-width: 767.98px) {
  .footer-el-widget.text-start .title::before {
    left: 0;
    right: auto;
  }
}
.footer-el-widget.text-start .title::after {
  content: "\f0da";
  color: var(--tg-theme-primary);
  left: auto;
  right: 0;
}
@media (max-width: 767.98px) {
  .footer-el-widget.text-start .title::after {
    left: 11px;
    right: auto;
  }
}
.footer-el-widget > ul.list-wrap li {
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-medium);
  color: #e2e1e1;
}
@media (max-width: 991.98px) {
  .footer-el-widget > ul.list-wrap li br {
    display: none;
  }
}
.footer-el-widget > ul.list-wrap li a {
  color: #e2e1e1;
}
.footer-el-widget > ul.list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.footer-el-widget.widget_nav_menu ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  margin: 0 0 13px;
}
@media (max-width: 767.98px) {
  .footer-el-widget.widget_nav_menu ul li {
    justify-content: flex-start;
    margin: 0 0 10px;
  }
}
.footer-el-widget.widget_nav_menu ul li:last-child {
  margin: 0 0;
}
.footer-el-widget.widget_nav_menu ul li a {
  display: block;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  color: #e2e1e1;
  position: relative;
}
.footer-el-widget.widget_nav_menu ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 42px;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(0deg);
  -moz-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  -o-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  background: var(--tg-theme-primary);
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-el-widget.widget_nav_menu ul li a:hover {
  color: var(--tg-theme-primary);
}
.footer-el-widget.widget_nav_menu ul li a:hover::before {
  opacity: 1;
  -webkit-transform: translateY(-50%) rotate(-40deg);
  -moz-transform: translateY(-50%) rotate(-40deg);
  -ms-transform: translateY(-50%) rotate(-40deg);
  -o-transform: translateY(-50%) rotate(-40deg);
  transform: translateY(-50%) rotate(-40deg);
}
.footer-el-logo {
  cursor: pointer;
}
.footer-el-logo img {
  max-width: 400px;
}

.footer-newsletter p {
  margin: 0 0 25px;
  font-size: 15px;
}
.footer-newsletter-form {
  position: relative;
}
.footer-newsletter-form [type=email] {
  display: block;
  border: none;
  background: #1f2935;
  width: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  padding: 17px 100px 17px 25px;
  font-size: 14px;
  height: 60px;
}
.footer-newsletter-form [type=email]::placeholder {
  font-size: 14px;
}
.footer-newsletter-form [type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  width: 63px;
  height: 100%;
  border: none;
  padding: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  font-size: 28px;
  color: #1f2935;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
}
.footer-newsletter-form [type=submit]:hover {
  background: var(--tg-theme-secondary);
}

.copyright__wrap {
  background: var(--tg-common-color-black-5);
  padding: 20px 0;
}
.copyright__wrap.-style-two {
  background: var(--tg-common-color-black-6);
}
@media (max-width: 767.98px) {
  .copyright__text {
    text-align: center;
  }
}
.copyright__text p {
  margin: 0 0;
  font-size: 14px;
  font-weight: var(--tg-fw-semi-bold);
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  color: #9f9f9f;
}
@media (max-width: 767.98px) {
  .copyright__text p {
    line-height: 1.45;
  }
}
.copyright__text p span {
  color: var(--tg-theme-primary);
}
@media (max-width: 767.98px) {
  .copyright__card {
    margin-top: 10px;
  }
}
.copyright__menu ul {
  gap: 0 55px;
}
@media (max-width: 1500px) {
  .copyright__menu ul {
    gap: 0 35px;
  }
}
@media (max-width: 991.98px) {
  .copyright__menu ul {
    gap: 0 25px;
    margin: 5px 0 0;
  }
}
.copyright__menu ul li div {
  display: block;
  text-transform: uppercase;
  color: #9f9f9f;
  font-weight: var(--tg-fw-semi-bold);
  font-family: var(--tg-heading-font-family);
  cursor: pointer;
}
.copyright__menu ul li div:hover {
  color: var(--tg-theme-primary);
}
@-webkit-keyframes tgSlideDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes tgSlideDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes shapeBlinker {
  50% {
    opacity: 0.59;
  }
}
@keyframes shapeBlinker {
  50% {
    opacity: 0.59;
  }
}
@-webkit-keyframes mykdRotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mykdRotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.tg__animate-text span {
  animation: 0.8s both tg_animated_text;
}

@keyframes tg_animated_text {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
    text-shadow: 0 0 25px var(--tg-theme-primary);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.tg__animate-text.style2 span {
  animation: 0.8s both tg_animated_text2;
}

@keyframes tg_animated_text2 {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
    text-shadow: 0 0 25px var(--tg-common-color-black-4);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes breadcrumbShake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes breadcrumbShake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes xAnimation {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}
@keyframes xAnimation {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}
@-webkit-keyframes defaultInset {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
}
@keyframes defaultInset {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
}
@-webkit-keyframes activeInset {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@keyframes activeInset {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@-webkit-keyframes tg_arrow {
  49% {
    transform: translateX(80%);
  }
  50% {
    opacity: 0;
    transform: translateX(-80%);
  }
  51% {
    opacity: 1;
  }
}
@-moz-keyframes tg_arrow {
  49% {
    transform: translateX(80%);
  }
  50% {
    opacity: 0;
    transform: translateX(-80%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes tg_arrow {
  49% {
    transform: translateX(80%);
  }
  50% {
    opacity: 0;
    transform: translateX(-80%);
  }
  51% {
    opacity: 1;
  }
}

.faq-section {
  background-color: var(--tg-common-color-black-6);
}

.mod-table {
  border: var(--tg-theme-primary) solid 1px;
  padding: 13px;
  margin-top: 10px;
}

.mod-table-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-transform: uppercase;
}

.mod-table-header h6 {
  font-size: 13px;
}

.mod-table-header .title-mod-table {
  font-size: 13px;
  color: var(--tg-theme-primary);
  margin-right: 10px;
}

.buttons-header-second {
  margin-top: 10%;
}

.mod-table-buttons {
  cursor: pointer;
  background-color: #7F06DE;
  color: #f4f7ff;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-family: var(--tg-heading-font-family);
  font-weight: var(--tg-fw-semi-bold);
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 12px;
}

#table-mods {
  display: block;
  align-items: center;
  max-height: 410px;
  overflow: auto;
}

#table-mods::-webkit-scrollbar {
  width: 0px;
}

.messages-nao-logado {
  text-align: center;
}
.dropdown li {
  margin-left: 0 !important;
  cursor: pointer;
}

.video-suporte {
  width: 100%;
  height: 100%;
  min-height: 350px;
}

.video-suporte-v2 {
  height: 300px;
}

.select-plataforma {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.select-plataforma img {
  width: 100px;
  height: 105px;
  padding: 10px;
  cursor: pointer;
}

.select-plataforma img:hover {
  transform: scale(1.2);
}

#imageTabContent img, iframe {
  height: 537px;
  width: 545px;
}

#imageTabContent #one {
  height: 575px;
  width: 574px;
  padding: 15px 80px;
}

#imageTabContent #two {
  height: 575px;
  width: 574px;
}

#imageTab img {
  height: 78px;
  width: 78px;
}

#description iframe {
  width: 100%;
  height: 800px;
}

#background-product-detalhe {
  min-height: 160px;
}

.shop__item-thumb img {
  max-width: 80%;
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; 
}

.centralizar-textos {
  text-align: center;
}

/* CHECKOU CONFIG*/
.checkout-section {
  /* background-color: var(--tg-common-color-black-6); */
}

.checkout-base {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 93vh; 
  margin-top: 50px; */
}

.checkout-main {
  background: radial-gradient(circle, #070d1093 0%, #070d104b 1%) !important;
  border-radius: 10px;
  border: white solid 1px;
}

.basket {
  max-height: 700px;
  min-height: 700px;
  overflow-y: auto;
  color: white;
}

.checkout-base a {
  border: 0 none;
  outline: 0;
  text-decoration: none;
}

.checkout-base strong {
  font-weight: bold;
}

.checkout-base h1 {
  font-size: 0.75rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.checkout-base input,
button {
  border: 0 none;
  outline: 0 none;
}

.checkout-base img,
.basket-module,
.basket-labels,
.basket-product {
  width: 100%;
}

.checkout-base input,
button,
.basket,
.basket-module,
.basket-labels,
.item,
.price,
.quantity,
.subtotal,
.basket-product,
.product-image,
.product-details {
  float: left;
  font-size: 14px;
}

.hide {
  display: none;
}

.checkout-base main {
  clear: both;
  font-size: 0.75rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem 0;
  width: 1050px;
}

.basket,
aside {
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.basket {
  width: 60%;
}

.basket-module {
  color: #111;
}

.checkout-base label {
  display: block;
  margin-bottom: 0.3125rem;
}

.promo-code-field {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-transform: uppercase;
  transition: all 0.2s linear;
  width: 48%;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -o-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.promo-code-field:hover,
.promo-code-field:focus {
  border: 1px solid #999;
}

.promo-code-cta {
  border-radius: 4px;
  font-size: 0.625rem;
  margin-left: 0.625rem;
  padding: 0.6875rem 1.25rem 0.625rem;
}

.basket-labels {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
}

.checkout-base ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.checkout-base li {
  color: white;
  display: inline-block;
  padding: 0.625rem 0;
}

.checkout-base li.price:before,
li.subtotal:before {
  content: '';
}

.item {
  width: 55%;
}

.price,
.quantity,
.subtotal {
  width: 15%;
}

.subtotal {
  text-align: right;
}

.remove {
  bottom: 1.125rem;
  float: right;
  position: absolute;
  right: 0;
  background-color: rgba(255, 0, 0, 0.21);
  color: white;
  width: 20%;
  height: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-radius: 5px;
}

.remove:hover {
  background-color: red;
}

.item-heading {
  padding-left: 4.375rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.basket-product {
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
  position: relative;
}

.product-image {
  width: 35%;
}

.product-details {
  width: 65%;
}

.product-details {
  padding: 0 1.5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.quantity-field {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 0.625rem;
  padding: 2px;
  width: 3.75rem;
}

.checkout-base aside {
  float: right;
  position: relative;
  width: 40%;
}

.summary {
  background-color: #070d10;
  border: 1px solid #aaa;
  padding: 1rem;
  /* position: fixed; */
  width: 390px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 700px;
}

.summary-total-items {
  color: #6a6464;
  font-size: 0.875rem;
  text-align: center;
}

.summary-subtotal,
.summary-total {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  clear: both;
  margin: 1rem 0;
  overflow: hidden;
  padding: 0.5rem 0;
}

.subtotal-title,
.subtotal-value,
.total-title,
.total-value,
.promo-title,
.promo-value {
  color: white;
  float: left;
  width: 50%;
  font-size: 20px;
}

.summary-promo {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.promo-title {
  float: left;
  width: 70%;
}

.promo-value {
  color: #8B0000;
  float: left;
  text-align: right;
  width: 30%;
}

.subtotal-value,
.total-value {
  text-align: right;
}

.total-title {
  font-weight: bold;
  text-transform: uppercase;
}

.summary-checkout {
  display: block;
}

.checkout-cta {
  display: block;
  float: none;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  padding: 0.625rem 0;
  width: 100%;
  color: white;
}

.summary-delivery-selection {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  font-size: 0.625rem;
  height: 34px;
  width: 100%;
}

@media screen and (max-width: 640px) {
  aside,
  .basket,
  .summary,
  .item,
  .remove {
    width: 100%;
  }
  .basket-labels {
    display: none;
  }
  .basket-module {
    margin-bottom: 1rem;
  }
  .item {
    margin-bottom: 1rem;
  }
  .product-image {
    width: 40%;
  }
  .product-details {
    width: 60%;
  }
  .price,
  .subtotal {
    width: 33%;
  }
  .quantity {
    text-align: center;
    width: 34%;
  }
  .quantity-field {
    float: none;
  }
  .remove {
    bottom: 0;
    text-align: left;
    margin-top: 0.75rem;
    position: relative;
  }
  .remove button {
    padding: 0;
  }
  .summary {
    margin-top: 1.25rem;
    position: relative;
  }
}

@media screen and (min-width: 641px) and (max-width: 960px) {
  .checkout-base aside {
    padding: 0 1rem 0 0;
  }
  .summary {
    width: 28%;
  }
}

@media screen and (max-width: 960px) {
  .checkout-base main {
    width: 100%;
  }
  .product-details {
    padding: 0 1rem;
  }
}

.payment-method h2 {
  margin: 0;
  padding: 1.8em 2em;
  font-size: 1.2em;
  line-height: 1.2;
  color: white;
}
.payment-method .pm-item {
  position: relative;
  border-bottom: rgba(255, 255, 255, 0.164) solid 1px;
}
.payment-method .pm-item:last-child:after {
  /* content: ""; */
  display: block;
  height: 0.125em;
  background-color: #e1e8ee;
  position: absolute;
  left: 2em;
  right: 2em;
}
.payment-method .pm-label {
  display: flex;
  margin-bottom: 0;
  padding: 1.5rem 2rem;
  cursor: pointer;
  background-color: #070d10;
}
.payment-method .pm-text {
  padding-left: 2.5em;
  position: relative;
}
.payment-method .pm-text:before {
  content: "";
  display: block;
  width: 1.5em;
  height: 1.5em;
  background: #e1e8ee;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  box-shadow: 0 0.08rem 0 rgba(20, 28, 35, 0.25) inset;
}
.payment-method .pm-text h5 {
  padding-top: 0.25em;
  font-size: 1em;
  color: var(--tg-theme-primary);
}
.payment-method .pm-text p {
  margin-bottom: 0;
  color: #86939e;
  width: 210px;
  font-size: 12px;
}
.payment-method .pm-thumb {
  padding-left: 0.9em;
}
.payment-method input[type=radio] {
  display: none;
}
.payment-method input[type=radio]:checked + .pm-label {
  background-color: #0d1116;
}
.payment-method input[type=radio]:checked + .pm-label .pm-text:before {
  background: radial-gradient(white 0%, white 33%, #7dc855 38%, #7dc855 100%);
}

.payment-method img {
  width: 250px;
}

.effeito_adicionar {
  /* background-color: var(--tg-theme-primary); */
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  position: absolute;
  margin-left: 180px;
  margin-bottom: 70px;
}

.sendtocart {
  animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sendtocart_dois {
  top: 45px;
  animation: xAxis 1s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cart {
  /* position: fixed; */
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  /* background: #292d48; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.cart i {
  font-size: 20px;
  color: white;
}
.cart:before {
  content: attr(data-totalitems);
  font-size: 12px;
  font-weight: 600;
  /* position: fixed;
  margin-left: 10px;
  margin-bottom: 10px; */
  /* position: absolute; */
  /* top: -12px;
  right: -12px; */
  /* background: #2bd156; */
  line-height: 24px;
  padding: 0 5px;
  height: 24px;
  min-width: 24px;
  color: white;
  text-align: center;
  border-radius: 24px;
}
.cart.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

@keyframes xAxis {
  100% {
    transform: translateX(calc(125px));
  }
}

@keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh + 75px));
  }
}

@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
.quantity {
  position: relative;
  width: 70px;
}
.quantity input {
  width: 100%;
  border-width: 0.25px;
  border-color: #282f36;
  border-style: solid;
  border-radius: 3px;
  background: #1b242e;
  height: 20px;
  padding: 12px 12px 12px 20px;
  font-weight: var(--tg-fw-extra-bold);
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
}
.quantity .qtybutton-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 18px;
  height: 100%;
  line-height: 1;
  align-items: center;
  justify-content: center;
}
.plus, .dis {
  cursor: pointer;
  font-size: 10px;
}
.container-carregando {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}
.carregando-container {
  margin-top: 110px;
  height: 90ch;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  background-color: #1f1d2b;
}

/* CHECKOUT PAY */
::selection {background: #f31544; color: #FFF;}
::moz-selection {background: #f31544; color: #FFF;}
.checkout-pay h1{
  font-size: 1.5em;
  color: #222;
}
.checkout-pay h2 {
  font-size: .9em;
  color: #6a6464;
}
.checkout-pay h3{
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
.checkout-pay p{
  font-size: 15px;
  color: #6a6464;
  line-height: 1.2em;
}
.checkout-pay #invoiceholder{
  width:100%;
  height: 100%;
  padding-top: 50px;
}
.checkout-pay #invoice{
  position: relative;
  margin: 0 auto;
  width: 700px;
  background: #FFF;
}
[id*='invoice-']{ /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
  padding: 30px;
}
.checkout-pay #invoice-top{min-height: 90px;}
.checkout-pay #invoice-mid{min-height: 120px;}
.checkout-pay #invoice-bot{ min-height: 970px;}
.checkout-pay .logo{
  float: left;
	height: 60px;
	width: 60px;
	background: url(http://michaeltruong.ca/images/logo1.png) no-repeat;
	background-size: 60px 60px;
}
.checkout-pay .clientlogo{
  float: left;
	height: 60px;
	width: 60px;
	background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
	background-size: 60px 60px;
  border-radius: 50px;
}
.checkout-pay .info{
  display: block;
  float:left;
  margin-left: 20px;
  color: black !important;
}
.checkout-pay .title{
  float: right;
}
.checkout-pay .title p{text-align: right;}
.checkout-pay #project{margin-left: 52%;}
.checkout-pay table{
  width: 100%;
  border-collapse: collapse;
}
.checkout-pay td{
  padding: 5px 0 5px 15px;
  border: 1px solid #EEE
}
.checkout-pay .tabletitle{
  padding: 5px;
  background: #EEE;
}
.checkout-pay .service{border: 1px solid #EEE;}
.checkout-pay .item{width: 50%;}
.checkout-pay .itemtext{font-size: .9em;}

.checkout-pay #legalcopy{
  margin-top: 30px;
}
.checkout-pay form{
  float:right;
  text-align: right;
}
.checkout-pay .effect2
{
  position: relative;
}
.checkout-pay .effect2:before, .effect2:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.checkout-pay .effect2:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.checkout-pay .legal{
  width:70%;
}
.checkout-pay .mp-details-list {
  display: flex !important;
  padding: 2px 0 !important;
}
.checkout-pay .mp-details-pix-img {
  height: 56px;
  object-fit: contain;
  display: block;
  margin: 0 0 0 0;
  padding: 0 0 0 32px;
}
.checkout-pay .mp-steps-congrats {
  margin: 24px 0 16px 0!important;
  list-style-type: none;
}
.checkout-pay .mp-col-md-4 {
  float: left;
  width: 100%;
  flex: 0 0 auto;
}
.checkout-pay .mp-steps-congrats {
  margin: 24px 0 16px 0!important;
  list-style-type: none;
}
.checkout-pay .mp-pix-left {
  padding: 0 0 0 32px;
}
.checkout-pay .mp-details-pix-title {
  width: 100%;
  opacity: .8;
  font-family: sans-serif;
  font-size: 18px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(0,0,0,.8);
  display: block;
  padding: 20px 0 0 32px;
  text-align: center;
}
.checkout-pay .mp-details-pix-number-p {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  border: solid 1px #009ee3;
  color: #009ee3;
  text-align: center;
  font-size: 16px;
  margin: 0 10px 0 0;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-pay .mp-details-list-description {
  padding: 5px 0 0 0;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0,0,0,.8);
  display: block;
  width: 70%;
}
.checkout-pay .mp-details-pix-amount {
  margin: 32px auto 0;
  text-align: center;
  width: 100%;
  color: rgba(0,0,0,.8);
  font-size: 18px;
  font-family: sans-serif;
}
.checkout-pay .mp-col-md-8 {
  flex: 0 0 66.666667%;
}
.checkout-pay .mp-details-pix-amount {
  margin: 32px auto 0;
  text-align: center;
  width: 100%;
  color: rgba(0,0,0,.8);
  font-size: 18px;
  font-family: sans-serif;
}
.checkout-pay .mp-details-pix-qr-title {
  margin: 24px 0 0 0;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0,0,0,.8);
}
.checkout-pay .mp-details-pix-qr-img {
  width: 168px !important;
  height: 168px !important;
  display: block;
  margin: 16px auto 0 auto;
}
.checkout-pay .mp-details-pix-qr-subtitle {
  margin: 12px 0 32px 0;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0,0,0,.45);
}
.checkout-pay .mp-details-pix-qr-description {
  margin: 32px 15px 16px 16px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0,0,0,.8);
}
.checkout-pay .mp-row-checkout-pix-container {
  width: 100%;
  display: block;
  padding: 16px 47px 48px 47px;
}
.checkout-pay .mp-qr-input {
  width: 100%;
  height: 48px;
  padding: 6.3px 0 5.7px 12px;
  border-radius: 6px;
  border: solid 1px rgba(0,0,0,.2) !important;
  background-color: #fff;
  margin: 0 12px 0 0;
}
.checkout-pay .mp-details-pix-button {
  margin-top: 10px;
  width: 100%;
  height: 48px;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: #009ee3;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.checkout-pay .mp-details-cancel {
  margin-top: 10px;
  width: 100%;
  height: 48px;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: #b13636;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.checkout-pay .icone-btn {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.checkout-pay .icone-btn-mercado {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}
.emblema-new {
  border-radius: 50%;
  margin-left: 33px;
  margin-top: 2px;
  width: 80px;
  height: 50px;
  background-size: 1px 1px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.emblema-new-ui-page {
  /* right: 0;
  top: 0; */
  top: -15px;
  left: -11px;
  width: 120px;
  height: 50px;
  position: fixed;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-pwxzbm {
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown_language {
  width: 160px;
  height: 85px;
}
.dropdown_user_nav_bar {
  width: 160px;
  height: 200px;
}
.drop_lang {
  cursor: pointer;
}
.dropdown-menu {
  background-color: #1d2333;
  color: white;
}
.dropdown-item {
  color: white;
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo {
    display: none !important;
  }
}
.img_pre_product {
  width: 400px;
  height: 530px;
  /* background-size: 450px !important; */
}
[data-rmiz-modal-overlay="visible"] {
  background-color: rgb(0 0 0 / 90%) !important;
}
[data-rmiz-modal-img] {
  border-radius: 12px;
}
.img_product_zoom {
  width: 100% !important;
  height: 100% !important;
}
.product_value {
  font-size: 30px;
  margin-right: 7px;
  text-decoration: line-through;
  color: #f9515c;
  font-family: "Rajdhani", sans-serif !important;
}
.product_discont {
  color: white;
  font-weight: bold;
  font-size: 45px;
  font-family: "Rajdhani", sans-serif !important;
}

.shop__details-bottom .tab-pane {
  background: #161e26;
  padding: 25px 30px;
  border-radius: 12px;
}

.shop__details-bottom table th, .shop__details-bottom table td {
  padding: 10px 25px;
  border-color: #30363d;
  border: 1px solid #30363d;
  color: rgb(255, 255, 255);
  background: #161e26;
}
.product_not_found_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}
.product_not_found{
  font-size: 100px;
  animation: glitch 1s linear infinite;
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}
.product_not_found:before {
  content: attr(title);
  position: absolute;
  left: 0;
}
.product_not_found:after {
  content: attr(title);
  position: absolute;
  left: 2px;
  bottom: 0;
}
.product_not_found:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}
@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}
.product_not_found:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}
@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}

/* ////////////////////////////////////////// */

.container-loading {
  width: 100vw;
  height: 100vh;
  background-color: #1F1D2B;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100000 !important;
}

.checkout-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}

/* ////////////////////////////////////////// */
.form_accetp_terms {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_accept_terms {
  margin-left: 10px;
}
.mod-vip {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.mod-vip:hover {
  transform: translateY(-1px);
}
.swiper-store {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.swiper-slide-store {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.section-banner-store {
  /* min-height: 160px; */
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.swiper-slide-store img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}
.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: white;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
.loading-orders {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .tgmenu__nav .logo img {
    width: 140px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .tgmenu__nav .logo img {
    width: 160px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .tgmenu__nav .logo img {
    width: 200px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 770px) {
  .profile_two {
    padding-top: 50px;
  }
}
